import * as _ from 'lodash';
import { deletePropertiesDeeply } from '@powerednow/shared/modules/utilities/object';
import { FORMS } from '@powerednow/shared/constants';
import FormManager from './FormManager';
import Path from './Path';

let context;
if (!process.env.PRODUCTION) {
    // eslint-disable-next-line global-require
    context = require('@powerednow/shared/context');
}

declare type FormPropertyDefinition = {
    value: string,
    copyable: boolean,
    resetValue?: string | boolean | null,
};

export default class FormDocumentManager extends FormManager {
    private _data: any;

    private originalData: any;

    constructor(formData, formDocumentData = {}) {
        super(formData);
        const filteredData = this.filterFormData(formDocumentData);

        this.originalData = _.cloneDeep(filteredData);

        this.setData(formDocumentData);
    }

    private setData(formDocumentData: object): void {
        const filteredData = this.filterFormData(formDocumentData);
        this._data = _.cloneDeep(filteredData);

        if (_.isEmpty(this._data.form)) {
            this._data.form = this.getDefaultTemplateData();
        }
    }

    private getDefaultTemplateData(data = this._data) {
        return this.getViewModelTemplate().getDefaultDataForPath(new Path('form'), data);
    }

    public getData(dataPath?: string | Path): any {
        const path = new Path(dataPath);
        const formData = this.filterFormData(this._data);

        if (path.isRoot()) {
            return formData;
        }

        return _.get(formData, path.toString());
    }

    public getOriginalData() {
        return this.originalData;
    }

    private filterFormData(formData) {
        const requiredFields = FORMS.FORM_DATA_FILTER;
        return requiredFields.reduce((filteredFormData, key) => {
            if (formData[key]) {
                Object.assign(filteredFormData, { [key]: formData[key] });
            }
            return filteredFormData;
        }, {});
    }

    public getProperty(path: string | Path): any {
        //
        // Sounds stupid, but we have a test to return undefined if path missing.
        //
        if (typeof path === 'undefined') {
            return undefined;
        }
        return _.get(this._data, path.toString());
    }

    public setProperty(path: string | Path, value: any): object {
        return _.set(this._data, path.toString(), value);
    }

    public updateData(data: any): void {
        const _data = _.omit(data, _.keys(this.getViewModelTemplate().formulas));

        this.setData(_data);
    }

    public setPageCheckedStatus(pageId: string, dataPath: string): void {
        const pageType = this.getPage(pageId).type;
        if (pageType === FORMS.PAGE_TYPES.CHILD) {
            this.checkChildPage(pageId);
        } else if (pageType === FORMS.PAGE_TYPES.RECURRING) {
            this.checkRecurringPage(dataPath);
        }
    }

    private checkChildPage(pageId: string): void {
        this._data.form.pageCheckStatuses = this._data.form.pageCheckStatuses || {};
        this._data.form.pageCheckStatuses[pageId] = true;
    }

    private checkRecurringPage(dataPath): void {
        const element = this.getProperty(dataPath);
        element.pageCheckStatus = true;
    }

    public copyFrom(otherFormDocumentManager: FormDocumentManager): void {
        const otherData = otherFormDocumentManager.getData(Path.getRootPath());
        this._data.form = Object.assign(this._data.form, _.cloneDeep(otherData.form));

        this.resetNonCopyableProperties();
        this.clearCheckStatuses();
        this.clearDataRecursively(new Path('form'));
    }

    private clearCheckStatuses() {
        deletePropertiesDeeply(
            this.getProperty('form'),
            ['pageCheckStatus', 'pageCheckStatuses'],
        );
    }

    private resetNonCopyableProperties(): void {
        Object.values(FORMS.PROPERTIES)
            .filter(({ copyable }) => !copyable)
            .forEach((propertyDefinition: FormPropertyDefinition) => {
                const { value, resetValue } = propertyDefinition;
                const joinHelper = value.split('.');
                joinHelper.shift();
                const key = joinHelper.join('.');
                _.set(this._data.form, key, resetValue);
            });
        Object.assign(
            this._data.form,
            [{ iteratePage: null }],
        );
    }

    public prepareFormDataForAction(action: string, dataPath: string | Path): any {
        const actionSwitcher = {
            [FORMS.ACTIONS.COPY]: this.copyRecurringData.bind(this),
            [FORMS.ACTIONS.NEW]: this.newRecurringData.bind(this),
            [FORMS.ACTIONS.EDIT]: samePath => samePath,
        };
        return actionSwitcher[action]
            ? actionSwitcher[action](dataPath)
            : dataPath;
    }

    private clearDataRecursively(path: Path): void {
        const data = _.get(this._data, path.toString());
        if (_.isPlainObject(data)) {
            const clearingData = this.getViewModelTemplate().getClearingDataForPath(path, this._data);

            if (clearingData) {
                _.merge(data, clearingData);
            }
            _.keys(data).forEach(key => {
                const subPath = path.getExtendedPath(key);
                this.clearDataRecursively(subPath);
            });
        } else if (_.isArray(data)) {
            data.forEach((item, index) => {
                const subPath = path.getExtendedPath(index);
                this.clearDataRecursively(subPath);
            });
        }
    }

    public deleteData(dataPath: string | Path): void {
        const path = new Path(dataPath);
        const parentDataPath = path.getParentPath();
        const data = this.getData(parentDataPath);

        if (_.isArray(data)) {
            const index = path.getIndex();
            data.splice(index, 1);
        } else {
            _.unset(data, [path.getLastSelector()]);
        }
    }

    public copyRecurringData(dataPath: string | Path): null | string {
        const path = new Path(dataPath);

        const dataToCopy = this.getData(path);
        const parentPath = path.getParentPath();
        const dataArray = this.getData(parentPath);
        const newData = _.cloneDeep(dataToCopy);
        const clearingData = this.getViewModelTemplate().getClearingDataForPath(path, this._data);
        _.merge(newData, clearingData);

        if (_.isArray(dataArray)) {
            dataArray.push(newData);
            return parentPath.getExtendedPath(dataArray.length - 1).toString();
        }
        return null;
    }

    public newRecurringData(dataPath: string | Path): null | string {
        const path = new Path(dataPath);

        const dataArray = this.getData(path);
        if (_.isArray(dataArray)) {
            dataArray.push(this.getViewModelTemplate().getDefaultDataForPath(path, this._data));
            return path.getExtendedPath(dataArray.length - 1).toString();
        }
        return null;
    }

    public getStyledHtml(XTemplate) {
        let outputTemplate = null;

        let loadTemplateFromFile = false;

        if (context) {
            loadTemplateFromFile = true;
            outputTemplate = context.requireFormOutputTemplate(this.getProperty(FORMS.PROPERTIES.FORM_NAME.value), this.getProperty(FORMS.PROPERTIES.FORM_VERSION.value));
        }
        /* eslint-disable no-eval */
        if (!loadTemplateFromFile) {
            outputTemplate = eval(this.getFormJSON().output_template);
        }
        /* eslint-enable no-eval */

        const formData = this.getData();
        const outputData = _.cloneDeep(formData);

        return FormDocumentManager.renderHtml(outputTemplate, outputData, (template, data) => {
            const outputXTemplate = new XTemplate(template);
            const html = outputXTemplate.apply(data);

            return html;
        });
    }

    public applyOutputTemplate(extContainer, XTemplate) {
        const styledHtml = this.getStyledHtml(XTemplate);
        extContainer.setHtml(styledHtml);
    }

    static renderHtml(outputTemplate, data, renderer) {
        const styles = outputTemplate.match(/<style>[\s\S]*?<\/style>/gi);

        let styleIndex = 0;
        const outputTemplateWithoutStyles = outputTemplate.replace(/<style>[\s\S]*?<\/style>/gi, () => {
            const placeHolder = `style-placeholder-${styleIndex}`;
            styleIndex += 1;
            return placeHolder;
        });

        const html = renderer(outputTemplateWithoutStyles, data);

        const styledHtml = html.replace(/style-placeholder-(\d+)/gi, (match, _styleIndex) => styles[_styleIndex]);

        return styledHtml;
    }
}
