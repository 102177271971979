import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageBlock from '@components/layout/PageBlock';
import { companySettings, displayProjectsOnPortal } from '@data/state/companySettings';
import { useResponsive } from '@data/hooks/responsive';
import PartOfTheDaySelector
    from '@features/appointment/createAppointmentWizard/partOfTheDaySelector';
import AppointmentTypeCalendar
    from '@features/appointment/createAppointmentWizard/appointmentTypeCalendar';
import LabelledBox from '@components/LabelledBox/LabelledBox';
import {
    getPartOfDayNameByKey,
    DayPartKey,
} from '@powerednow/shared/modules/utilities/date';
import ConfirmBookingAppointment
    from '@features/appointment/createAppointmentWizard/confirmBookingAppointment';
import {
    STATUS,
    ACTION,
    SETTINGS_KEYS, 
} from '@powerednow/shared/constants';

import ProjectSelector from '@features/appointment/createAppointmentWizard/projectSelector';
import {
    AppointmentTypeField,
} from '@features/appointment/createAppointmentWizard/AppointmentTypeField';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRecoilValue } from 'recoil';
import ErrorBoundaryWithTryAgainModal from '@components/errorBoundary/errorBoundary';
import AppointmentTypeSelector from './appointmentTypeSelector';

const useStyles = makeStyles<Theme>(createStyles({
    appointmentDetailsContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    bookingStepsContainer: {
        alignItems: 'center',
        border: '1px solid silver',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        maxWidth: '500px',
    },
    confirmBookingDataContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    helperText: {
        textAlign: 'center',
    },
    appointmentDetails: {
        wordBreak: 'break-word',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

export default function AppointmentWizard() {
    const [selectedAppointmentTypeId, setSelectedAppointmentTypeId] = useState<any>(-1);
    const [selectedAppointmentDefaultDurationId, setSelectedAppointmentDefaultDurationId] = useState<number>(-1);
    const [selectedDay, setSelectedDay] = useState<Date | null>(null);
    const [selectedDaySlots, setSelectedDaySlots] = useState<any | null>(null);
    const [selectedPartOfDay, setSelectedPartOfDay] = useState<DayPartKey | null>(null);
    const [confirmedPartOfDay, setConfirmedPartOfDay] = useState<DayPartKey | null>(null);
    const [lastBookedAppointmentId, setLastBookedAppointmentId] = useState<number | null>(null);
    const [selectedOpenProjectId, setSelectedOpenProjectId] = useState<number>(-1);
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [slotWasTaken, setSlotWasTaken] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');

    const displayProjects = useRecoilValue(displayProjectsOnPortal);
    const responsive = useResponsive();
    const classes = useStyles();

    const isAppointmentTypeSelected = selectedAppointmentTypeId !== -1;
    const shouldShowBookingAppointmentDetails = isAppointmentTypeSelected && (!displayProjects || selectedOpenProjectId !== -1) && !selectedPartOfDay;
    const shouldShowConfirmBookingAppointment = isAppointmentTypeSelected && (!displayProjects || selectedOpenProjectId !== -1) && selectedPartOfDay;
    const isAppointmentTypeAllDay = selectedAppointmentDefaultDurationId === ACTION.APPOINTMENT_DURATION.WORKING_DAYS;
    const isPartOfTheDaySelectorNeeded = !isAppointmentTypeAllDay || confirmed;
    const bookAppointmentDefaultText = useRecoilValue(companySettings(SETTINGS_KEYS.BOOK_APPOINTMENT_DEFAULT_TEXT));

    const defaultText = 'Please select an available date';
    const [helperText, setHelperText] = useState<string>(defaultText);
    const getHelperText = () => {
        if (!selectedDay) {
            setHelperText(defaultText);
        } else {
            setHelperText('Please select an available time');
        }
    };

    useEffect(() => {
        setConfirmed(false);
        setSlotWasTaken(false);
        getHelperText();
    }, [selectedDay]);

    const handleConfirmed = id => {
        setConfirmed(true);
        setConfirmedPartOfDay(null);
        setSelectedPartOfDay(null);
        setLastBookedAppointmentId(id);
        setComment('');
    };

    const handleSetComment = description => {
        setComment(description);
    };

    const handleCanceled = () => {
        setSelectedPartOfDay(null);
        setComment('');
    };

    const handleAppointmentTypeSelectorChange = (value, selectedAppointmentDurationId) => {
        // Reset calendar
        setConfirmed(false);
        setSlotWasTaken(false);
        setSelectedDay(null); 
        setSelectedDaySlots(null);
        setSelectedPartOfDay(null);
        setSelectedAppointmentTypeId(value);
        setSelectedAppointmentDefaultDurationId(selectedAppointmentDurationId);
    };

    const filterProjectSelector = job => job.data.status !== STATUS.ID.JOB_CLOSED;
    const handleProjectSelectorChange = value => {
        setSelectedOpenProjectId(value);
    };

    const handleSelectedPartOfDay = value => {
        setSelectedPartOfDay(value);
    };

    const selectBookingDate = (
        <Box>
            <Typography variant="h6" className={classes.helperText}>{helperText}</Typography>
            <PartOfTheDaySelector
                onChange={handleSelectedPartOfDay}
                availability={selectedDaySlots}
                value={confirmedPartOfDay}
                autoSelect={false}
            />
        </Box>
    );

    const textAfterBookingConfirmed = (
        <strong>
            {getPartOfDayNameByKey(confirmedPartOfDay)}
        </strong>
    );

    const bookingDataForConfirm = (
        <Box
            className={classes.confirmBookingDataContainer}
            mt={2}
            p={2}
        >
            <Typography paragraph={false} component="div">
                <AppointmentTypeField appointmentTypeId={selectedAppointmentTypeId} field="messageAfter" />
                <br />
                <br />
                {!isAppointmentTypeAllDay && textAfterBookingConfirmed}
                <strong>{selectedDay?.toDateString()}</strong>
            </Typography>
        </Box>
    );

    // TODO: PN-9021 - Appointment synchronization
    const slotIsTakenDuringBooking = (
        <LabelledBox
            labelPosition={responsive({ xs: 'top', md: 'left' })}
            title=""
        >
            <Typography>
                I&apos;m sorry, that slot has now been taken, please
                choose another time
                <br />
            </Typography>
            <br />
        </LabelledBox>
    );

    const confirmBookingAppointment = (
        <ConfirmBookingAppointment
            partOfDay={selectedPartOfDay}
            day={selectedDay}
            appointmentTypeId={selectedAppointmentTypeId}
            projectId={selectedOpenProjectId}
            onConfirmed={handleConfirmed}
            onCancel={handleCanceled}
            comment={comment}
            onSetComment={handleSetComment}
        />
    );

    const partOfTheDaySelector = (
        <Box
            className={classes.bookingStepsContainer}
            justifyContent={(confirmed || slotWasTaken) ? 'flex-start' : 'center'}
            ml={responsive({ xs: 0, md: 2 })}
            mt={responsive({ xs: 2, md: 0 })}
            p={2}
            width={responsive({ xs: '100%', md: 'auto' })}
            minHeight={(confirmed || slotWasTaken) ? 'auto' : 300}
        >
            {!confirmed && selectBookingDate}
            {confirmed && bookingDataForConfirm}
            {slotWasTaken && selectedPartOfDay && slotIsTakenDuringBooking}
        </Box>
    );

    const bookingAppointmentDetails = (
        <Box
            className={classes.appointmentDetailsContainer}
            alignItems={responsive({ xs: 'center', md: 'stretch' })}
            flexDirection={responsive({ xs: 'column', md: 'row' })}
        >
            <AppointmentTypeCalendar
                appointmentTypeId={selectedAppointmentTypeId}
                onSelectDay={(date, daySlots) => {
                    setSelectedDay(date);
                    setSelectedDaySlots(daySlots);
                    if (isAppointmentTypeAllDay) {
                        handleSelectedPartOfDay('allDay');
                    }
                }}
                selectedDay={selectedDay}
                lastBookedAppointmentId={lastBookedAppointmentId}
            />
            {/* If appointment type is "all day" then there is no need to show <PartOfTheDaySelector> component */}
            {isPartOfTheDaySelectorNeeded && partOfTheDaySelector}
        </Box>
    );

    const appointmentTypeDetails = (
        <LabelledBox
            labelPosition={responsive({ xs: 'top', md: 'left' })}
            title="Details:"
        >
            <Box mb={2}>
                <Typography paragraph={false} className={classes.appointmentDetails} component="div">
                    <AppointmentTypeField
                        appointmentTypeId={selectedAppointmentTypeId}
                        field="description"
                        suffix={<br />}
                    />
                    <AppointmentTypeField
                        appointmentTypeId={selectedAppointmentTypeId}
                        field="messageBefore"
                    />
                </Typography>
            </Box>
        </LabelledBox>
    );

    return (
        <PageBlock
            className={classes.root}
            title={bookAppointmentDefaultText.toString()}
            p={8}
            pb={16}
        >
            <ErrorBoundaryWithTryAgainModal>
                <AppointmentTypeSelector
                    selectedAppointmentTypeId={selectedAppointmentTypeId}
                    handleChange={handleAppointmentTypeSelectorChange}
                />
                <ProjectSelector
                    selectedProjectId={selectedOpenProjectId}
                    filter={filterProjectSelector}
                    onChange={handleProjectSelectorChange}
                />
                {isAppointmentTypeSelected && appointmentTypeDetails}
                {shouldShowBookingAppointmentDetails && bookingAppointmentDetails}
                {shouldShowConfirmBookingAppointment && confirmBookingAppointment}
            </ErrorBoundaryWithTryAgainModal>
        </PageBlock>
    );
}
