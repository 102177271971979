/**
 * Created by zoltanmagyar on 2017. 04. 19..
 */

const constants = require('@powerednow/shared/constants/businessCalculator').default;

module.exports = {

    /**
     * Floating point comparison
     * http://stackoverflow.com/questions/4915462/how-should-i-do-floating-point-comparison
     *
     * @param a
     * @param b
     * @param epsilon
     * @returns {boolean}
     */
    nearlyEqual(a, b, epsilon = 1.0e-15) {
        const absA = Math.abs(a);
        const absB = Math.abs(b);
        const diff = Math.abs(a - b);
        const minNormal = 1.1754943508222875e-38;

        // eslint-disable-next-line eqeqeq
        if (a == b) { // shortcut, handles infinities
            return true;
        } if (a === 0 || b === 0 || diff < minNormal) {
            //
            // a or b is zero or both are extremely close to it
            // relative error is less meaningful here
            //
            return diff < (epsilon * minNormal);
        }
        //
        // use relative error
        //
        return diff / (absA + absB) < epsilon;
    },

    roundPrice(value) {
        return this._round(value, constants.PRICE_PRECISION_MULTIPLIER);
    },

    round(value) {
        return this._round(value, constants.PRECISION_MULTIPLIER);
    },

    hex(value) {
        return Math.floor(value).toString(16);
    },

    _round(value, precision) {
        return Math.round(value * precision) / precision;
    },

    /**
     * Calculate tax included in baseValue on the give rate
     *
     * @param baseValue
     * @param taxRate
     * @returns {number}
     */
    calculateIncludedTax(baseValue, taxRate) {
        const exclusiveCost = this.round(baseValue / (1 + (taxRate / 100)));
        const taxValue = baseValue - exclusiveCost;
        return this.round(taxValue);
    },

    /**
     * Calculate tax on the given exclusiveValue by using the given taxRate
     *
     * @param exclusiveValue
     * @param taxRate
     * @returns {number}
     */
    calculateTax(exclusiveValue, taxRate) {
        return this.round((exclusiveValue * taxRate) / 100);
    },

    /**
     * Calculate tax rate from the given inclusive value and tax value
     *
     * @param inclusiveValue
     * @param taxValue
     * @returns {number}
     */
    calculateProRate(inclusiveValue, taxValue) {
        return (taxValue * 100) / inclusiveValue;
    },

    /**
     * calculate tax rate by cost and vat
     *
     * @param subtotal
     * @param vat
     * @param isInclusive
     * @returns {number}
     */
    getTaxRateByCost(subtotal, vat, isInclusive) {
        const exclCost = isInclusive ? subtotal - vat : subtotal;
        return (vat / (exclCost)) * 100;
    },

    sum(...operands) {
        return operands.reduce((a, b) => a + b, 0);
    },

    avg(...operands) {
        return this.sum(...operands) / operands.length;
    },

    /**
     * gets the closest value for a given number from an array of numbers;
     *
     * @param number
     * @param numbers
     * @returns {*}
     */
    closest(number, numbers) {
        return numbers.map(item => ({ new: Math.abs(item - number), old: item })).sort((a, b) => a.new - b.new)[0].old;
    },
};
