import ApiRequest from '@powerednow/apiRequest';
import config from 'src/config';
import { AuthData } from '@interfaces/api/AuthData';
import HTTP, { type HttpHeadersType } from '@powerednow/shared/constants/http';
import LOGIN from '@powerednow/shared/constants/login';
import { memoizeRunningPromise } from '@powerednow/shared/modules/utilities/decorators';
import { TokenDataValues } from '@powerednow/models/tokenData/entity';

class PortalApiRequest extends ApiRequest {
    protected static getHeaders(): Partial<Record<HttpHeadersType, any>> {
        return {
            ...super.getHeaders(),
            [HTTP.AUTHENTICATION_HEADER_DOMAIN]: LOGIN.SESSION_DOMAINS.PORTAL,
        };
    }

    protected getAuthHeaders(): Partial<Record<HttpHeadersType, any>> {
        return {
            [HTTP.AUTHENTICATION_HEADER_COMPANY]: this.authData?.companyId,
            [HTTP.AUTHENTICATION_HEADER_CUSTOMER]: this.authData?.customerId,
            [HTTP.AUTHENTICATION_HEADER_TOKEN]: this.authData?.authToken,
            [HTTP.AUTHENTICATION_HEADER_DEVICE]: -1,
        };
    }

    public async login(email: string, password: string): Promise<any> {
        return this.postUnAuthRequest('interfaces/portal/login', { email, password });
    }

    public async resetPassword(email: string, redirectUrl: string): Promise<any> {
        return this.postUnAuthRequest('interfaces/portal/resetpassword', { email, redirectUrl });
    }

    public async setPasswordByReminderHash(password: string, passwordReminderHash: string): Promise<any> {
        return this.postUnAuthRequest('interfaces/portal/setnewpassword', { password, passwordReminderHash });
    }

    public async register(authData: AuthData, userData: {
        password: string,
        email: string,
        firstname: string,
        lastname: string,
    }): Promise<any> {
        return this.postUnAuthRequest('interfaces/register', { authData, userData });
    }

    public async setPasswordByCompanyData(
        password: string,
        userUuid: string | undefined,
        companyId,
    ): Promise<any> {
        return this.postUnAuthRequest('interfaces/portal/setnewpassword', {
            password,
            userUuid,
            companyId,
        });
    }

    @memoizeRunningPromise
    public async getAuthToken(portalId: string): Promise<TokenDataValues> {
        return this.postUnAuthRequest('interfaces/autoLogin', { token: portalId });
    }

    public async resendAuthToken(portalId: string): Promise<void> {
        return this.postUnAuthRequest('interfaces/autoLogin', { portalId });
    }
}

export const getApiRequest = () => new PortalApiRequest(
    config.API_URL,
    {
        companyId: null,
        customerId: null,
        contactId: null,
        authToken: null,
        userProfiles: null,
    },
    config.retryOptions,
);

export const getApiRequestForAuth = (authData: AuthData) => new PortalApiRequest(
    config.API_URL,
    authData,
    config.retryOptions,
);
