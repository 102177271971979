import { jsx as _jsx } from "react/jsx-runtime";
import { Badge as MUIBadge } from '@mui/material';
import clsx from 'clsx';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { omit } from 'lodash';
const getColor = (theme, name) => ({
    '&> .MuiBadge-badge': {
        backgroundColor: theme.palette[name].main,
        color: (name === 'warning' || name === 'neutral') ? theme.palette[name].contrastText : theme.palette[name].light,
    },
});
const useStyles = makeStyles(theme => createStyles({
    root: {
        '&> .MuiBadge-badge': {
            border: 'solid 1px white',
        },
    },
    primary: getColor(theme, 'primary'),
    secondary: getColor(theme, 'secondary'),
    warning: getColor(theme, 'warning'),
    error: getColor(theme, 'error'),
    neutral: getColor(theme, 'neutral'),
    small: {
        '&> .MuiBadge-badge': {
            fontSize: theme.spacing(0.8 * 2.4),
        },
    },
    medium: {
        '&> .MuiBadge-badge': {
            fontSize: theme.spacing(2.4),
        },
    },
    large: {
        '&> .MuiBadge-badge': {
            fontSize: theme.spacing(1.2 * 2.4),
        },
    },
    noChildren: {
        '&> .MuiBadge-badge': {
            position: 'relative',
            top: 'initial',
            right: 'initial',
            transform: 'initial',
            transformOrigin: 'initial',
            paddingTop: '1px',
        },
    },
}));
export default function Badge(props) {
    const classes = useStyles();
    let positionClasses = null;
    const { children, className, content, color, size, } = props;
    const colorClasses = clsx(classes === null || classes === void 0 ? void 0 : classes[color || 'primary']);
    const sizeClasses = clsx(classes === null || classes === void 0 ? void 0 : classes[size || 'medium']);
    if (!children) {
        positionClasses = classes.noChildren;
    }
    return (_jsx(MUIBadge, Object.assign({ className: clsx(className, colorClasses, sizeClasses, classes.root, positionClasses), badgeContent: content }, omit(props, 'className', 'size', 'content', 'badgeContent', 'color'))));
}
