import ComplexData from '@powerednow/complexData/complexData';
import { useRecoilValue } from 'recoil';
import { useErrorHandler } from 'react-error-boundary';
import useBaseComplexData from '@powerednow/hooks/complexDataHook';
import {
    ComplexModelEntity, ComplexModelInstance, ModelMapKeys,
} from '@powerednow/models/modelLookup';
import type { DataWrapperInterface } from '@powerednow/complexData/connection/api/dataWrapper';
import { dataWrapper } from '../state/auth';

export default function useComplexData <O, T extends ModelMapKeys>(
    complexModelName: T,
    deps: number | Array<any> | null,
    callback: (
        _complexInstance: ComplexModelInstance<T>,
        _resultSetter: (_newResult: Partial<O>) => void,
        _watcher: <WC extends ComplexData | ComplexData[]>(_itemsToWatch: Promise<WC>) => Promise<WC>
    ) => Promise<void> = async () => {
    },
    dataWrapperInstance: DataWrapperInterface = useRecoilValue(dataWrapper),
    handleError: (e:unknown)=>void = useErrorHandler(),
): {
    details: ComplexModelEntity<T> | undefined,
    optionalResult: Partial<O> | undefined,
    error: Error | undefined
} {
    return useBaseComplexData(complexModelName, deps, dataWrapperInstance, callback, handleError);
}

export function useSimpleComplexData< T extends ModelMapKeys>(complexModelName: T, deps: number | null) {
    const { details } = useComplexData(complexModelName, deps);
    return details;
}

export function useComplexDataWithErrorHandling <O, T extends ModelMapKeys>(
    complexModelName: T,
    deps: number | Array<any> | null,
    callback: (
        _complexInstance: ComplexModelInstance<T>,
        _resultSetter: (_newResult: Partial<O>) => void
    ) => Promise<void> = async () => {},
    handleError: (e:unknown)=>void = useErrorHandler(),
): {
    details: ComplexModelEntity<T> | undefined,
    optionalResult: Partial<O> | undefined,
    error: Error | undefined
} {
    return useComplexData(complexModelName, deps, callback, useRecoilValue(dataWrapper), handleError);
}
