import React from 'react';
import PageBlock from '@components/layout/PageBlock';
import ItemDetailSection from '@components/ItemInformations/itemDetailSection';
import { useResponsive } from '@data/hooks/responsive';
import { TYPES_VALUES } from '@powerednow/shared/constants/document';
import { ValueOf } from '@powerednow/type-definitions';
import { DocumentSummaryData } from '@powerednow/models/document';
import DocumentSummaryBlock from '@features/document/DocumentSummaryBlock';

type DocumentTypeValue = ValueOf<typeof TYPES_VALUES>;

export interface DocumentBlockProps {
    type?: DocumentTypeValue,
    title: string,
    summaryData?: DocumentSummaryData,
    total?: string,
    dueDate?: string,
    dateOfIssue?: string,
    status: string,
    refuseReason?: string,
    children?: React.ReactNode,
}

export default function DocumentBlock({
    type,
    children,
    title,
    summaryData,
    total,
    dueDate,
    dateOfIssue,
    status,
    refuseReason,
}: DocumentBlockProps) {
    const responsive = useResponsive();
    const allowedDocumentTypes: Array<DocumentTypeValue> = [
        TYPES_VALUES.INVOICE,
        TYPES_VALUES.QUOTE,
        TYPES_VALUES.WORKSHEET,
    ];
    const shouldRenderStatusSection = typeof type === 'number' && allowedDocumentTypes.includes(type);
    const shouldShowDocumentTotal = (typeof type === 'number' && type !== TYPES_VALUES.SIGNATURE) && Boolean(total);
    const { groupTitles, itemDescriptions } = summaryData || {};

    return (
        <PageBlock
            title={title}
            display="flex"
            flexDirection="column"
            mb={8}
            flex={responsive({ sm: 2, md: 5 })}
            pt={0}
            pl={0}
            pb={0}
        >
            <DocumentSummaryBlock groupTitles={groupTitles} itemDescriptions={itemDescriptions} />
            <ItemDetailSection title="Total:" text={total} hidden={!shouldShowDocumentTotal} />
            <ItemDetailSection title="Date of Issue:" text={dateOfIssue} hidden={!dateOfIssue} />
            <ItemDetailSection title="Due date:" text={dueDate} hidden={!dueDate} />
            <ItemDetailSection title="Status:" text={status} hidden={!shouldRenderStatusSection} />
            <ItemDetailSection title="Refuse reason:" text={refuseReason} hidden={!refuseReason} />
            {children}
        </PageBlock>
    );
}
