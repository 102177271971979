import useComplexData from '@data/hooks/complexDataHook';
import { ACTION, SETTINGS_KEYS } from '@powerednow/shared/constants';
import { ComplexModelEntity } from '@powerednow/models/modelLookup';

export type AppointmentBookingSettings = {
    companyName?: ComplexModelEntity<'Company'>['name'],
    planType: number,
    eveningHours,
    workdayHalfTime: string,
    lunchTime,
    workday_start: ComplexModelEntity<'Company'>['workday_start'],
    workday_end: ComplexModelEntity<'Company'>['workday_end'],
};

export type AppointmentBookingSettingsResult = {
    loaded: boolean,
    data: AppointmentBookingSettings
}
export default (companyId: number | null) => {
    const { optionalResult } = useComplexData(
        'Company',
        companyId,
        async (complexCompany, resultSetter: (_newResult:AppointmentBookingSettingsResult)=>void) => {
            //
            // Due to PN-10765 it is temporarily make hard coded to unplanned for day.
            //
            // const planType = parseInt(await complexCompany.getSettingValue(SETTINGS_KEYS.BOOK_APPOINTMENT_PLAN_TYPE) as string, 10);
            const planType = ACTION.PLAN_TYPES.UNPLANNED_FOR_DAY.ID;
            const lunchTime = await complexCompany.getParsedSettingValue(SETTINGS_KEYS.LUNCH_TIME);
            const eveningHours = await complexCompany.getParsedSettingValue(SETTINGS_KEYS.EVENING_HOURS);
            let workdayHalfTime = complexCompany.workdayHalfTime();

            if (lunchTime.enabled) {
                workdayHalfTime = '';
            }

            resultSetter({
                loaded: true,
                data: {
                    companyName: complexCompany.data.name,
                    planType,
                    eveningHours,
                    workdayHalfTime,
                    lunchTime,
                    workday_start: complexCompany.data.workday_start,
                    workday_end: complexCompany.data.workday_end,
                },
            });
        },
    );

    return {
        data: optionalResult?.data,
        loaded: optionalResult?.loaded || false,
    };
};
