import React, { useState } from 'react';
import { Typography, Box, BoxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import useComplexData from '@data/hooks/complexDataHook';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { useResponsive } from '@data/hooks/responsive';
import MediaDisplay from '@features/media/mediaDisplay';
import UserAvatar from '@components/Avatar/UserAvatar/UserAvatar';
import { AvatarSizes } from '@components/Avatar/Avatar';
import htmlDecode from '@components/helper/htmlDecode';
import {
    displayAssigneePhoneNumberOnPortal,
    displayCompanyPhoneNumberOnPortal,
} from '@data/state/companySettings';
import { useRecoilValue } from 'recoil';
import { OptionalTextWithIcon } from '@components/fields/TextWithIcon';
import Qualifications, { Qualification } from './qualifications/Qualifications';

type UserDetailsProps = BoxProps & {
    userId: number,
}

type UserDetailsAvatarProps = {
    profilePictureUrl?: string,
    fullName?: string,
}

type UserDetailsAndProfileResults = {
    assigneePhoneNumber?: string,
    companyPhoneNumber?: string,
    jobTitle?: string,
    profilePictureUrl?: string,
}

type UserContactInformationProps = UserDetailsAndProfileResults & {
    fullName?: string,
}

const useStyles = makeStyles<Theme>(createStyles({
    root: {
        display: 'flex',
        justifyContent: 'start',
    },
    qualifications: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '50vh',
        overflow: 'auto',
        alignItems: 'center',
        width: '100%',
        paddingTop: '15px',
    },
}));

const useUserDetailsAndProfile = userId => {
    const { details, optionalResult } = useComplexData(
        'User',
        userId,
        async (complexUser, resultSetter: (_newResult:UserDetailsAndProfileResults)=>void) => {
            const jobTitle = await complexUser?.getJobTitle();
            const assigneePhoneNumber = await complexUser?.getPhoneNumber();
            const companyPhoneNumber = await complexUser?.getCompanyPhoneNumber();
            const userMedia = await complexUser?.getUserProfilePicture();

            resultSetter({
                assigneePhoneNumber,
                companyPhoneNumber,
                jobTitle,
                profilePictureUrl: userMedia?.data?.getPureDataValues().url,
            });
        },
    );
    return {
        details,
        assigneePhoneNumber: optionalResult?.assigneePhoneNumber,
        companyPhoneNumber: optionalResult?.companyPhoneNumber,
        jobTitle: optionalResult?.jobTitle,
        profilePictureUrl: optionalResult?.profilePictureUrl,
    };
};

const useUserImageStyles = makeStyles<Theme>(createStyles({
    root: {
        display: 'flex',
        flex: '0',
    },
    image: {
        borderRadius: 8,
    },
}));
function UserDetailsAvatar(props: UserDetailsAvatarProps) {
    const { fullName, profilePictureUrl } = props;
    const classes = useUserImageStyles();
    const responsive = useResponsive();
    const size: AvatarSizes = responsive({
        xs: 'xlarge',
        md: 'xxlarge',
        lg: 'xxxlarge',
    });
    return (
        <Box className={classes.root}>
            <UserAvatar
                profilePictureUrl={profilePictureUrl}
                size={size}
                className={classes.image}
                alt={fullName}
            />
        </Box>
    );
}

const useUserContactInformationStyles = makeStyles<Theme>(createStyles({
    contactInformation: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '1rem',
        minHeight: 90,
    },
    phoneNumber: {
        display: 'flex',
        alignItems: 'center',
    },
}));
function UserContactInformation(props: UserContactInformationProps) {
    const {
        fullName,
        jobTitle,
        assigneePhoneNumber,
        companyPhoneNumber,
    } = props;
    const classes = useUserContactInformationStyles();
    const displayAssigneePhone = useRecoilValue(displayAssigneePhoneNumberOnPortal);
    const displayCompanyPhone = useRecoilValue(displayCompanyPhoneNumberOnPortal);
    const shouldRenderAssigneePhone = displayAssigneePhone && Boolean(assigneePhoneNumber);
    const shouldRenderCompanyPhone = displayCompanyPhone && Boolean(companyPhoneNumber);

    return (
        <Box className={classes.contactInformation}>
            <Typography variant="h5">
                {htmlDecode(fullName)}
            </Typography>
            <OptionalTextWithIcon
                startIcon={<PhoneOutlinedIcon />}
                renderIf={shouldRenderAssigneePhone}
                className={classes.phoneNumber}
            >
                {assigneePhoneNumber}
            </OptionalTextWithIcon>
            <OptionalTextWithIcon
                startIcon={<PhoneOutlinedIcon />}
                renderIf={shouldRenderCompanyPhone}
                className={classes.phoneNumber}
            >
                {companyPhoneNumber}
            </OptionalTextWithIcon>
            <Box>{htmlDecode(jobTitle)}</Box>
        </Box>
    );
}

export default function UserDetails(props: UserDetailsProps) {
    const [dialogDisplayFlag, setDialogDisplayFlag] = useState<boolean>(false);
    const [dialogMedia] = useState<Qualification>({});
    const classes = useStyles();
    const { userId } = props;
    const {
        details: userDetails,
        assigneePhoneNumber,
        jobTitle,
        profilePictureUrl,
        companyPhoneNumber,
    } = useUserDetailsAndProfile(userId);

    if (!userDetails || !userId) {
        return (
            <Box>
                No engineer(s) assigned yet.
            </Box>
        );
    }

    const fullName = `${userDetails.firstname} ${userDetails.lastname}`;
    
    return (
        <Box className={classes.root}>
            <UserDetailsAvatar
                fullName={fullName}
                profilePictureUrl={profilePictureUrl}
            />
            <Box>
                <UserContactInformation
                    fullName={fullName}
                    assigneePhoneNumber={assigneePhoneNumber}
                    companyPhoneNumber={companyPhoneNumber}
                    jobTitle={jobTitle}
                />
                <Box className={classes.qualifications}>
                    <Qualifications userId={userId} />
                    <MediaDisplay
                        description={`${dialogMedia.description}`}
                        url={`${dialogMedia.url}`}
                        open={dialogDisplayFlag}
                        onClose={setDialogDisplayFlag}
                    />
                </Box>
            </Box>
        </Box>
    );
}
