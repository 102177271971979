import React, { useState } from 'react';
import MediaDisplay from '@features/media/mediaDisplay';
import Button from '@components/button/Button';
import { Box } from '@mui/material';
import { useResponsive } from '@data/hooks/responsive';
import DocumentPreview from './DocumentPreview';

export interface DocumentWithPreviewProps {
  previewUrl: string;
  mediaUrl: string;
  description: string;
}

export default function DocumentWithPreview({
    description,
    mediaUrl,
    previewUrl,
}: DocumentWithPreviewProps) {
    const responsive = useResponsive();
    const [dialogDisplayFlag, setDialogDisplayFlag] = useState(false);

    const handleClick = () => setDialogDisplayFlag(true);

    return (
        <Box
            display="flex"
            flexDirection="column"
            flex={responsive({ sm: 1, md: 3 })}
        >
            <DocumentPreview
                title={description}
                srcUrl={previewUrl}
                clickHandler={handleClick}
                clickable
                scrollable="no"
            />
            <MediaDisplay
                description={description}
                url={mediaUrl}
                open={dialogDisplayFlag}
                onClose={setDialogDisplayFlag}
            />
            <Button
                size="large"
                buttonType="primary"
                emphasis="low"
                onClick={handleClick}
                hidden={responsive({ xs: true, sm: false })}
            >
                View larger
            </Button>
        </Box>
    );
}
