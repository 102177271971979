import { INTEGRATION, DOCUMENT } from '@powerednow/shared/constants';
import { Nullable } from '@powerednow/type-definitions';

import * as moment from 'moment';
import * as _ from 'lodash';

const TAX_RATE_STATUS_ACTIVE = 'ACTIVE';

export default class XeroIntegration {
    static updateContactGroups(companyIntegrationObject, xeroContactGroups): void {
        const companyIntegration = companyIntegrationObject;

        companyIntegration.data.settings.contactGroupChoices = [
            INTEGRATION.CONTACT_GROUP_NONE,
            ...xeroContactGroups.map(xeroContactGroup => ({
                name: xeroContactGroup.Name,
                contactGroupId: xeroContactGroup.ContactGroupID,
            })),
        ];

        if (!companyIntegration.data.settings.contactGroupChoices.find(contactGroup => contactGroup.contactGroupId === companyIntegration.data.settings.contactGroupId)) {
            companyIntegration.data.settings.contactGroupId = INTEGRATION.CONTACT_GROUP_NONE.contactGroupId;
        }
    }

    static updateAccountCodes(companyIntegrationObject, xeroAccounts): void {
        const companyIntegration = companyIntegrationObject;

        companyIntegration.data.settings.accountCodeChoices = {
            [INTEGRATION.ACCOUNT_CODE_TYPES.SALES]: [
                ...xeroAccounts.filter(xeroAccount => (xeroAccount.Class === 'REVENUE' && xeroAccount.Status === 'ACTIVE'))
                    .map(xeroAccount => ({ name: xeroAccount.Name, code: xeroAccount.Code })),
            ],
            [INTEGRATION.ACCOUNT_CODE_TYPES.SUPPLIER_INVOICES]: [
                ...xeroAccounts.filter(xeroAccount => (xeroAccount.Class === 'EXPENSE' && xeroAccount.Status === 'ACTIVE'))
                    .map(xeroAccount => ({ name: xeroAccount.Name, code: xeroAccount.Code })),
            ],
            [INTEGRATION.ACCOUNT_CODE_TYPES.EXPENSES]: [
                ...xeroAccounts.filter(xeroAccount => (xeroAccount.Class === 'EXPENSE' && xeroAccount.Status === 'ACTIVE'))
                    .map(xeroAccount => ({ name: xeroAccount.Name, code: xeroAccount.Code })),
            ],
        };

        companyIntegration.data.settings.accountCodes = {
            [INTEGRATION.ACCOUNT_CODE_TYPES.SALES]:
                XeroIntegration.getDefaultAccountCode(
                    companyIntegration.data.settings.accountCodeChoices[INTEGRATION.ACCOUNT_CODE_TYPES.SALES],
                    companyIntegration.data.settings.accountCodes[INTEGRATION.ACCOUNT_CODE_TYPES.SALES],
                    '200',
                ),
            [INTEGRATION.ACCOUNT_CODE_TYPES.SUPPLIER_INVOICES]:
                XeroIntegration.getDefaultAccountCode(
                    companyIntegration.data.settings.accountCodeChoices[INTEGRATION.ACCOUNT_CODE_TYPES.SUPPLIER_INVOICES],
                    companyIntegration.data.settings.accountCodes[INTEGRATION.ACCOUNT_CODE_TYPES.SUPPLIER_INVOICES],
                    '300',
                ),
            [INTEGRATION.ACCOUNT_CODE_TYPES.EXPENSES]:
                XeroIntegration.getDefaultAccountCode(
                    companyIntegration.data.settings.accountCodeChoices[INTEGRATION.ACCOUNT_CODE_TYPES.EXPENSES],
                    companyIntegration.data.settings.accountCodes[INTEGRATION.ACCOUNT_CODE_TYPES.EXPENSES],
                    '300',
                ),
        };
    }

    static updateTaxRates(companyIntegrationObject, xeroTaxRates): void {
        const companyIntegration = companyIntegrationObject;

        const taxRateChoices = xeroTaxRates.filter(taxRate => taxRate.Status === TAX_RATE_STATUS_ACTIVE);

        companyIntegration.data.settings.taxRateChoices = taxRateChoices;
    }

    static updateBankAccountCodes(companyIntegrationObject, xeroAccounts) {
        const companyIntegration = companyIntegrationObject;

        const bankAccountChoices = xeroAccounts.filter(xeroAccount => ((xeroAccount.Class === 'BANK' || xeroAccount.Type === 'BANK') && xeroAccount.Status === 'ACTIVE' && xeroAccount.Code))
            .map(xeroAccount => ({ name: xeroAccount.Name, code: xeroAccount.Code }));

        const defaultBankAccountCode = this.getDefaultBankAccountCode(bankAccountChoices);

        Object.values(companyIntegration.data.settings.paymentTypeMapping).forEach(documentPaymentCategoryToPaymentTypes => {
            Object.entries(documentPaymentCategoryToPaymentTypes).forEach(([paymentCategory, paymentType]) => {
                const assignedBankAccountCodeExists = bankAccountChoices.find(bankAccountCode => bankAccountCode.code === paymentType);
                if (!assignedBankAccountCodeExists) {
                    Object.assign(documentPaymentCategoryToPaymentTypes, {
                        [paymentCategory]: defaultBankAccountCode,
                    });
                }
            });
        });

        companyIntegration.data.settings.bankAccountChoices = bankAccountChoices;
    }

    static getDefaultAccountCode(xeroAccounts, currentCode, defaultCode:Nullable<string> = null): string {
        if (xeroAccounts.find(xeroAccount => xeroAccount.code === currentCode)) {
            return currentCode;
        }

        if (xeroAccounts.find(xeroAccount => xeroAccount.code === defaultCode)) {
            return defaultCode;
        }

        return xeroAccounts.length > 0 ? xeroAccounts[0].code : null;
    }

    static initCompanyIntegration(companyIntegrationObject, enabledPaymentTypesByDocumentPaymentCategories): void {
        const companyIntegration = companyIntegrationObject;

        const settings = companyIntegration.data.settings || {};

        delete settings.disabled; // the `disabled` property should be removed because it was just a temporary thing while
        // we didn't want to make xero integration available for everyone.

        if (_.isEmpty(settings)) {
            Object.assign(settings, {
                accountCodes: {
                    [INTEGRATION.ACCOUNT_CODE_TYPES.SALES]: null,
                    [INTEGRATION.ACCOUNT_CODE_TYPES.SUPPLIER_INVOICES]: null,
                    [INTEGRATION.ACCOUNT_CODE_TYPES.EXPENSES]: null,
                },
                accountCodeChoices: {
                    [INTEGRATION.ACCOUNT_CODE_TYPES.SALES]: [],
                    [INTEGRATION.ACCOUNT_CODE_TYPES.SUPPLIER_INVOICES]: [],
                    [INTEGRATION.ACCOUNT_CODE_TYPES.EXPENSES]: [],
                },
                taxRateMapping: {
                    1: 'OUTPUT2',
                    2: 'RROUTPUT',
                    3: 'ZERORATEDOUTPUT',
                    4: 'EXEMPTOUTPUT',
                    5: 'INPUT2',
                    6: 'RRINPUT',
                    7: 'ZERORATEDINPUT',
                    8: 'EXEMPTINPUT',
                    9: 'NONE',
                    10: 'ECZROUTPUT',
                    11: 'ECZROUTPUTSERVICES',
                    12: 'OUTPUT2',
                    13: 'RROUTPUT',
                    14: 'ZERORATEDOUTPUT',
                    15: 'EXEMPTOUTPUT',
                    16: 'OUTPUT2',
                    17: 'RROUTPUT',
                    18: 'ZERORATEDOUTPUT',
                    19: 'EXEMPTOUTPUT',
                    20: 'ECACQUISITIONS',
                    21: 'REVERSECHARGES',
                    22: 'ECACQUISITIONS',
                    23: 'ECACQUISITIONS',
                    24: 'ECACQUISITIONS',
                    25: 'ECACQUISITIONS',
                    26: 'ECACQUISITIONS',
                    27: 'ECACQUISITIONS',
                    28: 'ECACQUISITIONS',
                    29: 'ECACQUISITIONS',
                    30: 'NONE',
                    31: 'NONE',
                    32: 'NONE',
                    33: 'NONE',
                    34: 'NONE',
                    35: 'NONE',
                    36: 'DRCHARGESUPPLY20',
                    37: 'DRCHARGESUPPLY5',
                    38: 'DRCHARGE20',
                    39: 'DRCHARGE5',
                },
                taxRateChoices: [],
                contactGroupId: INTEGRATION.CONTACT_GROUP_NONE.contactGroupId,
                contactGroupChoices: [INTEGRATION.CONTACT_GROUP_NONE],
                contactsCopied: false,
                sendContactsToXero: true,
                sendContactsToPN: false,
                sendSalesInvoicesToXero: true,
                sendSalesInvoicesApproved: true,
                sendSupplierInvoicesToXero: false,
                sendExpensesToXero: false,
                sendSupplierInvoicesApproved: true,
                automaticInterfacing: false,
            });
        }

        if (!_.has(settings, 'sendPaymentsToXero')) {
            // @ts-ignore
            settings.sendPaymentsToXero = false;
        }

        if (!_.has(settings, 'sendPaymentsToPN')) {
            // @ts-ignore
            settings.sendPaymentsToPN = false;
        }

        if (!_.has(settings, 'paymentTypeMapping')) {
            // @ts-ignore
            settings.paymentTypeMapping = {
                [DOCUMENT.DOCUMENT_PAYMENT_CATEGORIES.SALES]: {},
                [DOCUMENT.DOCUMENT_PAYMENT_CATEGORIES.SUPPLIER]: {},
                [DOCUMENT.DOCUMENT_PAYMENT_CATEGORIES.EXPENSE]: {},
            };
        }

        if (!_.has(settings, 'bankAccountChoices')) {
            // @ts-ignore
            settings.bankAccountChoices = [];
        }

        if (_.has(settings, 'taxRateMapping') && settings.taxRateMapping[36] === 'INPUT2') {
            settings.taxRateMapping[36] = 'DRCHARGESUPPLY20';
        }
        if (_.has(settings, 'taxRateMapping') && settings.taxRateMapping[37] === 'RRINPUT') {
            settings.taxRateMapping[37] = 'DRCHARGESUPPLY5';
        }
        if (_.has(settings, 'taxRateMapping') && settings.taxRateMapping[38] === 'OUTPUT2') {
            settings.taxRateMapping[38] = 'DRCHARGE20';
        }
        if (_.has(settings, 'taxRateMapping') && settings.taxRateMapping[39] === 'RROUTPUT') {
            settings.taxRateMapping[39] = 'DRCHARGE5';
        }

        this.updatePaymentTypeMapping(settings, enabledPaymentTypesByDocumentPaymentCategories);

        if (!companyIntegration.data.interfacing_start_date) {
            companyIntegration.data.interfacing_start_date = moment.utc().startOf('day').toDate();
        }

        companyIntegration.data.settings = _.cloneDeep(settings);
    }

    static setContactsCopied(companyIntegrationObject, copied): void {
        const companyIntegration = companyIntegrationObject;

        companyIntegration.data.settings.contactsCopied = copied;
    }

    static validateAccountCodeSettings(companyIntegration) {
        const { settings } = companyIntegration.data;
        if (settings.contactsCopied) {
            if (!settings.accountCodes[INTEGRATION.ACCOUNT_CODE_TYPES.EXPENSES]
                || !settings.accountCodes[INTEGRATION.ACCOUNT_CODE_TYPES.SALES]
                || !settings.accountCodes[INTEGRATION.ACCOUNT_CODE_TYPES.SUPPLIER_INVOICES]) {
                return false;
            }
        }

        return true;
    }

    static validateBankAccountCodeSettings(companyIntegration) {
        const { settings } = companyIntegration.data;
        let isValid = true;
        if (settings.contactsCopied && settings.sendPaymentsToXero) {
            Object.values(settings.paymentTypeMapping).forEach(documentPaymentCategoryToPaymentTypes => {
                Object.values(documentPaymentCategoryToPaymentTypes).forEach(bankAccountCode => {
                    if (bankAccountCode === INTEGRATION.UNDEFINED_BANK_ACCOUNT_CODE) {
                        isValid = false;
                    }
                });
            });
        }

        return isValid;
    }

    static updatePaymentTypeMapping(settings, enabledPaymentTypesByDocumentPaymentCategories) {
        Object.entries(settings.paymentTypeMapping).forEach(([key, documentPaymentCategoryToPaymentTypes]) => {
            let enabledPaymentTypes = _.cloneDeep(enabledPaymentTypesByDocumentPaymentCategories[key]);
            const paymentTypesToRemove = [];

            Object.keys(documentPaymentCategoryToPaymentTypes).forEach(paymentType => {
                const paymentTypeInt = parseInt(paymentType, 10);
                const paymentTypeExists = enabledPaymentTypes.includes(paymentTypeInt);
                if (!paymentTypeExists) {
                    paymentTypesToRemove.push(paymentTypeInt);
                }

                enabledPaymentTypes = enabledPaymentTypes.filter(enabledPaymentType => enabledPaymentType !== paymentTypeInt);
            });

            enabledPaymentTypes.forEach(paymentType => {
                Object.assign(documentPaymentCategoryToPaymentTypes, {
                    [paymentType]: this.getDefaultBankAccountCode(settings.bankAccountChoices),
                });
            });

            Object.keys(documentPaymentCategoryToPaymentTypes).forEach(paymentType => {
                const paymentTypeInt = parseInt(paymentType, 10);
                if (paymentTypesToRemove.includes(paymentTypeInt)) {
                    // eslint-disable-next-line no-param-reassign
                    delete documentPaymentCategoryToPaymentTypes[paymentTypeInt];
                }
            });
        });
    }

    static getPaymentTypeMappingKey(paymentCategory, paymentType) {
        return `${paymentCategory}.${paymentType}`;
    }

    static destructPaymentTypeMappingKey(key) {
        const [paymentCategory, paymentType] = key.split('.');
        return { paymentCategory: parseInt(paymentCategory, 10), paymentType: parseInt(paymentType, 10) };
    }

    static getDefaultBankAccountCode(bankAccountCodeChoices) {
        return bankAccountCodeChoices.length > 0 ? bankAccountCodeChoices[0].code : INTEGRATION.UNDEFINED_BANK_ACCOUNT_CODE;
    }
}
