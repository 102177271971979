import Box from '@mui/material/Box';
import React, { useState } from 'react';
import TextField from '@components/fields/TextField';
import Button from '@components/button/Button';
import SendIcon from '@mui/icons-material/Send';
import { useResponsive } from '@data/hooks/responsive';
import { ROLES, STATUS } from '@powerednow/shared/constants';
import { hasRole } from '@data/state/permission';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authState, dataWrapper } from '@data/state/auth';
import MESSAGES from '@powerednow/shared/constants/messages';
import ProjectSelector from '@features/appointment/createAppointmentWizard/projectSelector';
import DefaultMessages from '@powerednow/shared/constants/customerEmailTemplateValues';
import { format, getNow } from '@powerednow/shared/modules/utilities/date';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@components/toast/toast';
import { CircularProgress } from '@components/progress/Progress';
import Mask from '@components/mask/Mask';
import ErrorBoundaryWithTryAgainModal from '@components/errorBoundary/errorBoundary';
import type { DataWrapperType } from '@powerednow/complexData/connection/api/dataWrapper';
import ParentDeletedError from '@powerednow/apiRequest/error/ParentDeletedError';
import { complexFindModel, ComplexModelEntity, ComplexModelInstance } from '@powerednow/models/modelLookup';

type SendMessageProps = {
    containerBackgroundColor: string,
    parentCustomerMessage?: ComplexModelInstance<'CustomerMessage'> | null,
    onSent?: (() => (Promise<void> | void)) | null,
    onSend?: (() => (Promise<void> | void)) | null,
    onError?: ((err: Error) => (Promise<void> | void)) | null,
}

export interface StyleProps {
    containerBackgroundColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>(createStyles({
    title: {
        width: '100%',
        minWidth: 230,
        maxWidth: 700,
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        paddingBottom: 0,
        backgroundColor: ({ containerBackgroundColor }) => containerBackgroundColor,

    },
    contentBox: {
        display: 'flex',
        alignItems: 'flex-end',
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        backgroundColor: ({ containerBackgroundColor }) => containerBackgroundColor,
    },
}));

const getJobRecord = async ({
    dataWrapperInstance,
    selectedProjectId,
    companyId,
    contactId,
    job,
}:{
    dataWrapperInstance: DataWrapperType,
    selectedProjectId: number,
    companyId: number,
    contactId: number,
    job: ComplexModelInstance<'Job'>,
}) => {
    const complexCompany = await dataWrapperInstance.getComplexDataObject(complexFindModel('Company'), companyId);
    const complexContact = await dataWrapperInstance.getComplexDataObject(complexFindModel('Contact'), contactId);
    const complexCustomer = await complexContact?.getCustomer();
    const customerId = complexCustomer.data.getPureDataValues().id;
    if (selectedProjectId <= 0) {
        const jobData = {
            description: `Project Created on ${format(new Date(), 'ShortDate')}`,
            company_id: companyId,
            customer_id: customerId,
            contact_id: contactId,
            status: STATUS.ID.JOB_OPEN,
            is_autocreated: false,
        };
        Object.assign(job.data, jobData);
        await complexCustomer.addJob(job);
        await complexCompany.addJob(job);
        return job;
    }
    return complexCustomer.getJobById(selectedProjectId);
};

const createMessageRecords = async ({
    dataWrapperInstance,
    job,
    companyId,
    contactId,
    parentCustomerMessage,
    customerMessage,
    messageRecipient,
}: {
    dataWrapperInstance: DataWrapperType,
    job: ComplexModelInstance<'Job'>,
    companyId: number,
    contactId: number,
    parentCustomerMessage: ComplexModelInstance<'CustomerMessage'> | null | undefined,
    customerMessage: ComplexModelInstance<'CustomerMessage'>,
    messageRecipient: ComplexModelInstance<'MessageRecipient'>,
}): Promise<void> => {
    const complexCompany = await dataWrapperInstance.getComplexDataObject(complexFindModel('Company'), companyId);
    const mainUser = await complexCompany.getMainUser();
    const userId = mainUser.data.getPureDataValues().id;
    const complexContact = await dataWrapperInstance.getComplexDataObject(complexFindModel('Contact'), contactId);
    const complexCustomer = await complexContact?.getCustomer();
    const customerId = complexCustomer.data.getPureDataValues().id;

    const messageRecipientDetails: Partial<ComplexModelEntity<'MessageRecipient'>> = {
        contact_id: complexContact.data.id,
        contactmethodtype_id: MESSAGES.TYPES.PORTAL,
        recipientName: (await complexCompany.getMainUser()).getFullName(),
    };

    if (!parentCustomerMessage) {
        Object.assign(customerMessage.data, {
            company_id: companyId,
            customer_id: customerId,
            user_id: userId,
            job_id: job.data.id,
            linked_id: null,
            linked_type: null,
            type: DefaultMessages.DIRECT_MESSAGE.ID,
            direction: MESSAGES.MESSAGE_DIRECTION.INCOMING,
            dt_created: getNow(),
        });

        Object.assign(messageRecipient.data, {
            ...messageRecipientDetails,
        });
        await customerMessage.addMessageRecipient(messageRecipient);
        await complexCustomer.addCustomerMessage(customerMessage);
    } else {
        await parentCustomerMessage.addReply(
            messageRecipientDetails,
            MESSAGES.MESSAGE_DIRECTION.INCOMING,
            customerMessage,
            messageRecipient,
        );
    }

    await job.addCustomerMessage(customerMessage);
    await complexCompany.addJob(job);
    await complexCompany.getCustomerById(Number(complexCustomer.data.id));
};

const sendMessage = async ({
    dataWrapperInstance, companyId,
}) => {
    const complexCompany = await dataWrapperInstance.getComplexDataObject(complexFindModel('Company'), companyId);
    await dataWrapperInstance.saveComplexDataObject(complexCompany);
};

function FirstMessageDetails({
    containerBackgroundColor,
    handleProjectSelectorChange,
    selectedProjectId,
    handleSubjectChange,
    subject,
}) {
    const classes = useStyles({ containerBackgroundColor });
    const filterProjectSelector = job => job.data.status !== STATUS.ID.JOB_CLOSED;

    return (
        <Box
            p={4}
            className={classes.titleBox}
        >
            <ProjectSelector
                selectedProjectId={selectedProjectId}
                filter={filterProjectSelector}
                onChange={handleProjectSelectorChange}
            />
            <Box
                className={classes.title}
            >
                <TextField
                    fullWidth
                    label="Subject"
                    value={subject}
                    onChange={handleSubjectChange}
                />
            </Box>
        </Box>
    );
}

export default function SendMessage(props: SendMessageProps) {
    const {
        containerBackgroundColor,
        parentCustomerMessage,
        onSent,
        onSend,
        onError,
    } = props;
    
    const authData = useRecoilValue(authState);
    const { success, error } = useToast();
    const { data: { companyId, contactId } } = authData;

    const navigate = useNavigate();

    const dataWrapperInstance = useRecoilValue(dataWrapper);

    const isReply = Boolean(parentCustomerMessage);

    const classes = useStyles({ containerBackgroundColor });
    const [subject, setSubject] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [sendingInProgress, setSendingInProgress] = useState<boolean>(false);

    const [job, setJob] = useState<ComplexModelInstance<'Job'>>(new (complexFindModel('Job'))({}));
    const [customerMessage, setCustomerMessage] = useState<ComplexModelInstance<'CustomerMessage'>>(new (complexFindModel('CustomerMessage'))({}));
    const [messageRecipient, setMessageRecipient] = useState<ComplexModelInstance<'MessageRecipient'>>(new (complexFindModel('MessageRecipient'))({}));

    const hasSendMessageRole = useRecoilValue(hasRole(ROLES.PERMISSIONS.CAN_SEND_MESSAGE_FROM_PORTAL));
    const responsive = useResponsive();

    const [selectedProjectId, setSelectedProjectId] = useState<number>(parentCustomerMessage?.data?.job_id ?? -1);

    if (!companyId || !contactId) {
        return null;
    }

    if (messageRecipient) {
        messageRecipient.data.subject = subject;
        messageRecipient.data.message = messageBody;
    }

    const handleProjectSelectorChange = value => {
        setSelectedProjectId(value);
    };

    const sendHandler = async () => {
        setSendingInProgress(true);
        if (onSend) {
            await onSend();
        }
        try {
            const selectedJob = parentCustomerMessage && isReply ? await parentCustomerMessage?.getJob() : await getJobRecord({
                dataWrapperInstance,
                selectedProjectId,
                companyId,
                contactId,
                job,
            });
            if (selectedJob !== job) {
                setJob(selectedJob);
            }

            await createMessageRecords({
                dataWrapperInstance,
                job: selectedJob,
                companyId,
                contactId,
                parentCustomerMessage,
                customerMessage,
                messageRecipient,
            });

            await sendMessage({
                dataWrapperInstance, companyId,
            });

            setJob(new (complexFindModel('Job'))({}));
            setCustomerMessage(new (complexFindModel('CustomerMessage'))({}));
            setMessageRecipient(new (complexFindModel('MessageRecipient'))({}));
            if (onSent) {
                await onSent();
            }

            if (!selectedJob || !isReply) {
                navigate(`/portal/${authData.portalId}/message/${customerMessage?.data.id}/view`);
            }
            success('Thank you, your message has been sent');
            setMessageBody('');
            setSubject('');
        } catch (err) {
            if (err instanceof ParentDeletedError) {
                error('The project of this message has been deleted. Therefore your message could not be sent');
                navigator.serviceWorker?.controller?.postMessage({
                    type: 'invalidateCache',
                    data: { tableName: 'Job', companyId },
                });
                setJob(new (complexFindModel('Job'))({}));
                setCustomerMessage(new (complexFindModel('CustomerMessage'))({}));
                setMessageRecipient(new (complexFindModel('MessageRecipient'))({}));
                dataWrapperInstance.reload();
                if (onError) {
                    await onError(err);
                }
            } else {
                throw err;
            }
        } finally {
            setSendingInProgress(false);
        }
    };

    const handleSubjectChange = e => setSubject(e.target.value);
    return (
        <Box>
            {!isReply && (
                <FirstMessageDetails
                    containerBackgroundColor={containerBackgroundColor}
                    handleProjectSelectorChange={handleProjectSelectorChange}
                    selectedProjectId={selectedProjectId}
                    handleSubjectChange={handleSubjectChange}
                    subject={subject}
                />
            )}
            <Box p={4} className={classes.contentBox} flexDirection="column">
                <TextField
                    variant="filled"
                    multiline
                    rows={3}
                    fullWidth
                    label={isReply ? null : 'Message'}
                    value={messageBody}
                    onChange={e => setMessageBody(e.target.value)}
                />
                <Box display="flex" justifyContent="flex-end">
                    <Mask
                        show={sendingInProgress}
                        content={<CircularProgress color="primary" size="large" />}
                        overflow="0"
                        blur={3}
                        alignItems="center"
                        flexDirection="column"
                        style={{
                            borderRadius: 3,
                        }}
                    >
                        <ErrorBoundaryWithTryAgainModal>
                            <Button
                                disabled={messageBody.trim() === '' || !hasSendMessageRole}
                                onClick={sendHandler}
                                endIcon={<SendIcon />}
                                mt={responsive({ xs: 4, sm: 2 })}
                            >
                                Send
                            </Button>
                        </ErrorBoundaryWithTryAgainModal>
                    </Mask>
                </Box>
            </Box>
        </Box>
    );
}
