import React from 'react';
import { useRecoilValue } from 'recoil';
import useComplexData from '@data/hooks/complexDataHook';
import filesListColumns from '@features/file/list/filesListColumnConfig';
import DataGridTabList, { ComplexTabListItems, TabListItemsParams } from '@features/dataGridTabList/DataGridTabList';
import { ComplexModelEntity } from '@powerednow/models/modelLookup';
import { displayProjectsOnPortal } from '@data/state/companySettings';

type FileDataType = ComplexModelEntity<'FilesForCustomer'>;

const useFiles = (params: TabListItemsParams<ComplexModelEntity<'File'>>): ComplexTabListItems<ComplexModelEntity<'File'>> => {
    const {
        contactId,
        pageSize,
        skip,
        sorters,
    } = params;
    const {
        optionalResult,
        error,
    } = useComplexData(
        'Contact',
        [contactId, pageSize, skip, sorters],
        async (complexContact, resultSetter: (_newResult:{ total: number, loaded: boolean, listItems: FileDataType[] })=>void) => {
            const complexCustomer = await complexContact?.getCustomer();

            const total = await complexCustomer?.getFilesForCustomerCount();
            const complexFiles = await complexCustomer?.getAllFilesForCustomer({
                skip,
                limit: pageSize,
                sorters,
            });
            resultSetter({
                total,
                loaded: true,
                listItems: complexFiles.map(file => file.data.getPureDataValues()) as FileDataType[],
            });
        },
    );

    return {
        listItems: optionalResult?.listItems || [],
        loaded: optionalResult?.loaded || false,
        total: optionalResult?.total || 0,
        error,
    };
};

function FilesList() {
    const noRowsText = 'Sorry, there are no files to display';
    const rowHeight = 80;
    const height = 510;
    const sortModel = [
        { field: 'dt_created', sort: 'desc' } as const,
    ];
    const displayProjects = useRecoilValue(displayProjectsOnPortal);
    const columns = filesListColumns(displayProjects);

    return (
        <DataGridTabList
            columns={columns}
            useGetListItems={useFiles}
            sortModel={sortModel}
            rowHeight={rowHeight}
            height={height}
            noRowsText={noRowsText}
            tabToNavigate="file"
        />
    );
}

export const MemoizeList = React.memo(FilesList);
export default MemoizeList;
