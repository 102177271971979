import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import useComplexData from '@data/hooks/complexDataHook';

const useStyles = makeStyles<Theme>(theme => createStyles({
    root: {
        display: 'flex',
        height: 'fit-content',
        maxWidth: '200px',
        maxHeight: '60px',
        borderRadius: '4px',
        [theme.breakpoints.up('xs')]: {
            width: '80px',
        },
        [theme.breakpoints.up('sm')]: {
            width: 'unset',
        },
    },
}));

interface CompanyLogoProps {
    companyId: number,
}

const useCompanyLogo = companyId => {
    const { optionalResult } = useComplexData(
        'Company',
        companyId,
        async (complexCompany, resultSetter: (_newResult: { logoUrl: string })=>void) => {
            const complexLogo = await complexCompany.getCompanyLogo();
            resultSetter({ logoUrl: complexLogo?.data?.getPureDataValues().url });
        },
    );
    return optionalResult?.logoUrl;
};

function CompanyLogo(props: CompanyLogoProps) {
    const { companyId } = props;
    const logoUrl = useCompanyLogo(companyId);
    const classes = useStyles();
    if (!logoUrl) {
        return null;
    }

    return (
        <img
            alt="logo"
            className={classes.root}
            src={logoUrl}
        />
    );
}

export default CompanyLogo;
