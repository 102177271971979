import React from 'react';
import PageBlock from '@components/layout/PageBlock';
import SendMessage from '@features/message/view/sendMessage';
import { useTheme } from '@mui/material/styles';
import useSetFAQS from '@data/hooks/useSetFAQS';

export default function ComposeMessage() {
    const theme = useTheme();
    const containerBackgroundColor = theme.palette.neutral.main;
    useSetFAQS(['findDocument', 'contactYou']);

    return (
        <PageBlock>
            <SendMessage containerBackgroundColor={containerBackgroundColor} />
        </PageBlock>
    );
}
