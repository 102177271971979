import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MUIMenu from '@mui/material/Menu';
import { omit } from 'lodash';
import { useCustomEventListener } from 'react-custom-events';
import Button from '../button/Button';
const useStyles = makeStyles(theme => createStyles({
    accountButton: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'initial',
        [theme.breakpoints.up('xs')]: {
            height: '50.25px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '56px',
        },
    },
}));
const StyledMenu = withStyles(theme => ({
    paper: {
        border: `1px solid ${theme.palette.action.active}`,
        marginTop: 4,
        '&>ul': {
            paddingBottom: '0',
            paddingTop: '0',
        },
    },
}))((props) => (_jsx(MUIMenu, Object.assign({ elevation: 0, anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
    }, transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
    } }, props))));
const MenuButton = React.forwardRef((props, ref) => {
    const text = props.text || 'Open Menu';
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useCustomEventListener('closeMenu', handleClose);
    return (_jsxs("div", { children: [_jsx(Button, Object.assign({}, omit(props, ['text']), { onClick: handleClick, className: classes.accountButton, children: text })), _jsx(StyledMenu, { ref: ref, id: "customized-menu", anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: handleClose, children: props.children })] }));
});
export default MenuButton;
