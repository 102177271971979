import { jsx as _jsx } from "react/jsx-runtime";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ReactHtmlParser from 'react-html-parser';
import LabelledBox from '../LabelledBox/LabelledBox';
const useStyles = makeStyles(createStyles({
    itemDetailSection: {
        maxHeight: '45vh',
        overflowX: 'auto',
        wordBreak: 'break-word',
        '& > p:first-child, .details-title': {
            marginTop: '0',
            marginBottom: '0',
            overflowWrap: 'break-word',
            hyphens: 'auto',
        },
    },
}));
export default function ItemDetailSection(detailSectionData) {
    const { title, text, display = 'flex', hidden = false, style = { alignItems: 'center' }, } = detailSectionData;
    const classes = useStyles();
    if (hidden)
        return null;
    const shouldParseText = typeof text === 'string' ? ReactHtmlParser(text) : text;
    return (_jsx(LabelledBox, { title: title, component: "div", className: classes.itemDetailSection, display: display, style: style, labelPosition: "left", fontWeight: "bold", children: shouldParseText }));
}
