import React, { useMemo } from 'react';
import Badge from '@components/Badge/Badge';
import { Columns } from '@components/dataGrid/DataGrid';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import {
    isDateInFuture,
    parse,
    patterns,
    format,
    convertToLocal,
    isValidDate,
} from '@powerednow/shared/modules/utilities/date';
import { ColorTypes } from '@components/button/ColoredButton';
import { capitalize } from 'lodash';
import { useProjectDescription } from '@powerednow/portal/src/helper/tabList';

const useStyles = makeStyles<Theme>(createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        flex: 1,
        lineHeight: '35px',
    },
    spanFont: {
        fontSize: 'x-small',
    },
}));

function DateCell({ row }) {
    const date = parse(row.dt_created, patterns.messageDateTime);
    const finalDate = convertToLocal(date);

    return (
        <span>
            {format(finalDate, patterns.shortDateOnly)}
        </span>
    );
}

function projectCell({ row }) {
    return (
        <Typography variant="inherit" noWrap>
            {useProjectDescription(row.job_id)}
        </Typography>
    );
}

function StatusCell({ row }) {
    const classes = useStyles();
    let badgeContent = '';
    let badgeColor:ColorTypes | undefined = 'neutral';
    const badgeChildContent = row.display_number;

    if (row.form_data.form) {
        const { reminderDate, nextServiceDate } = row.form_data.form;
        if (isValidDate(parse(nextServiceDate, 'DD-MM-Y'))) {
            const renewalDate = format(new Date(nextServiceDate).toISOString(), 'Y-MM-DD');
            if (isDateInFuture(renewalDate) && !isDateInFuture(reminderDate)) {
                badgeContent = 'Due for renewal';
                badgeColor = 'warning';
            } else if (!isDateInFuture(renewalDate)) {
                badgeContent = 'Expired';
                badgeColor = 'error';
            } else if (isDateInFuture(renewalDate)) {
                badgeContent = 'Active';
                badgeColor = 'secondary';
            }
        } else {
            badgeContent = 'Active';
            badgeColor = 'secondary';
        }
    }

    return (
        <Box className={classes.root}>
            <Box>
                <Badge
                    size="small"
                    content={capitalize(badgeContent)}
                    color={badgeColor}
                >
                    {badgeChildContent}
                </Badge>
            </Box>
        </Box>
    );
}

export default function formListColumnConfig(displayProjects: boolean): Columns {
    return useMemo(() => {
        const columns: Columns = [{
            field: 'dt_created',
            headerName: 'Date',
            minWidth: 60,
            renderCell: DateCell,
        }, {
            field: 'display_number',
            headerName: 'Details',
            headerAlign: 'left',
            minWidth: 250,
            renderCell: StatusCell,
        }];
        if (displayProjects) {
            columns.push({
                field: 'project',
                headerName: 'Project',
                flex: 1,
                minWidth: 400,
                sortable: false,
                renderCell: projectCell,
            });
        }
        return columns;
    }, [displayProjects]);
}
