import BaseValidator from './baseValidator';

export default class LengthValidator extends BaseValidator {
    protected config: {

        message: string;
    };

    constructor(config = {}) {
        Object.assign(config, {
            message: 'notCorrectLength',
        });
        super(config);
    }

    async runDefaultRule(value, options:{ min?: number; max?: number; allowEmpty?: boolean;} = {}) {
        const stringValue = typeof value === 'number' ? String(value) : value;
        const trimmedValue = (stringValue || '').trim();
        const trimmedValueLength = trimmedValue.length;

        if (trimmedValue === '' && options.allowEmpty) {
            return true;
        }

        if (options.min && options.max && (trimmedValueLength < options.min || trimmedValueLength > options.max)) {
            if (options.min === options.max) {
                this.detailedValidationMessage = `(Requires exactly ${options.min} characters)`;
            } else {
                this.detailedValidationMessage = `(At least ${options.min}, and max ${options.max} characters)`;
            }

            return this.config.message;
        } if (options.min && trimmedValueLength < options.min) {
            this.detailedValidationMessage = `(At least ${options.min} characters)`;
            return this.config.message;
        } if (options.max && trimmedValueLength > options.max) {
            this.detailedValidationMessage = `(Max ${options.max} characters)`;
            return this.config.message;
        }

        return true;
    }

    getDetailedValidationMessage() {
        return this.detailedValidationMessage;
    }
}
