import React from 'react';

import htmlDecode from '@components/helper/htmlDecode';

import { authState } from '@data/state/auth';
import { useRecoilValue } from 'recoil';
import {
    AppointmentTypeResult, AppointmentTypeSettingsResult,
    useAppointmentType,
    useAppointmentTypeSettings,
} from '@data/hooks/useAppointmentTypes';
import { LinearProgress } from '@components/progress/Progress';
import { ComplexModelEntity } from '@powerednow/models/modelLookup';

export function getPriceText(price, formattedPrice, appointmentTypeSettings) {
    const { displayPrice, VATPostFix, vatRegistered } = appointmentTypeSettings.data;
    const addPrice = displayPrice && price > 0;
    return addPrice ? `${formattedPrice}${vatRegistered ? ` ${VATPostFix}` : ''}` : '';
}

const getAppointmentTypeDataWithPriceText = (
    appointmentType: AppointmentTypeResult,
    appointmentTypeSettings: AppointmentTypeSettingsResult,
): Partial<ComplexModelEntity<'AppointmentType'>> & {
    priceText?: string
} | null => {
    const values = {};

    if (appointmentType.loaded && appointmentType.data) {
        Object.assign(values, { ...appointmentType.data.appointmentType });

        if (appointmentTypeSettings.loaded && appointmentTypeSettings.data) {
            const { price } = appointmentType.data.appointmentType;
            const { formattedPrice } = appointmentType.data;
            const priceText = getPriceText(price, formattedPrice, appointmentTypeSettings);

            Object.assign(values, { priceText });
        } 
        return values;
    }
    return null;
};

function getValues(appointmentTypeId) {
    const authData = useRecoilValue(authState);
    const { data: { companyId } } = authData;

    const appointmentType = useAppointmentType(appointmentTypeId);
    const appointmentTypeSettings = useAppointmentTypeSettings(companyId);

    return getAppointmentTypeDataWithPriceText(appointmentType, appointmentTypeSettings);
}

type AppointmentTypeFieldProps = {
    field: keyof ComplexModelEntity<'AppointmentType'> | 'priceText',
    appointmentTypeId: number;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
}

export function AppointmentTypeField({
    appointmentTypeId,
    prefix = '',
    suffix = '',
    field = 'name',
}: AppointmentTypeFieldProps) {
    const appointmentData = getValues(appointmentTypeId);

    if (!appointmentData) {
        return <LinearProgress />;
    }

    const value = appointmentData[field];

    if (!value) {
        return null;
    }

    return (
        <span>
            {prefix}
            {htmlDecode(value)}
            {suffix}
        </span>
    );
}
