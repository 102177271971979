import React, { useState } from 'react';
import { useResponsive } from '@data/hooks/responsive';
import PageBlock from '@components/layout/PageBlock';
import { useParams } from 'react-router-dom';
import { useToast } from '@components/toast/toast';
import { ifInstanceOf } from '@powerednow/shared/modules/typedCatch';
import { NetworkError, ServiceError } from '@powerednow/errors';
import SetNewPassword from '@features/setNewPassword/SetNewPassword';
import textUtils from '@powerednow/shared/modules/utilities/textUtils';
import UnAuthorizedPage from '@pages/UnauthorizedPage/UnAuthorizedPage';
import { getApiRequest } from '@app/connection/apiRequest';

const pageBlockTitle = 'This link has already been used for password reset';
const successfulPasswordChangeMessage = 'New password is successfully changed';
const serviceErrorMessage = 'User not found. The link is invalid or broken.';
const networkErrorMessage = 'Your network seems to be down or Amazon did blow up and our servers are in the heaven not in the cloud anymore.';

export default function ForgottenPassword() {
    const responsive = useResponsive();
    const { passwordReminderHash } = useParams<'passwordReminderHash'>();
    const { redirectUrl } = useParams<'redirectUrl'>();
    const { success, error } = useToast();
    const [password, setPassword] = useState({
        password: '',
        confirmPassword: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    if (!passwordReminderHash) {
        return (
            <PageBlock
                display="flex"
                flexDirection="column"
                neutral
                title={pageBlockTitle}
                titleAlign="center"
                minWidth={responsive({ xs: 300, sm: 400 })}
                position="relative"
                p={12}
            />
        );
    }

    const handleNewPassword = async () => {
        const decodedUrl = decodeURIComponent(redirectUrl!);
        const hashedPassword = textUtils.hash(password.password);
        const apiRequest = getApiRequest();
        setIsLoading(true);
        try {
            await apiRequest.setPasswordByReminderHash(hashedPassword, passwordReminderHash);
            window.location.assign(decodedUrl);
            success(successfulPasswordChangeMessage);
        } catch (err) {
            if (err instanceof ServiceError) {
                error(serviceErrorMessage);
            } else if (err instanceof NetworkError) {
                error(networkErrorMessage);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = event => {
        const { name, value } = event.target;
        setPassword(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <UnAuthorizedPage title="Set new password">
            <SetNewPassword
                onSetNewPasswordButtonClicked={handleNewPassword}
                onPasswordFieldChange={handleChange}
                password={password.password}
                confirmPassword={password.confirmPassword}
                showCompanyLogo
                isLoading={isLoading}
            />
        </UnAuthorizedPage>
    );
}
