import {
    useState, useRef, Dispatch, SetStateAction,
} from 'react';
import { isEqual } from 'lodash';
import type { GridFilterModel } from '@mui/x-data-grid';

type FilterModelComparatorFunction = (modelA?: GridFilterModel, modelB?: GridFilterModel) => boolean;

export default function useFilter<T extends GridFilterModel>(filterModel?: T, comparator?: FilterModelComparatorFunction): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
    const [localSortModel, setLocalFilterModel] = useState(filterModel);
    const sortRef = useRef(localSortModel);
    const compareFn = comparator || isEqual;
    if (!compareFn(sortRef.current, localSortModel)) {
        sortRef.current = localSortModel;
    }
    return [sortRef.current, setLocalFilterModel];
}
