import chroma from 'chroma-js';
const getShadedColors = (color, light = null) => ({
    main: color,
    light: light || chroma.scale(['white', color])(0.1).hex(),
    dark: chroma(color).darken(1).hex(),
    contrastText: chroma.scale(['black', color])(0.25).hex(),
});
const getDarkShadedColors = (color, dark = null) => ({
    main: color,
    dark: dark || chroma.scale(['white', color])(0.8).hex(),
    light: chroma(color).darken(-2).hex(),
    contrastText: chroma.scale(['white', color])(0.25).hex(),
});
export { getDarkShadedColors, getShadedColors, };
