import { __awaiter, __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useErrorHandler } from 'react-error-boundary';
import clsx from 'clsx';
const useStyles = makeStyles(createStyles({
    base: {
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
    },
}));
function ColoredButton(_a) {
    var { children, color, onClick } = _a, props = __rest(_a, ["children", "color", "onClick"]);
    const handleError = useErrorHandler();
    const classes = useStyles();
    const sizeClass = classes === null || classes === void 0 ? void 0 : classes[props.size || 'medium'];
    const className = clsx([classes.base, sizeClass, props.className]);
    const colorProp = ['inherit', 'primary', 'secondary', 'warning', 'error'].indexOf(color) > -1
        ? color
        : undefined;
    const handledOnClick = (event) => __awaiter(this, void 0, void 0, function* () {
        try {
            if (onClick) {
                yield onClick(event);
            }
        }
        catch (err) {
            handleError(err);
        }
    });
    return (_jsx(Button, Object.assign({}, props, { color: colorProp, className: className, onClick: handledOnClick, children: children })));
}
ColoredButton.displayName = 'ColoredButton';
export default ColoredButton;
