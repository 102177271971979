export const portalTokenUrlPlaceHolder = 'PORTAL_TOKEN_URL_PLACEHOLDER';

export default {
    ATTACHMENT_LIMIT: 20971520, // bytes =  20MB
    GLOBAL_DAILY_EMAIL_LIMIT: 10000,
    MIN_VERSION_ACCOUNT_VERIFICATION: '10.0.0',
    STATUS: {
        PREPARED: 0,
        SENT_TO_PROVIDER: 1,
    },
    DELIVERY_STATUS: {
        process_failed: -1,
        send: 1,
        deferral: 2,
        hard_bounce: 3,
        soft_bounce: 4,
        open: 5,
        click: 6,
        spam: 7,
        unsub: 8,
        reject: 9,
        // SENDGRID
        delivered: 1,
        processed: 0,
        deferred: 2,
        bounce: 3,
        report_span: 7,
        unsubscribe: 8,
        dropped: 9,
        // Twilio
        sent: 1,
        read: 5,
        failed: 3,
    } as const,
    TYPES: {
        PUSH: 0,
        SMS: 1,
        EMAIL: 2,
        ELECTRONIC: 3,
        WORK: 4,
        HOME: 5,
        EMAIL_CC: 6,
        WHATSAPP: 7,
        PORTAL: 8,
    } as const,

    CUSTOMER_MESSAGE_LINK_TYPES: {
        DOCUMENT: 0,
        APPOINTMENT: 1,
        FORMDOCUMENT: 2,
        PAYMENT: 3,
        MESSAGE: 4,
    },

    MESSAGE_DIRECTION: {
        INCOMING: 0,
        OUTGOING: 1,
    },

    MESSAGE_TYPES: {
        DOCUMENT: {
            ID: 'doc',
            TYPESTRING_PROMPT: 'Message.TypeStringDocument',
            TYPESTRING_TEXT: 'document',
            PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc',
            PROMPT_TEXT: 'Good News!<br/><br/>Looks like the %s you sent with display number <b>%s</b> to '
            + '<b>%s</b> from the project named <i>- %s -</i> has just been opened. You don\'t need to do anything, but '
            + 'we thought it would be nice to let you know.<br/><br/>Happy Invoicing!<br/><br/>–<br/>Need help with '
            + 'Powered Now? Simply reply to this mail!',
            SEND_OPEN_NOTIFICATION: true,
        },
        FORMDOCUMENT: {
            ID: 'formDocument',
            TYPESTRING_PROMPT: null,
            TYPESTRING_TEXT: null,
            PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc',
            PROMPT_TEXT: 'Good News!<br/><br/>Looks like the %s you sent with display number <b>%s</b> to '
            + '<b>%s</b> from the project named <i>- %s -</i> has just been opened. You don\'t need to do anything, but '
            + 'we thought it would be nice to let you know.<br/><br/>Happy Invoicing!<br/><br/>–<br/>Need help with '
            + 'Powered Now? Simply reply to this mail!',
            SEND_OPEN_NOTIFICATION: true,
        },
        PAYMENT: {
            ID: 'payment',
            TYPESTRING_PROMPT: 'Message.TypeStringPayment',
            TYPESTRING_TEXT: 'payment',
            PROMPT_ID: 'ServerNotification.EmailOpenedMsgPayment',
            PROMPT_TEXT: 'Good News!<br/><br/>Looks like the %s you sent with display number <b>%s</b> '
            + 'to <b>%s</b> has just been opened. You don\'t need to do anything, but we thought it would be nice to let you know.'
            + '<br/><br/>Happy Invoicing!<br/><br/>–<br/>Need help with Powered Now? Simply reply to this mail!',
            SEND_OPEN_NOTIFICATION: true,
        },
        APPOINTMENT: {
            ID: 'appointment',
            TYPESTRING_PROMPT: 'Message.TypeStringAppointment',
            TYPESTRING_TEXT: 'appointment',
            PROMPT_ID: 'ServerNotification.EmailOpenedMsgAppointment',
            PROMPT_TEXT: 'Good News!<br/><br/>Looks like the %s you sent to '
            + '<b>%s</b> from the project named <i>- %s -</i> about an appointment scheduled with you from <b>%s</b> with the title '
            + '<i>- %s -</i> has just been opened. You don\'t need to do anything, but '
            + 'we thought it would be nice to let you know.<br/><br/>Happy Invoicing!<br/><br/>–<br/>Need help with '
            + 'Powered Now? Simply reply to this mail!',
            SEND_OPEN_NOTIFICATION: true,
        },
        NOTIFY_ASSIGNEE: {
            ID: 'notify_assignee',
            TYPESTRING_PROMPT: 'Message.TypeStringAppointmentAssignee',
            TYPESTRING_TEXT: 'notification',
            PROMPT_ID: 'ServerNotification.EmailOpenedMsgAppointmentAssignee',
            PROMPT_TEXT: 'Good News!<br/><br/>Looks like the %s you sent to '
            + '<b>%s</b> about an appointment scheduled with the customer from <b>%s</b> related to the project named <i>- %s -</i>  has just been opened. You don\'t need to do anything, but '
            + 'we thought it would be nice to let you know.<br/><br/>Happy Invoicing!<br/><br/>–<br/>Need help with '
            + 'Powered Now? Simply reply to this mail!',
            SEND_OPEN_NOTIFICATION: true,
        },
        MESSAGE: {
            ID: 'message',
            TYPESTRING_PROMPT: 'Message.TypeStringMessage',
            TYPESTRING_TEXT: 'message',
            PROMPT_ID: 'ServerNotification.EmailOpenedMsgMessage',
            PROMPT_TEXT: 'Good News!<br/><br/>Looks like the %s you sent to '
            + '<b>%s</b> from the project named <i>- %s -</i> has just been opened. You don\'t need to do anything, but '
            + 'we thought it would be nice to let you know.<br/><br/>Happy Invoicing!<br/><br/>–<br/>Need help with '
            + 'Powered Now? Simply reply to this mail!',
            SEND_OPEN_NOTIFICATION: true,
        },
        REPORT: {
            ID: 'report',
            TYPESTRING_PROMPT: 'Message.TypeStringReport',
            TYPESTRING_TEXT: 'report',
            PROMPT_ID: 'ServerNotification.EmailOpenedMsgReport',
            PROMPT_TEXT: 'Good News!<br/><br/>Looks like the %s you sent to '
            + '<b>%s</b> has just been opened. You don\'t need to do anything, but '
            + 'we thought it would be nice to let you know.<br/><br/>Happy Invoicing!<br/><br/>–<br/>Need help with '
            + 'Powered Now? Simply reply to this mail!',
            SEND_OPEN_NOTIFICATION: true,
        },
        NOTIFY_DOCUMENT_APPROVE: {
            ID: 'notify_documentApprove',
            TYPESTRING_PROMPT: null,
            TYPESTRING_TEXT: null,
            PROMPT_ID: null,
            PROMPT_TEXT: null,
            SEND_OPEN_NOTIFICATION: false,
        },
    },

    RECIPIENT_TYPES: {
        CONTACT: 1,
        TEAM_MEMBER: 2,
    },
    
    ERROR_CODES: {
        LIMIT_REACHED: 42,  
    },

    MAX_IMAGE_ATTACHMENT_SIZE: 10485760, // 10 MB
    MAX_SUM_ATTACHMENT_SIZE: 10485760,
    MAX_FILE_ATTACHMENT_SIZE: 31457280, // 30 MB
    PORTAL_TOKEN_URL: portalTokenUrlPlaceHolder,
};
