import * as _ from 'lodash';
import Path from '@powerednow/shared/modules/form/Path';

let context;
// @ts-ignore
if (!process.env.PRODUCTION) {
    // eslint-disable-next-line global-require
    context = require('@powerednow/shared/context');
}

export default class FormManager {
    private _formJSON;

    constructor(formJSON) {
        this.setFormJSON(formJSON);
    }

    private setFormJSON(formJSON): void {
        this._formJSON = formJSON;

        if (_.isString(formJSON.form_template)) {
            /* eslint-disable no-eval */
            (this._formJSON.formTemplate as any) = eval(formJSON.form_template);
            /* eslint-enable no-eval */

            if (context) {
                this._formJSON.formTemplate = context.requireFormTemplate(formJSON.name, formJSON.version);
            }
        }
    }

    public getFormJSON() {
        return this._formJSON;
    }

    public getPage(pageId: string | number = 'mainPage'): any {
        return this.getFormTemplate().pages[pageId];
    }

    public getPages(): any {
        return this.getFormTemplate().pages;
    }

    public getFormTemplate() {
        return this.getFormJSON().formTemplate;
    }

    public getSettings(): Record<string, any> {
        return this.getFormTemplate().settings;
    }

    public getViewModelTemplate(defaultDataPath?: string | Path) {
        const { viewModelTemplate } = this.getFormTemplate();
        if (defaultDataPath) {
            viewModelTemplate.defaultDataPath = defaultDataPath;
        }
        return viewModelTemplate;
    }

    public getCommonProperties(): object {
        return this.getFormTemplate().commonProperties;
    }
}
