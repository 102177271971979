import React from 'react';
import { Box, Typography } from '@mui/material';
import { useResponsive } from '@data/hooks/responsive';
import TipBox from '@components/layout/TipBox';
import { AccessTime } from '@mui/icons-material';
import PageBlock from '@components/layout/PageBlock';
import { useRecoilValue } from 'recoil';
import { authState } from '@data/state/auth';
import useComplexData from '@data/hooks/complexDataHook';
import htmlDecode from '@components/helper/htmlDecode';

const useCompanyDetails = companyId => {
    const { details, optionalResult } = useComplexData(
        'Company',
        companyId,
        async (complexCompany, resultSetter: (_newResult:{ logo: string })=>void) => {
            const complexLogo = await complexCompany.getCompanyLogo();
            resultSetter({ logo: complexLogo?.data?.getPureDataValues().url });
        },
    );
    return {
        details,
        companyLogo: optionalResult?.logo,
    };
};

export default function WelcomeBlock() {
    const responsive = useResponsive();
    const authData = useRecoilValue(authState);
    const { data: { companyId } } = authData;
    const { details: companyDetails } = useCompanyDetails(companyId);
    return (
        <PageBlock neutral title={`Welcome to your ${companyDetails?.name} account`}>
            <Box display="flex" flexDirection={responsive({ xs: 'column', md: 'row' })}>
                <Box flex={1} pr={responsive({ sm: 0, md: 20 })}>
                    <Typography>
                        <br />
                        The&nbsp;
                        {htmlDecode(companyDetails?.name)}
                        
                        &nbsp;portal is a place for you to access your account with us.
                        <br />
                        <br />
                        You can send us a message, book an appointment and access all of your document including invoices, quotes and much more.
                        <br />
                        <br />
                        For more details please see the frequently asked questions below.
                        <br />
                        <br />
                    </Typography>
                </Box>
                <TipBox
                    flex={0}
                    minWidth={responsive({ xs: '250px', md: '400px', lg: '500px' })}
                    icon={<AccessTime />}
                    title="How to create an appointment"
                    content="View all of your appointments from a single place. You can also see who the engineer was that came to visit you and the date and time."
                />
            </Box>
        </PageBlock>
    );
}
