import React, { useState } from 'react';
import PageBlock from '@components/layout/PageBlock';
import Mask from '@components/mask/Mask';
import { CircularProgress } from '@components/progress/Progress';
import SetNewPassword from '@features/setNewPassword/SetNewPassword';
import { Box, Typography } from '@mui/material';
import { useToast } from '@components/toast/toast';
import { useRecoilValue } from 'recoil';
import useComplexData from '@data/hooks/complexDataHook';
import { useNavigate } from 'react-router-dom';
import { authState, dataWrapper } from '@data/state/auth';
import { ifInstanceOf } from '@powerednow/shared/modules/typedCatch';
import { NetworkError, ServiceError } from '@powerednow/errors';
import textUtils from '@powerednow/shared/modules/utilities/textUtils';
import { getApiRequest } from '@app/connection/apiRequest';
import { complexFindModel, ComplexModelEntity } from '@powerednow/models/modelLookup';

type ContactDetailsResult = {
    email: ComplexModelEntity<'ContactMethod'>,
}

const useContactDetails = (contactId: number | null) => {
    const { details, optionalResult } = useComplexData(
        'Contact',
        contactId,
        async (complexContact, resultSetter: (_newResult:ContactDetailsResult)=>void) => {
            const email = await complexContact.getEmail();
            resultSetter({
                email: email?.data.getPureDataValues(),
            });
        },
    );
    return {
        contactDetails: details,
        email: optionalResult?.email,
    };
};

const setUserToContact = async function (dataWrapperInstance, contactDetailsId, userToContact) {
    const contact = await dataWrapperInstance.getComplexDataObject(complexFindModel('Contact'), contactDetailsId);
    return contact.setUserToContact(userToContact);
};

export default function SetAccountPassword() {
    const authData = useRecoilValue(authState);
    const { success, error } = useToast();
    const dataWrapperInstance = useRecoilValue(dataWrapper);
    const navigate = useNavigate();
    const { contactDetails, email } = useContactDetails(authData?.data.contactId);
    const [saveInProgress, setSaveInProgress] = useState(false);

    const [password, setPassword] = useState({
        password: '',
        confirmPassword: '',
    });

    if (!contactDetails) {
        return <CircularProgress color="primary" />;
    }

    const handleNewPassword = async () => {
        if (!email?.value) {
            error('Email must be specified.');
            return;
        }
        setSaveInProgress(true);
        const hashedPassword = textUtils.hash(password.password);
        const apiRequest = getApiRequest();
        await apiRequest.register(authData.data, {
            password: hashedPassword,
            email: email.value,
            firstname: contactDetails.firstname,
            lastname: contactDetails.lastname || '',
        }).then(async (result: any) => {
            //
            // Make sure Contact knows the new user association
            //
            await setUserToContact(
                dataWrapperInstance,
                contactDetails.id,
                result.userToContact,
            );
            success('Your account has been created');
            navigate(`/portal/${authData.portalId}`);
        }).catch(ifInstanceOf(ServiceError, () => {
            setSaveInProgress(false);
            error('It looks like you already have an account with us.');
        })).catch(ifInstanceOf(NetworkError, () => {
            setSaveInProgress(false);
            error('Your network seems to be down or Amazon did blow up and our servers are in the heaven not in the cloud anymore.');
        }));
    };

    const handleChange = event => {
        const { name, value } = event.target;
        setPassword(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };
    return (
        <PageBlock alignItems="center" neutral title="Please set a password">
            <Mask show={saveInProgress} overflow="10px" position="relative" maxWidth={600} p={2} content={<CircularProgress />}>
                To secure your account, please set a password
                <br />
                <br />
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flex={1} flexDirection="column">
                        <Box>
                            <Typography variant="h5">
                                {email?.value}
                            </Typography>
                        </Box>
                    </Box>
                    <SetNewPassword
                        onSetNewPasswordButtonClicked={handleNewPassword}
                        onPasswordFieldChange={handleChange}
                        password={password.password}
                        confirmPassword={password.confirmPassword}
                        savePasswordButtonText="Create account"
                    />
                </Box>
            </Mask>
        </PageBlock>
    );
}
