import { ValueOf } from '@powerednow/type-definitions';

const httpHeaders = {
    HEADER_APP_VERSION: 'x-appversion',
    HEADER_DISPLAY_VERSION: 'x-display-version',
    HEADER_TRANSFORM_FUNCTION_NAME: 'x-transform-function-name',
    HEADER_TRANSFORM_RESPONSE_FUNCTION_NAME: 'x-transform-response-function-name',
    HEADER_DATA_COLLECT_FUNCTION_NAME: 'x-data-collect-function-name',
    HEADER_RETURN_VALUE_CONTENT_DISPOSITION: 'x-return-value-content-disposition',
    HEADER_RETURN_VALUE_CONTENT_TYPE: 'x-return-value-content-type',
    HEADER_RESPONSE_FORMATTER: 'x-response-formatter',
    HEADER_REQUEST_TMP_URL: 'x-request-tmp-url',
    HEADER_ACCEPT: 'Accept',
    HEADER_CONTENT_TYPE: 'Content-Type',
    AUTHENTICATION_HEADER_PASSWORD: 'x-auth-password',
    AUTHENTICATION_HEADER_TOKEN: 'x-auth-token',
    AUTHENTICATION_HEADER_JWT_TOKEN: 'x-auth-token-jwt',
    AUTHENTICATION_HEADER_USER: 'x-auth-user',
    AUTHENTICATION_HEADER_COMPANY: 'x-auth-company',
    AUTHENTICATION_HEADER_DEVICE: 'x-auth-device',
    AUTHENTICATION_HEADER_DOMAIN: 'x-auth-domain',
    AUTHENTICATION_HEADER_CUSTOMER: 'x-auth-customer',
    X_FORWARDED_FOR: 'x-forwarded-for',
    X_FORWARDED_PROTO: 'x-forwarded-proto',
    USER_AGENT: 'user-agent',
    NEED_COUNT: 'x-need-count',
    WHITE_LABEL_ID: 'x-white-label-id',
    PARTNER_ID: 'x-partner-id',
    CUSTOM_CRUD_HANDLER: 'x-custom-crud-handler',
    HOST: 'host',
} as const;

export default httpHeaders;
export type HttpHeadersType = ValueOf<typeof httpHeaders>;
