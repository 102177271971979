import { TokenDataValues } from '@powerednow/models/tokenData/entity';

const AUTH_STORAGE = 'auth';
const PORTAL_IDS = 'portalIds';

export type StoredTokenType = TokenDataValues

export default class TokenStorage {
    static getToken(userToken) : StoredTokenType | undefined {
        const storedData = TokenStorage.getAllTokens();
        return storedData?.[userToken];
    }

    static setToken(userToken: string, tokenData: StoredTokenType): void {
        const parsedData = TokenStorage.getAllTokens();
        parsedData[userToken] = tokenData;
        TokenStorage.setAllTokens(parsedData);
    }

    static getAllTokens(): Record<string, StoredTokenType> {
        const storedData = localStorage.getItem(AUTH_STORAGE);
        if (!storedData) {
            return {};
        }
        const parsedStoredData = JSON.parse(storedData);

        return this.getUniqueAuths(parsedStoredData);
    }

    static setAllTokens(data) {
        localStorage.setItem(AUTH_STORAGE, JSON.stringify(data));
    }

    static setPortalIds(data): void {
        localStorage.setItem(PORTAL_IDS, JSON.stringify(data));
    }

    static getPortalIds() {
        return JSON.parse(localStorage.getItem(PORTAL_IDS) || '[]');
    }

    static deleteToken(userToken: string): void {
        const parsedData = TokenStorage.getAllTokens();
        delete parsedData[userToken];
        TokenStorage.setAllTokens(parsedData);
    }

    static getUniqueAuths(tokens: Record<string, StoredTokenType>): Record<string, StoredTokenType> {
        const dupeCheck = {};
        return Object.entries(tokens).reduce((accumulator, [key, value]) => {
            const mapKey = `${value.companyId}_${value.customerId}_${value.contactId}`;
            if (!dupeCheck[mapKey]) {
                accumulator[key] = value;
            }
            dupeCheck[mapKey] = 1;
            return accumulator;
        }, {});
    }
}
