import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import TextsmsIcon from '@mui/icons-material/Textsms';
import clsx from 'clsx';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(theme => createStyles({
    icon: {
        '&:hover, &:focus': {
            color: theme.palette.primary.main,
        },
        margin: theme.spacing(2),
        marginLeft: 0,
    },
}));
export default function ChannelIndicator({ channels, onChannelIconClick = _c => { } }) {
    const classes = useStyles();
    const getChannelIcon = (channel, key) => ({
        EMAIL: _jsx(EmailIcon, { fontSize: "small", onClick: () => onChannelIconClick('EMAIL'), className: clsx(classes.icon) }, key),
        WHATSAPP: _jsx(WhatsAppIcon, { fontSize: "small", onClick: () => onChannelIconClick('WHATSAPP'), className: clsx(classes.icon) }, key),
        SMS: _jsx(TextsmsIcon, { fontSize: "small", onClick: () => onChannelIconClick('SMS'), className: clsx(classes.icon) }, key),
    }[channel]);
    return (_jsx(_Fragment, { children: channels.map((channel, index) => getChannelIcon(channel, index)) }));
}
