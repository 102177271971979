import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import Button from '@components/button/Button';
import { ArrowForwardIos as ArrowForward } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import PageBlock from '@components/layout/PageBlock';

const useStyles = makeStyles<Theme>(() => createStyles({
    customButton: {
        justifyContent: 'space-between',
        padding: '1.5rem 1.5rem',
        fontSize: '0.9rem',
    },
}));

export default function FAQ() {
    const classes = useStyles();

    return (
        <PageBlock titleAlign="center" title="Frequently asked questions">
            <Box pb={8}>
                <Typography variant="body2" align="center">Commonly asked questions about your account with us</Typography>
            </Box>

            <Container maxWidth="sm">
                <Button
                    className={classes.customButton}
                    buttonType="neutral"
                    endIcon={<ArrowForward />}
                    target="_blank"
                    href="https://support.powerednow.com/en/knowledge/portal-faq"
                    fullWidth
                >
                    Go to FAQs
                </Button> 
            </Container>
        </PageBlock>
    );
}
