import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, Box, } from '@mui/material';
import { useResponsive } from '../hooks/responsive';
export default function LabelledBox(props) {
    const responsive = useResponsive();
    const { title, children, hide, labelPosition = 'top', labelAlign = 'inherit' } = props, boxProps = __rest(props, ["title", "children", "hide", "labelPosition", "labelAlign"]);
    const alignment = { flexDirection: 'row' };
    if (labelPosition === 'top') {
        alignment.flexDirection = 'column';
    }
    else if (labelPosition === 'bottom') {
        alignment.flexDirection = 'column-reverse';
    }
    else if (labelPosition === 'left') {
        alignment.flexDirection = 'row';
    }
    else if (labelPosition === 'right') {
        alignment.flexDirection = 'row-reverse';
    }
    return (_jsxs(Box, { sx: {
            display: hide ? 'none' : 'flex',
            marginTop: responsive({ xs: 2, md: 6 }),
            flexDirection: alignment.flexDirection,
        }, children: [title && (_jsx(Typography, { align: labelAlign, variant: "h6", children: title })), _jsx(Box, Object.assign({ pl: title && responsive({ xs: 0, md: 10 }), flex: 1 }, boxProps, { children: children }))] }));
}
export function ResponsiveLabelledBox(_a) {
    var { title, styles, children, labelAlign, responsiveProps = { xs: 'top', sm: 'left' } } = _a, props = __rest(_a, ["title", "styles", "children", "labelAlign", "responsiveProps"]);
    const responsive = useResponsive();
    return (_jsx(LabelledBox, Object.assign({ title: title, labelPosition: responsive(responsiveProps), labelAlign: labelAlign, styles: styles }, props, { children: children })));
}
