import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { LinearProgress as MUILinearProgress, CircularProgress as MUICircularProgress, Box } from '@mui/material';
import clsx from 'clsx';
const useCircularStyles = makeStyles(theme => createStyles({
    primary: { color: theme.palette.primary.main },
    secondary: { color: theme.palette.secondary.main },
    warning: { color: theme.palette.warning.main },
    error: { color: theme.palette.error.main },
    neutral: { color: theme.palette.neutral.dark },
}));
const useLinearStyles = makeStyles(theme => createStyles({
    small: { height: 2 },
    medium: { height: 4 },
    large: { height: 7 },
    primary: {
        backgroundColor: theme.palette.primary.light,
        '& .MuiLinearProgress-bar': { backgroundColor: theme.palette.primary.main },
    },
    secondary: {
        backgroundColor: theme.palette.secondary.light,
        '& .MuiLinearProgress-bar': { backgroundColor: theme.palette.secondary.main },
    },
    warning: {
        backgroundColor: theme.palette.warning.light,
        '& .MuiLinearProgress-bar': { backgroundColor: theme.palette.warning.main },
    },
    error: {
        backgroundColor: theme.palette.error.light,
        '& .MuiLinearProgress-bar': { backgroundColor: theme.palette.error.main },
    },
    neutral: {
        backgroundColor: theme.palette.neutral.light,
        '& .MuiLinearProgress-bar': { backgroundColor: theme.palette.neutral.dark },
    },
}));
function LinearProgress(_a) {
    var { size, color } = _a, props = __rest(_a, ["size", "color"]);
    const linearClasses = useLinearStyles();
    return (_jsx(Box, { display: "flex", justifyContent: "center", alignItems: "center", p: 2, children: _jsx(MUILinearProgress, Object.assign({ className: clsx('MuiLinearProgress-bar', linearClasses === null || linearClasses === void 0 ? void 0 : linearClasses[color || 'neutral'], linearClasses === null || linearClasses === void 0 ? void 0 : linearClasses[size || 'medium']) }, props)) }));
}
function CircularProgress(_a) {
    var { size, color } = _a, props = __rest(_a, ["size", "color"]);
    const classes = useCircularStyles();
    const colorClass = classes === null || classes === void 0 ? void 0 : classes[color || 'neutral'];
    const sizeInPixels = {
        small: 25,
        medium: 30,
        large: 45,
    }[size || 'medium'];
    const thickness = {
        small: 6,
        medium: 5,
        large: 5,
    }[size || 'medium'];
    return (_jsx(Box, { display: "inline-flex", justifyContent: "center", alignItems: "center", p: 2, children: _jsx(MUICircularProgress, Object.assign({ thickness: thickness, className: clsx(colorClass), size: sizeInPixels }, props)) }));
}
export default function Progress(_a) {
    var { type } = _a, props = __rest(_a, ["type"]);
    if (type === 'linear') {
        return _jsx(LinearProgress, Object.assign({}, props));
    }
    return _jsx(CircularProgress, Object.assign({}, props));
}
export { CircularProgress, LinearProgress };
