/**
 * Created by jurikpeter on 2017. 05. 08..
 */
export default {
    /**
     * converts value to Number if wouldnt be a NaN
     * @param value
     * @returns {number}
     * @constructor
     */
    toNumberIfPossible(value: any): number {
        return Number.isNaN(Number(value)) ? value : Number(value);
    },

    stringToBoolean(value) {
        return value === 'true' || value === '1';
    },

    toFloat(string = '', forceNullOnValues = ['@']) {
        let retVal: string | number = string.toString();
        const lastComma = retVal.lastIndexOf(',');
        const lastPoint = retVal.lastIndexOf('.');
        const hasBoth = lastComma > -1 && lastPoint > -1;
        const isCommaDecimal = lastComma > lastPoint;
        const toDotDecimalDigitRegex = /[^\d+.\d+]/g;
        const isThousandSeparatorRegex = /^\d{1,3}(?:(?:([,.])\d{3})(?:\1\d{3})*)?$/;
        const removeThousandSeparatorRegex = /([.,])(?=\d{3})/g;
        let forcedNaN = false;

        //
        // has both kind fo sep, and for the decimal , is used
        //
        if (hasBoth && isCommaDecimal) {
            //
            // remove '.' thousand sep, and replace ',' decimal to '.'
            //
            retVal = retVal.replace(/\./g, '').replace(/,/g, '.');
        } else if (hasBoth && !isCommaDecimal) {
            //
            // comma is thousand separator here, do not needed
            //
            retVal = retVal.replace(/,/g, '');
            // } else if (!hasBoth && atLeastOneSeparator) {
        } else if (isThousandSeparatorRegex.test(string)) {
            //
            // in case of only one separator (thousand) found
            //

            // remove thousand separators
            retVal = retVal.replace(removeThousandSeparatorRegex, '');
        }
        //
        // replace comma decimal separator to dot
        //
        retVal = retVal.replace(',', '.');

        retVal.split('').forEach(char => {
            if (forceNullOnValues.includes(char)) {
                forcedNaN = true;
            }
        });

        retVal = forcedNaN ? NaN : parseFloat(retVal.replace(toDotDecimalDigitRegex, ''));
        return retVal;
    },

    removeCorruptedLineBreaks(value, asHtml = false) {
        let fixedValue = value;
        const replaceValue = asHtml ? '\\r\\n' : '\r\n';
        if (value && value.indexOf) {
            if (value.indexOf('\u007f') > -1 || value.indexOf('\u2028') > -1 || value.indexOf('\u2029') > -1) {
                fixedValue = value
                    .replace(/\u007f/g, replaceValue)
                    .replace(/\u2028/g, replaceValue)
                    .replace(/\u2029/g, replaceValue);
            }
        }
        return fixedValue;
    },

    convertValueToXls(value): number {
        if (value === '' || value === null) {
            return value;
        }

        // is in it letters
        if (/[A-Za-z]/.test(value)) {
            return value;
        }

        if (typeof value === 'string') {
            if (this._isFloat(value)) {
                return parseFloat(String(value));
            }
        }
        return value;
    },

    _isFloat(n) {
        if (n === '' || Array.isArray(n)) {
            return false;
        }
        return /^-?\d*(\.\d+)?$/.test(n);
    },

    getAllVersionForAmpersands(companyQuery) {
        const ampersandsMap = {
            and: [
                'and',
                '&',
                '&amp;',
            ],
            '&': [
                '&',
                'and',
                '&amp;',
            ],
            '&amp;': [
                '&amp;',
                'and',
                '&',
            ],
        };
        const words = (companyQuery || '').trim().split(' ');

        return words.reduce((versions, word) => {
            const wordWithoutSpecials = word
                .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                .replace(/[^a-zA-Z0-9 &]/g, '');

            const hasAlternatives = Object.keys(ampersandsMap).includes(wordWithoutSpecials);

            if (versions.length === 0) {
                if (!hasAlternatives) {
                    return [wordWithoutSpecials];
                }
                return ampersandsMap[wordWithoutSpecials];
            }
            return versions.reduce((newVersions, version) => {
                if (hasAlternatives) {
                    ampersandsMap[wordWithoutSpecials].forEach(replacer => {
                        newVersions.push(`${version} ${replacer}`);
                    });
                } else {
                    newVersions.push(`${version} ${wordWithoutSpecials}`);
                }
                return newVersions;
            }, []);
        }, []);
    },
};
