import {
    atom, DefaultValue, selector, selectorFamily,
} from 'recoil';
import TokenStorage, { StoredTokenType } from '@data/tokenStorage';
import { type DataWrapperType, getDataWrapperInstance } from '@powerednow/complexData/connection/api/dataWrapper';
import { getApiRequest, getApiRequestForAuth } from '@app/connection/apiRequest';

export type AccountDetailsFromServer = {
    companyId: number,
    contactId: number,
}
export interface AuthData {
    portalId: string | null,
    data: StoredTokenType
}

/**
 * availableAccounts is an atom which can be used to
 * get all the available accounts that currently stored
 * in the local storage.
 */
export const availableAccounts = atom<Record<string, StoredTokenType>>({
    key: 'availableAccounts',
    default: TokenStorage.getAllTokens(),
});

/**
 * currentPortalID is used to store an id which
 * helps to get further information about the
 * current portal user. This information lives
 * under the same key in availableAccounts atom.
 * It's value set in the loggedInUser selector.
 */
export const currentPortalID = atom<string | null>({
    key: 'currentPortalID',
    default: null,
});

export const dataWrapper = atom<DataWrapperType>({
    key: 'dataWrapper',
    default: getDataWrapperInstance(getApiRequest()),
    dangerouslyAllowMutability: true,
});

/**
 * based on the portalId passed to it
 * loggedInUser selector sets the currentPortalID
 * to the provided portalId and updates authData
 * if an account is available with that portalId
 * from availableAccounts atom.
 */
export const loggedInUser = selector<string | null>({
    key: 'loggedInUser',
    get: ({ get }) => get(currentPortalID),
    set: ({ get, set }, newValue) => {
        set(currentPortalID, newValue);
        if (!newValue || newValue instanceof DefaultValue) {
            return;
        }
        const allAccounts = get(availableAccounts);
        const data = allAccounts[newValue];
        if (data) {
            const dataWrapperInstance = getDataWrapperInstance(getApiRequestForAuth({
                companyId: data.companyId,
                customerId: data.customerId,
                contactId: data.contactId,
                authToken: data.authToken,
                userProfiles: data.userProfiles,
            }));
            set(dataWrapper, dataWrapperInstance);
        }
    },
});

/**
 * authState contains data of the portalId and
 * authData related to that portalId. The portalId's
 * value is going to be the current value of
 * loggedInUser selector.
 */
export const authState = selector<AuthData>({
    key: 'authState',
    get: ({ get }) => {
        const portalId = get(loggedInUser);
        const allAccounts = get(availableAccounts);
        if (!portalId || !allAccounts[portalId]) {
            return {
                portalId: null,
                data: {
                    companyId: null,
                    customerId: null,
                    contactId: null,
                    authToken: null,
                    userProfiles: null,
                    email: '',
                },
            };
        }
        return { portalId, data: allAccounts[portalId] };
    },
});

/**
 * Based on the current portalId and
 * the availableAccounts, isLogged in selector
 * checks if the logged in criteria met
 */
export const isLoggedIn = selector<boolean>({
    key: 'isLoggedIn',
    get: ({ get }) => {
        const portalId = get(loggedInUser);
        const allAccounts = get(availableAccounts);
        return portalId !== null && Boolean(allAccounts[portalId]);
    },
});

/**
 * isValidLogin waits a portalId as a parameter
 * and checks if there is any available account
 * to that portalId.
 */
export const isValidLogin = selectorFamily<boolean, string>({
    key: 'auth/isValidLogin',
    get: portalId => (function ({ get }) {
        const allAccounts = get(availableAccounts);
        return portalId !== null && Boolean(allAccounts[portalId]);
    }),
});
