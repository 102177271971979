import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { List as MUIList, ListItem as MUIListItem, ListItemIcon as MUIListItemIcon, ListItemText as MUIListItemText, ListItemAvatar as MUIListItemAvatar, ListSubheader as MUIListSubheader, Avatar, Box as MuiBox, } from '@mui/material';
import { omit } from 'lodash';
function ListItemIconSlug({ element, isStart, elementProps }) {
    if (element) {
        return _jsx(MUIListItemIcon, Object.assign({ style: { justifyContent: isStart ? 'flex-start' : 'flex-end' } }, elementProps, { children: element }));
    }
    return null;
}
function ListItemAvatarSlug({ element, elementProps }) {
    if (element) {
        return (_jsx(MUIListItemAvatar, Object.assign({}, elementProps, { children: _jsx(Avatar, { children: element }) })));
    }
    return null;
}
function SubheaderSlug({ element }) {
    if (element) {
        return _jsx(MUIListSubheader, { children: element });
    }
    return null;
}
function ListItemTextSlug(props) {
    const { primary, secondary, textProps } = props;
    if (primary || secondary) {
        return _jsx(MUIListItemText, Object.assign({ primary: primary, secondary: secondary }, textProps));
    }
    return null;
}
function ListItem(props) {
    const { avatar, startIcon, secondary, endIcon, avatarProps, startIconProps, textProps, endIconProps, children, } = props;
    return (_jsxs(MUIListItem, Object.assign({}, omit(props, ['button', 'avatar', 'before', 'children', 'after', 'endIcon']), { children: [_jsx(ListItemAvatarSlug, { element: avatar, elementProps: avatarProps }), _jsx(ListItemIconSlug, { element: startIcon, elementProps: startIconProps, isStart: true }), _jsx(ListItemTextSlug, { primary: children, secondary: secondary, textProps: textProps }), _jsx(ListItemIconSlug, { element: endIcon, elementProps: endIconProps, isStart: false })] })));
}
function List(props) {
    const { listStyle, listClassName, dense, subheader, disablePadding, children, } = props;
    return (_jsx(MuiBox, { children: _jsx(MUIList, Object.assign({ style: listStyle, className: listClassName, dense: dense, subheader: _jsx(SubheaderSlug, { element: subheader }), disablePadding: disablePadding }, omit(props, ['css', 'dense', 'subheader', 'disablePadding']), { children: children })) }));
}
export { List, ListItem };
