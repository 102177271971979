export default {
    TIMELINE_FILTER_BY_TYPE: {
        ALL: 0,
        QUOTES: 1,
        SALES_DOCUMENTS: 2,
        SUPPLIER_DOCUMENTS: 3,
        EXPENSE_DOCUMENTS: 4,
        JOB_SHEETS: 5,
        PURCHASE_ORDERS: 6,
        PAYMENTS: 7,
        APPOINTMENTS_DT_CREATED: 8,
        REMINDERS: 9,
        FORM_DOCUMENTS: 10,
        NOTES: 11,
        TIME_LOGS: 12,
        SIGNATURES: 13,
        PRICED_ITEM_GROUPS: 14,
        EMAILS: 15,
        TEXTS: 16,
        WHATSAPP: 17,
        MESSAGES: [15, 16, 17],
        APPOINTMENTS_DT_START: 18,
        MEDIA: 19,
        FILES: 20,
    },
};
