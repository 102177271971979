import { jsx as _jsx } from "react/jsx-runtime";
import { styled, useThemeProps } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, InputAdornment, TextField as MUITextField, } from '@mui/material';
import clsx from 'clsx';
import Close from '@mui/icons-material/Close';
import { omit } from 'lodash';
import useStateControl from '../hooks/useStateControl';
import htmlDecode from '../helper/htmlDecode';
import htmlEncode from '../helper/htmlEncode';
const useStyles = makeStyles(createStyles({
    noLabelWrap: {
        '& p': {
            whiteSpace: 'nowrap',
        },
        '& label': {
            whiteSpace: 'nowrap',
        },
    },
    noBorder: {
        '& .MuiInput-underline:before': {},
        '& .MuiInput-underline.Mui-focused:after': {
            content: 'none',
        },
    },
}));
const TextFieldRoot = styled(MUITextField, {
    name: 'TextField',
    slot: 'root',
})({});
export default function TextField(inProps) {
    const props = useThemeProps({ props: inProps, name: 'TextField' });
    const { value, noLabelWrap, startAdornment, endAdornment, InputProps, inputProps, InputLabelProps, className, noBorder, align, disabled, clearable, onChange, variant = 'standard', } = props;
    const classes = useStyles();
    const noLabelWrapClass = classes[noLabelWrap ? 'noLabelWrap' : ''];
    const [currentValue, setCurrentValue] = useStateControl(value || '');
    const handleClear = () => {
        setCurrentValue('');
        if (onChange) {
            onChange({
                target: { value: '' },
            });
        }
    };
    const renderStartAdornments = () => {
        const adornment = startAdornment || (inputProps === null || inputProps === void 0 ? void 0 : inputProps.startAdornment);
        if (adornment) {
            return (_jsx(InputAdornment, { position: "start", children: _jsx("p", { className: "MuiFormLabel-root", children: adornment }) }));
        }
        return null;
    };
    const renderEndAdornments = () => {
        const returnElement = [];
        const adornment = endAdornment || (inputProps === null || inputProps === void 0 ? void 0 : inputProps.endAdornment);
        if (adornment) {
            returnElement.push(_jsx(InputAdornment, { position: "end", children: _jsx("p", { className: "MuiFormLabel-root", children: adornment }) }, "customAdornment"));
        }
        if (clearable) {
            returnElement.push(_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { size: "small", "aria-label": "clear", onClick: handleClear, style: { visibility: (currentValue === '' || disabled) ? 'hidden' : 'initial' }, children: _jsx(Close, {}) }) }, "clearAdornment"));
        }
        if (clearable || adornment) {
            return returnElement;
        }
        return null;
    };
    const handleChange = (event) => {
        event.target.value = htmlEncode(event.target.value);
        setCurrentValue(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };
    const startAdornmentComponent = renderStartAdornments();
    const endAdornmentComponent = renderEndAdornments();
    const newProps = Object.assign(Object.assign({}, omit(props, 'onchange', 'clearable', 'noLabelWrap', 'startAdornment', 'endAdornment', 'align', 'numberFormat', 'noBorder')), { size: undefined, InputLabelProps: Object.assign({}, InputLabelProps), color: 'primary', variant, className: clsx(className, noLabelWrapClass, noBorder ? classes.noBorder : null), InputProps: Object.assign(Object.assign({}, InputProps), { startAdornment: startAdornmentComponent, endAdornment: endAdornmentComponent, inputProps: Object.assign(Object.assign({}, inputProps), { style: { textAlign: align || 'left' } }) }), onChange: handleChange });
    return (_jsx(TextFieldRoot, Object.assign({}, newProps, { value: htmlDecode(currentValue) })));
}
