import React, { useContext, useState } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box, Hidden, Grid, Typography,
} from '@mui/material';
import CompanyHeader from '@features/company/companyHeader/companyHeader';
import AccountButton from '@features/contact/accountButton/accountButton';
import { authState } from '@data/state/auth';
import { hasRole } from '@data/state/permission';
import { useRecoilValue } from 'recoil';
import { useResponsive } from '@data/hooks/responsive';
import { useOnlineBookable } from '@data/hooks/header';
import PageBlock from '@components/layout/PageBlock';
import { CircularProgress } from '@components/progress/Progress';
import { ThemeContext } from '@components/theme/stylesheet';
import { useSimpleComplexData } from '@data/hooks/complexDataHook';
import tokenStorage from '@data/tokenStorage';
import Button from '@components/button/Button';
import { isPortalAvailable } from '@data/state/subscription';
import RequireFeature from '@features/permission/requireFeature';
import { SETTINGS_KEYS, ROLES } from '@powerednow/shared/constants';
import htmlDecode from '@components/helper/htmlDecode';
import { canCustomerAccessPortalChat, companySettings } from '@data/state/companySettings';
import MATERIAL_THEME from '@powerednow/shared/constants/materialTheme';
import CompanyLogo from '../company/companyLogo/companyLogo';

const useStyles = makeStyles<Theme>(createStyles({
    menuContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    headerMenuBar: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    leftMenuButtons: {
        justifyContent: 'left',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '10px',
    },
    rightMenuButtons: {
        justifyContent: 'right',
        display: 'flex',
        flexWrap: 'wrap',
    },
}));

export default function Header() {
    const classes = useStyles();
    const authData = useRecoilValue(authState);
    const companyId = authData?.data.companyId;
    const navigate = useNavigate();
    const location = useLocation();
    const bookableOptions = useOnlineBookable(companyId);
    const bookAppointmentDefaultText = useRecoilValue(companySettings(SETTINGS_KEYS.BOOK_APPOINTMENT_DEFAULT_TEXT));
    const themeContext = useContext(ThemeContext);
    const { switchTheme, themeName } = themeContext;
    const [activeTheme, setActiveTheme] = useState(themeName === `${MATERIAL_THEME.AVAILABLE_THEMES.PORTAL_THEME}--light`); // Light theme is true, Dark theme is false
    const appointmentRoute = `/portal/${authData.portalId}/appointment/`;
    const messageRoute = `/portal/${authData.portalId}/message/`;
    const loginRoute = '/login';

    const responsive = useResponsive();

    if (!companyId) {
        return <CircularProgress />;
    }
    const companyDetails = useSimpleComplexData('Company', companyId);
    const { name } = companyDetails || {};

    const canBookAppointment = useRecoilValue(hasRole(ROLES.PERMISSIONS.CAN_BOOK_APPOINTMENT_ON_PORTAL));
    const canSendMessage = useRecoilValue(hasRole(ROLES.PERMISSIONS.CAN_SEND_MESSAGE_FROM_PORTAL)) && useRecoilValue(canCustomerAccessPortalChat);
    const showBookAppointmentButton = bookableOptions?.loaded && bookableOptions?.isOnlineBookable && canBookAppointment;

    const MenuItems = [
        {
            hide: !showBookAppointmentButton,
            isActive: location.pathname.includes('appointment/create'),
            label: `${htmlDecode(bookAppointmentDefaultText)}`,
            onClick: () => navigate(`${appointmentRoute}create`),
        },
        {
            hide: !canSendMessage,
            label: 'Send a message',
            isActive: location.pathname.includes('message/compose'),
            onClick: () => navigate(`${messageRoute}compose`),
        },
    ];

    const buttonSize = 'large';

    const menuButtons = MenuItems.map(menuItem => {
        if (menuItem.hide) {
            return null;
        }

        return (
            <Button
                emphasis={menuItem.isActive ? 'medium' : 'high'}
                onClick={menuItem.isActive ? () => { } : menuItem.onClick}
                noWrap
                size={buttonSize}
                mr={2}
                mb={1}
                key={`button_${menuItem.label}`}
            >
                {menuItem.label}
            </Button>
        );
    });

    const logout = async () => {
        if (authData.portalId) {
            tokenStorage.deleteToken(authData.portalId);
        }
        navigate(loginRoute);
        window.location.reload();
    };

    const login = async () => {
        navigate(loginRoute);
    };

    const switchThemeFunction = () => {
        if (activeTheme) {
            setActiveTheme(false);
            switchTheme('dark');
        } else {
            setActiveTheme(true);
            switchTheme('light');
        }
    };

    return (
        <header>
            <PageBlock
                className={classes.menuContainer}
                pt={responsive({ xs: 1, md: 2 })}
                pb={responsive({ xs: 1, md: 2 })}
                flexDirection={responsive({ xs: 'column', md: 'row' })}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.headerMenuBar}>
                        <Box className={classes.leftMenuButtons}>
                            <RequireFeature feature={isPortalAvailable}>
                                <>
                                    <AccountButton
                                        onCreateAccount={() => { }}
                                        onLogin={login}
                                        onLogout={logout}
                                        toggleButtonState={activeTheme}
                                        onChange={switchThemeFunction}
                                    />
                                    <Hidden only={['xs', 'sm']}>
                                        {menuButtons}
                                    </Hidden>
                                </>
                            </RequireFeature>
                        </Box>

                        <Box className={classes.rightMenuButtons}>
                            <CompanyHeader
                                companyId={companyId}
                                mb={2}
                                mr={responsive({ xs: 0, md: 2 })}
                            />
                            <CompanyLogo companyId={companyId} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" align="center" noWrap>
                            {htmlDecode(name)}
                        </Typography>
                    </Grid>
                </Grid>
            </PageBlock>
        </header>
    );
}
