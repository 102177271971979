import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import RightIcon from '@mui/icons-material/KeyboardArrowRight';
import { getShortDayNames, getShortDayNamesSundayFirst, getFirstDayOfTheMonth, getLastDayOfTheMonth, } from '@powerednow/shared/modules/utilities/date';
import Button from '../button/Button';
export default function CalendarHeader(props) {
    const theme = useTheme();
    const { onNextMonth, onPreviousMonth, date, firstDay, lastDay, firstDayOfTheWeek, color, } = props;
    const weekendColor = color ? {
        primary: theme.palette.primary.main,
        secondary: theme.palette.secondary.main,
        warning: theme.palette.warning.main,
        error: theme.palette.error.main,
        neutral: theme.palette.neutral.dark,
        none: 'initial',
    }[color] : 'initial';
    const weekdayColor = color ? {
        primary: theme.palette.primary.contrastText,
        secondary: theme.palette.secondary.contrastText,
        warning: theme.palette.warning.contrastText,
        error: theme.palette.error.contrastText,
        neutral: theme.palette.neutral.dark,
        none: 'initial',
    }[color] : 'initial';
    const dayNames = firstDayOfTheWeek === 'sunday' ? getShortDayNamesSundayFirst() : getShortDayNames();
    const isPrevMonthInRange = () => {
        if (!firstDay)
            return true;
        const startOfMonth = getFirstDayOfTheMonth(date);
        return startOfMonth >= firstDay;
    };
    const isNextMonthInRange = () => {
        if (!lastDay)
            return true;
        const endOfTheMonth = getLastDayOfTheMonth(date);
        return endOfTheMonth.getTime() < lastDay.getTime();
    };
    return (_jsxs(Box, { children: [_jsxs(Box, { pb: 1, display: "flex", height: 40, borderBottom: "solid 1px #AAA", justifyContent: "space-between", alignItems: "center", children: [_jsx(Button, { disabled: !isPrevMonthInRange(), buttonType: color || 'none', emphasis: "low", size: "large", startIcon: _jsx(LeftIcon, {}), onClick: onPreviousMonth, style: { borderRadius: '20px' } }), _jsxs(Box, { children: [date.getFullYear(), ' ', date.toLocaleString('MM', { month: 'long' })] }), _jsx(Button, { disabled: !isNextMonthInRange(), buttonType: color || 'none', emphasis: "low", size: "large", startIcon: _jsx(RightIcon, {}), onClick: onNextMonth, style: { borderRadius: '20px' } })] }), _jsx(Box, { display: "flex", height: 40, borderBottom: "solid 1px #AAA", children: dayNames.map((day, ind) => (_jsx(Box, { display: "flex", flex: "1", justifyContent: "center", alignItems: "center", style: { color: ind > 4 ? weekendColor : weekdayColor }, children: day }, `weekday_${day}`))) })] }));
}
