import React from 'react';

import { Box, Card, Typography } from '@mui/material/';

export default function ParentDeletedFallback({ error }) {
    return (
        <Box width={600} p={15} mx="auto">
            <Card>
                <Box p={10}>
                    <Typography variant="h5" align="center">
                        This page can not be displayed
                    </Typography>
                </Box>
                <Box p={10}>
                    <Typography align="center">
                        The parent of the requested resource has been deleted.
                    </Typography>
                </Box>
            </Card>
        </Box>
    );
}
