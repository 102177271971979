import {
    atom, selector, selectorFamily,
} from 'recoil';
import { getApiRequest } from '@app/connection/apiRequest';
import type { ComplexModelEntity } from '@powerednow/models/modelLookup';

const globalData = selectorFamily({
    key: 'globalData',
    get: (tableName: string) => async () => {
        try {
            return getApiRequest().requestGlobalTable(tableName);
        } catch {
            return [];
        }
    },
});

const paymentTypes = atom<ComplexModelEntity<'PaymentType'>[]>({
    key: 'globalData/paymentTypes',
    default: selector({
        key: 'globalData/paymentTypes/Default',
        get: async ({ get }) => get(globalData('PaymentType')),
    }),
});

const paymentType = selectorFamily<ComplexModelEntity<'PaymentType'> | undefined, number>({
    key: 'globalData/paymentTypes',
    get: (id: number) => async ({ get }) => {
        const types = get(paymentTypes);
        return types.find(item => item.id === id);
    },
});

export { globalData, paymentTypes, paymentType };
