import { atom } from 'recoil';

// eslint-disable-next-line no-shadow
export enum TABS {
    Appointment = 0,
    Document,
    Form,
    Payment,
    Message,
    File,
}

export const selectedTab = atom<TABS>({
    key: 'selectedTab',
    default: TABS.Appointment,
});
