import React, { useState } from 'react';
import { useToast } from '@components/toast/toast';
import { useRecoilValue } from 'recoil';
import SetNewPassword from '@features/setNewPassword/SetNewPassword';
import { authState, dataWrapper } from '@data/state/auth';
import textUtils from '@powerednow/shared/modules/utilities/textUtils';
import { useNavigate } from 'react-router-dom';
import PageBlock from '@components/layout/PageBlock';
import { useResponsive } from '@data/hooks/responsive';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';
import { getApiRequest } from '@app/connection/apiRequest';
import { complexFindModel } from '@powerednow/models/modelLookup';
import type DataWrapper from '@powerednow/complexData/connection/api/dataWrapper';

const useStyles = makeStyles<Theme>(createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '3rem',
    },
    changePasswordContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: '25px',
    },
}));

const updatePassword = async (dataWrapperInstance: DataWrapper, contactId: number, password: string, companyId: number) => {
    const contact = await dataWrapperInstance.getComplexDataObject(complexFindModel('Contact'), contactId);
    const userToContact = await contact.getUserToContact();
    await getApiRequest().setPasswordByCompanyData(
        textUtils.hash(password),
        userToContact.data.user_uuid,
        companyId,
    );
};

export default function ChangePassword() {
    const { success } = useToast();
    const navigate = useNavigate();
    const responsive = useResponsive();
    const classes = useStyles();
    const dataWrapperInstance = useRecoilValue(dataWrapper);
    const [password, setPassword] = useState({
        password: '',
        confirmPassword: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const authData = useRecoilValue(authState);
    const { data: { contactId, companyId } } = authData;

    const handleNewPassword = async () => {
        setIsLoading(true);
        await updatePassword(
            dataWrapperInstance,
            contactId!,
            password.password,
            companyId!,
        ).finally(() => {
            setIsLoading(false);
        });
        
        setIsLoading(false);
        navigate(`/portal/${authData.portalId}/`);
        success('Password changed successfully.');
    };

    const handleChange = event => {
        const { name, value } = event.target;
        setPassword(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <PageBlock
            className={classes.root}
            title="Change password"
            titleAlign="center"
            neutral
        >
            <Box
                className={classes.changePasswordContainer}
                paddingLeft={responsive({ xs: 0, sm: '30%' })}
                paddingRight={responsive({ xs: 0, sm: '30%' })}
            >
                <SetNewPassword
                    onSetNewPasswordButtonClicked={handleNewPassword}
                    onPasswordFieldChange={handleChange}
                    password={password.password}
                    confirmPassword={password.confirmPassword}
                    isLoading={isLoading}
                />
            </Box>
        </PageBlock>
    );
}
