import React from 'react';
import { Theme, useTheme, Breakpoint } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { RequireAtLeastOne } from '@powerednow/type-definitions';

export interface BreakpointMap { xs?: any, sm?: any, md?: any, lg?:any, xl?:any }

export const useWidth = () => {
    const theme: Theme = useTheme();
    const keys: Breakpoint[] = [...theme.breakpoints.keys];
    return keys.filter(key => useMediaQuery(theme.breakpoints.up(key)));
};

export const useResponsive = () => {
    const themeWidth = useWidth();
    const theme: Theme = useTheme();

    return (props: RequireAtLeastOne<BreakpointMap, keyof BreakpointMap>):any => {
        let result:any;

        theme.breakpoints.keys.forEach(key => {
            if (themeWidth.includes(key) && Object.prototype.hasOwnProperty.call(props, key)) {
                result = props[key];
            }
        });

        return result;
    };
};
