/**
 * Created by zoltanmagyar on 2017. 04. 14..
 */

/**
 * ValidationError
 * @param data
 * @constructor
 */
// eslint-disable-next-line max-classes-per-file
export class ValidationError extends Error {
    constructor(data) {
        super();
        if (data) {
            this.message = data.message;
            this.name = 'ValidationError';
            this.severity = data.severity || 10;
            this.code = data.code;
        }
    }
}

export class TemplateCodeInsertionError extends Error {
    constructor(template, error) {
        super();
        this.cause = error;
        this.message = 'Your email template is invalid and could not be sent. Please check your template and try resending your email. Please contact support for further assistance.';
        this.name = 'TemplateCodeInsertionInvalid';
        this.severity = 8;
        this.template = template;
    }
}

export class AccountNotVerifiedError extends Error {
    constructor(message, type) {
        super(message);
        this.name = 'AccountNotVerifiedError';
        this.type = type;
    }
}


export class AuthenticationError extends Error {
    constructor(error) {
        super();
        this.name = "AuthenticationError";
        this.code = error.error_code;
        this.message = error.error_msg;

    }
}

export class PhoneNumberRequiredError extends Error {
    constructor(error) {
        super();
        this.name = "PhoneNumberRequiredError";
        this.code = error.error_code;
        this.message = error.error_msg;

    }
}
