import React from 'react';
import { useRecoilValue } from 'recoil';
import useComplexData from '@data/hooks/complexDataHook';
import paymentListColumnConfig from '@features/payment/list/paymentListColumnConfig';
import DataGridTabList, { ComplexTabListItems, TabListItemsParams } from '@features/dataGridTabList/DataGridTabList';
import { displayProjectsOnPortal } from '@data/state/companySettings';
import type { ComplexModelEntity } from '@powerednow/models/modelLookup';

export type PaymentDataType = ComplexModelEntity<'PaymentsForCustomer'>;

type PaymentResult = {
    listItems: PaymentDataType[],
    loaded: boolean,
    total: number,
    error?: any,
}

const usePayments = (params: TabListItemsParams<ComplexModelEntity<'Payment'>>): ComplexTabListItems<ComplexModelEntity<'Payment'>> => {
    const {
        contactId,
        pageSize,
        skip,
        sorters,
    } = params;
    const { optionalResult, error } = useComplexData(
        'Contact',
        [contactId, pageSize, skip, sorters],
        async (complexContact, resultSetter: (_newResult:PaymentResult)=>void) => {
            const complexCustomer = await complexContact.getCustomer();
            const total = await complexCustomer.getPaymentCount();
            const complexPayments = await complexCustomer.getAllPayment({
                skip,
                limit: pageSize,
                sorters,
            });
            resultSetter({
                total,
                loaded: true,
                listItems: complexPayments.map(payment => payment.data.getPureDataValues()),
            });
        },
    );

    return {
        listItems: optionalResult?.listItems || [],
        loaded: optionalResult?.loaded || false,
        total: optionalResult?.total || 0,
        error,
    };
};

export default function PaymentList() {
    const rowHeight = 80;
    const height = 510;
    const sortModel = [
        { field: 'dt_created', sort: 'desc' } as const,
    ];

    const displayProjects = useRecoilValue(displayProjectsOnPortal);
    const columns = paymentListColumnConfig(displayProjects);

    return (
        <DataGridTabList
            columns={columns}
            useGetListItems={usePayments}
            sortModel={sortModel}
            rowHeight={rowHeight}
            height={height}
            noRowsText="Sorry, there are no payments to display"
            tabToNavigate="payment"
        />
    );
}
