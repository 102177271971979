import React, { CSSProperties } from 'react';
import { Box, Paper } from '@mui/material';
import Logo from '@components/Images/Logo';
import { useResponsive } from '@data/hooks/responsive';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import PageBlock from '@components/layout/PageBlock';
import clsx from 'clsx';

const useStyles = makeStyles<Theme>(createStyles({
    root: {
        height: '90vh',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },

    companyLogo: {
        marginBottom: '30px',
    },
}));

type UnAuthorizedPageProps = {
    children: React.ReactNode,
    title: string,
    showCompanyLogo?: boolean,
    className?: CSSProperties | string,
}

export default function UnAuthorizedPage(props: UnAuthorizedPageProps) {
    const {
        children,
        title,
        showCompanyLogo = false,
        className,
    } = props;
    const responsive = useResponsive();
    const classes = useStyles();
    return (
        <Box className={clsx([classes.root, className])}>
            {showCompanyLogo && <Logo className={classes.companyLogo} width={400} height={50} />}
            <Paper elevation={4}>
                <PageBlock
                    display="flex"
                    flexDirection="column"
                    neutral
                    title={title}
                    titleAlign="center"
                    width={responsive({ xs: 300, sm: 400 })}
                    position="relative"
                    p={12}
                >
                    {children}
                </PageBlock>
            </Paper>
        </Box>
    );
}
