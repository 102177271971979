import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import MUIMenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { omit, camelCase } from 'lodash';
import clsx from 'clsx';
const StyledMenuToggle = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
        '& *.MuiButton-label': {
            justifyContent: 'flex-start',
        },
    },
}))(MUIMenuItem);
const useStyles = makeStyles(theme => createStyles({
    iconSmall: {
        minWidth: theme.spacing(7),
        '& svg': { fontSize: theme.spacing(4) },
    },
    iconMedium: {
        minWidth: theme.spacing(8),
        '& svg': { fontSize: theme.spacing(4.5) },
    },
    iconLarge: {
        minWidth: theme.spacing(9),
        '& svg': { fontSize: theme.spacing(5.5) },
    },
    textSmall: { '& .MuiTypography-root': { fontSize: theme.spacing(3) } },
    textMedium: { '& .MuiTypography-root': { fontSize: theme.spacing(3.5) } },
    textLarge: { '& .MuiTypography-root': { fontSize: theme.spacing(4) } },
}));
const MenuToggle = React.forwardRef((props, ref) => {
    const { onToggled, enabledTitle, disabledTitle, enabledIcon, disabledIcon, defaultToggleButtonState, icon, } = props;
    const classes = useStyles();
    const itemProps = Object.assign({}, omit(props, 'children', 'className', 'icon', 'subTitle', 'defaultToggleButtonState', 'enabledTitle', 'disabledTitle', 'enabledIcon', 'disabledIcon', 'onToggled', 'onClick', 'className'));
    const size = { small: 'small', medium: 'medium', large: 'large' }[props.size || 'medium'];
    const sizeClass = classes[size];
    const [toggleButtonState, setToggleButtonState] = useState(defaultToggleButtonState);
    const toggleButtonTitle = toggleButtonState ? enabledTitle : disabledTitle;
    const toggleIcon = (toggleButtonState ? enabledIcon : disabledIcon) || icon;
    const iconSizeClass = classes[camelCase(['icon', size].join('_'))];
    const textSizeClass = classes[camelCase(['text', size].join('_'))];
    const handleOnClick = () => {
        setToggleButtonState(!toggleButtonState);
        if (typeof onToggled !== 'undefined') {
            onToggled(toggleButtonState);
        }
    };
    return (_jsxs(StyledMenuToggle, Object.assign({}, itemProps, { className: clsx(props.className, sizeClass), onClick: handleOnClick, children: [toggleIcon && _jsx(ListItemIcon, { className: iconSizeClass, children: toggleIcon }), _jsx(ListItemText, { className: textSizeClass, primary: toggleButtonTitle })] })));
});
export default MenuToggle;
