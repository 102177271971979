const _ = require('lodash');

/**
 * Collection of array-related functions
 *
 * @type {{getIndex: (function(*, *, *)), inverseObjectKeyValue: module.exports.inverseObjectKeyValue}}
 */

export default class ArrayUtils {
    static stdDeviation(array: Array<number>): number {
        const mean = array.reduce((sum, value) => sum + value, 0) / array.length;
        const variance = array.reduce((sum, value) => sum + (value - mean) ** 2, 0) / array.length;
        return Math.sqrt(variance);
    }

    static isArrayBuffer(v) {
        return typeof v === 'object' && v !== null && v.byteLength !== undefined;
    }

    /**
     * Get the index on element of an array of objects by the object's propertyName and value
     *
     * @param arr
     * @param propertyName
     * @param value
     * @returns {*}
     */
    static getIndex(arr, propertyName, value) {
        return arr.findIndex(item => item[propertyName] === value);
    }

    /**
     * Find an item in an array of objects. The find query is expected to be
     * a key-value object of criteria. E.g. in case of
     * var arr = [{id:1, v:'a'}, {id:1, v:'b'}, {id:3, v:'a'}];
     *
     * findObjectInArray(arr, {v:'a'}) -> [{id:1, v:'a'}, {id:3, v:'a'}]
     * findObjectInArray(arr, {id:1}) -> {id:1, v:'a'}
     * findObjectInArray(arr, {id:1, v:'b'}) -> {id:1, v:'b'}
     *
     * @param array
     * @param object
     * @returns {Array}
     */
    static findObjectInArray(array, object) {
        const indexes = this.findObjectIndexInArray(array, object, false);
        const matches = array.filter((element, index) => indexes.includes(index));
        return matches.length === 1 ? matches[0] : matches;
    }

    /**
     * Find an item in an array of objects. The find query is expected to be
     * a key-value object of criteria. E.g. in case of
     * var arr = [{id:1, v:'a'}, {id:1, v:'b'}, {id:3, v:'a'}];
     *
     * findObjectIndexInArray(arr, {v:'a'}) -> [0, 2]
     * findObjectIndexInArray(arr, {id:1}) -> 0
     * findObjectIndexInArray(arr, {id:1, v:'b'}) -> 1
     *
     * @param array
     * @param object
     * @returns {Array}
     */
    static findObjectIndexInArray(array, object, singleItemToNonArray = true) {
        const indexes = array.reduce((result, item, index) => {
            if (this.isPropertiesInObject(item, object)) {
                result.push(index);
            }
            return result;
        }, []);

        return singleItemToNonArray ? this._singleItemArrayToNonArray(indexes) : indexes;
    }

    /**
     * tells if haystack object contains needle object's properties
     *
     * @param haystack
     * @param needle
     * @returns {boolean}
     */
    static isPropertiesInObject(haystack, needle) {
        let allPropertiesFound = true;
        Object.keys(needle)
            .forEach(objectKey => {
                if (needle[objectKey] !== haystack[objectKey]) {
                    allPropertiesFound = false;
                }
            });
        return allPropertiesFound;
    }

    /**
     *
     * @param objects
     * @param properties
     * @param defaultValue
     */
    static fillObjectsWithDefaultValues(objects, properties, defaultValue = 0) {
        return objects.map(object => this._fillObjectWithDefaultValues(object, properties, defaultValue));
    }

    static _fillObjectWithDefaultValues(object, properties, defaultValue) {
        const defaults = properties.reduce((map, colKey) => {
            const value = typeof object[colKey] !== 'undefined' ? object[colKey] : defaultValue;
            return Object.assign(map, { [colKey]: value });
        }, {});

        return Object.assign(object, defaults);
    }

    /**
     * get all column keys from array
     * @param rowsData
     * @param excludePattern
     * @return {Array}
     */
    static getColumnKeysFromArray(rowsData, excludePattern = /_null$/) {
        const columnKeys = [];
        rowsData.forEach(row => {
            Object.keys(row)
                .forEach(columnKey => {
                    const isSkip = (excludePattern instanceof RegExp && excludePattern.test(columnKey));
                    if (columnKeys.indexOf(columnKey) === -1 && !isSkip) {
                        columnKeys.push(columnKey);
                    }
                });
        });
        return columnKeys;
    }

    /**
     * assigns a property to an Object
     * @param objectToAssign
     * @param key
     * @param value
     * @returns {*}
     * @private
     */
    static _assignToObject(objectToAssign, key, value) {
        return Object.assign(objectToAssign, {
            [key]: value,
        });
    }

    /**
     * converts a single item array to a non-array item, or returns the full array
     * @param array
     * @returns {*}
     * @private
     */
    static _singleItemArrayToNonArray(array) {
        let retVal = array;
        if (array.length === 0) {
            retVal = -1;
        } else if (array.length < 2) {
            [retVal] = array;
        }
        return retVal;
    }

    static mergeByObjectProps(target, source, props: any[] = [], strict = false) {
        return target
            .filter(targetItem => !source.find(sourceItem => {
                const filterMethod = strict ? 'every' : 'some';
                return props[filterMethod].call(props, prop => targetItem[prop] === sourceItem[prop]);
            }))
            .concat(source);
    }

    static remove(source, item) {
        const index = source.indexOf(item);

        if (index > -1) {
            source.splice(index, 1);
        }
        return source;
    }

    static removeItem(source, object) {
        return source.filter(item => JSON.stringify(item) !== JSON.stringify(object));
    }

    static difference(arrayToInspect: (string | number)[], valuesToExclude: (string | number)[]): any {
        return arrayToInspect.filter(inspectedItem => !valuesToExclude.includes(inspectedItem));
    }

    static searchStringInArray(str, strArray) {
        if (!Array.isArray(strArray)) {
            throw new Error(`searchStringInArray - second parameters is not array: ${strArray}`);
        }
        if (typeof str === 'undefined' || typeof str !== 'string') {
            throw new Error(`searchStringInArray - first parameters is undefined or not string: ${str}`);
        }
        let itemIndex = -1;
        strArray.some((value, index) => {
            let isMatch;
            if (str === '') {
                isMatch = value === str;
            } else {
                isMatch = typeof value === 'string' && value.match(str);
            }

            if (isMatch) {
                itemIndex = index;
                return true;
            }
            return false;
        });

        return itemIndex;
    }

    static async mergeAsync(array:String[], execFunction: Function):Promise<Array<any>> {
        const resultArray = [];
        for (let i = 0; i < array.length; i += 1) {
            // eslint-disable-next-line no-await-in-loop
            const result = await execFunction(array[i]);
            resultArray.push(...result);
        }
        return resultArray;
    }

    static async someAsync(array, filterFunction): Promise<boolean> {
        for (let i = 0; i < array.length; i += 1) {
            // eslint-disable-next-line no-await-in-loop
            if ((await filterFunction(array[i]))) {
                return true;
            }
        }
        return false;
    }

    static async filterAsync<T extends any>(array: T[], predicate: (value: T) => Promise<boolean>): Promise<T[]> {
        const filteredArray = [];
        // eslint-disable-next-line no-restricted-syntax
        for await (const element of array) {
            if (await predicate(element)) {
                filteredArray.push(element);
            }
        }
        return filteredArray;
    }

    static unique(array): any[] {
        return [...new Set(array)];
    }

    static convertKeysValue(source, converter) {
        const data = JSON.parse(JSON.stringify(source));
        return this._convertKeyValue(data, converter);
    }

    static _convertKeyValue(data, converterMap) {
        Object.keys(data).map(key => {
            Object.keys(converterMap).forEach(convertKey => {
                if (key === convertKey) {
                    this._convertObjectValue(data, convertKey, converterMap[convertKey]);
                }
            });

            if (this._isObject(data[key]) || Array.isArray(data[key])) {
                return this._convertKeyValue(data[key], converterMap);
            }

            return data[key];
        });
        return data;
    }

    static _convertObjectValue(obj, convertKey, convertValue) {
        if (obj[convertKey]) {
            const newValue = typeof convertValue === 'function' ? convertValue(obj[convertKey]) : convertValue;
            Object.assign(obj, { [convertKey]: newValue });
        }
    }

    static _isObject(obj) {
        return obj instanceof Object && !(obj instanceof Array);
    }

    static deepUniquePush(array, item) {
        if (!ArrayUtils.deepIncludes(array, item)) {
            array.push(item);
        }
        return array;
    }

    static deepIncludes(array, item) {
        const index = ArrayUtils.findObjectIndexInArray(array, item);
        return index >= 0;
    }

    static deepRemove(roles, item, criterion = ['data.role_id', 'data.userprofile_id']) {
        return roles.filter(record => !criterion.map(dataKey => (_.get(record, dataKey) === _.get(item, dataKey))).every(value => value === true));
    }

    static binarySearch(array = [], value = undefined, {
        closest = false,
        start = 0,
        end = array.length - 1,
    } = {}) {
        let comparator = item => item - value;
        let startIndex = start;
        let endIndex = end;
        if (typeof value === 'function') {
            comparator = value;
        }

        while (startIndex <= endIndex) {
            const middleIndex = Math.floor((startIndex + endIndex) / 2);
            const middleValueCompared = comparator(array[middleIndex]);

            if (middleValueCompared === 0) {
                return middleIndex;
            }
            if (middleValueCompared > 0) {
                endIndex = middleIndex - 1;
            } else {
                startIndex = middleIndex + 1;
            }
        }
        if (closest && array.length) {
            endIndex = Math.max(endIndex, 0);
            startIndex = Math.min(startIndex, array.length - 1);
            const startValue = Math.abs(comparator(array[startIndex]));
            const endValue = Math.abs(comparator(array[endIndex]));
            if (isNaN(startValue) || isNaN(endValue)) {
                return -1;
            }
            return startValue <= endValue ? startIndex : endIndex;
        }
        return -1;
    }

    static binaryFindIndex(array, value) {
        return ArrayUtils.binarySearch(array, value);
    }

    static binaryFind(array, value) {
        return array[ArrayUtils.binaryFindIndex(array, value)];
    }

    static binaryFindClosestIndex(array, value) {
        return ArrayUtils.binarySearch(array, value, { closest: true });
    }

    static binaryFindClosest(array, value) {
        return array[ArrayUtils.binaryFindClosestIndex(array, value)];
    }

    /* this method can be used to sort an Array of Object based on the value of an Object key
       ************
       const objectArray = [{ key1 : value1 }, key2 : { secKey1 : secValue1 , secKey2 : secValue2 }];
       objectArraySorter( objectArray, path = 'key2.secKey1', 'asc');
       ************
       the 1st prop contains the Object array,
           2nd prop would contain the path to the value used to sort the array
       and the 3rd prop would contain the sort order(ascending/descending)
     */
    static objectArraySorter(objectArray, path, order = 'asc') {
        // keys should be provided in order of destructuring the object
        function objectSorter() {
            return function innerSort(element1, element2) {
                const value1 = _.get(element1, path);
                const value2 = _.get(element2, path);

                if (typeof value1 === 'undefined' || typeof value2 === 'undefined') {
                    return undefined;
                }

                let comparison = 0;
                if (value1 > value2) {
                    comparison = 1;
                } else if (value1 < value2) {
                    comparison = -1;
                }
                return (
                    (order === 'desc') ? (comparison * -1) : comparison
                );
            };
        }
        const sortedObj = objectArray.sort(objectSorter());
        return sortedObj;
    }

    static areAllElementsIncluded(elementsToCheck: string[], targetArray: string[]): boolean {
        return _.difference(targetArray, elementsToCheck).length === 0;
    }

    static splitObjectArrayByKey<T extends Object>(array: T[], key: keyof T) {
        return array.reduce<T[][]>((acc, item) => {
            const mainArray = acc[0];
            if (mainArray.findIndex(i => i[key] === item[key]) !== -1) {
                acc.push([item]);
            } else {
                mainArray.push(item);
            }

            return acc;
        }, [[]]);
    }
}
