import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { DataGrid as MUIDataGrid, GridOverlay, useGridApiContext, useGridSelector, GridPagination, gridPageSizeSelector, gridFilteredTopLevelRowCountSelector, useGridRootProps, } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { omit, camelCase } from 'lodash';
import LinearProgress from '@mui/material/LinearProgress';
import clsx from 'clsx';
import useStateControl from '../hooks/useStateControl';
import { useResponsive } from '../hooks/responsive';
import ErrorBoundaryWithTryAgainModal from '../errorBoundary/errorBoundary';
import Pagination from '../pagination/Pagination';
const useStyles = makeStyles(theme => createStyles({
    root: {
        border: 'none',
        '& .MuiDataGrid-colCell': {
            outline: 'none !important',
        },
        '& .MuiDataGrid-cell': {
            outline: 'none !important',
        },
        '& .MuiDataGrid-row.Mui-odd': {
            backgroundColor: theme.palette.dataGridRowColor.main,
        },
        '& .MuiDataGrid-row:hover': {
            backgroundColor: theme.palette.dataGridRowColor.light,
        },
        '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: theme.palette.dataGridRowColor.selected,
        },
        '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: theme.palette.dataGridRowColor.selectedhover,
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
            padding: 0,
        },
    },
    noHeaderSeparator: {
        '& .MuiDataGrid-columnSeparator': {
            display: 'none',
        },
    },
    noRowBorder: {
        '& .MuiDataGrid-cell': {
            borderBottom: 'none !important',
        },
    },
    striped: {
        '& .MuiDataGrid-row:nth-child(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
        },
    },
    cursorPointer: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        },
    },
    cursorDefault: {
        '& .MuiDataGrid-row': {
            cursor: 'default',
        },
    },
    cellFocus: {
        '& .MuiDataGrid-cell:focus': {
            border: 'dotted 1px rgba(0,0,0,0.2)',
            borderTop: 'none',
        },
    },
    hideOriginalPagination: {
        '& .MuiToolbar-root': {
            display: 'none',
        },
    },
    hideLabelDisplayedRows: {
        '& .MuiTablePagination-displayedRows': {
            display: 'none',
        },
    },
}));
function CustomLoadingOverlay() {
    return (_jsx(GridOverlay, { children: _jsx("div", { style: { position: 'absolute', top: 0, width: '100%' }, children: _jsx(LinearProgress, {}) }) }));
}
function CustomNoRowsOverlay(props) {
    const { noRowsText } = props;
    return (_jsx(GridOverlay, { children: _jsx(Box, { children: _jsx("div", { children: noRowsText || 'Sorry, there is nothing to display' }) }) }));
}
function CustomErrorOverlay(props) {
    const { error } = props;
    return (_jsx(GridOverlay, { children: _jsx(Box, { display: "flex", position: "absolute", children: _jsx("div", { children: (error === null || error === void 0 ? void 0 : error.message) || 'An Error Occurred' }) }) }));
}
const getPageCount = (rowCount, pageSize) => {
    if (pageSize > 0 && rowCount > 0) {
        return Math.ceil(rowCount / pageSize);
    }
    return 0;
};
function CustomPagination({ page, onPageChange, }) {
    var _a;
    const classes = useStyles();
    const responsive = useResponsive();
    const apiRef = useGridApiContext();
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
    const visibleTopLevelRowCount = useGridSelector(apiRef, gridFilteredTopLevelRowCountSelector);
    const rootProps = useGridRootProps();
    const pageCount = getPageCount((_a = rootProps.rowCount) !== null && _a !== void 0 ? _a : visibleTopLevelRowCount, pageSize);
    return pageCount <= 1 ? null : (_jsx(Pagination, { className: classes.root, color: "primary", count: pageCount, page: page + 1, onChange: (event, value) => {
            onPageChange(event, value - 1);
        }, siblingCount: responsive({ xs: 0, sm: undefined }), size: responsive({ xs: 'small', sm: undefined }) }));
}
function GridCustomPagination(props) {
    return _jsx(GridPagination, Object.assign({ ActionsComponent: CustomPagination }, props));
}
export default function DataGrid(props) {
    const classes = useStyles();
    const { rows, className, paginationStyle, boxProps, noHeaderSeparator, noRowBorder, striped, rowCursor, enableCellFocus, height, maxHeight, minHeight, paginationModel, rowCount, onPageChange, hideLabelDisplayedRows = true, } = props;
    if (!rows) {
        return null;
    }
    const [customPaginationModel, setCustomPaginationModel] = useState({
        pageSize: paginationModel.pageSize,
        page: paginationModel.page,
    });
    const [rowCountState] = useStateControl(rowCount || 0);
    const handlePaginationModelChange = (value) => {
        setCustomPaginationModel(value);
        onPageChange(value);
    };
    let mergedClasses = clsx(className, classes.root);
    if (noHeaderSeparator) {
        mergedClasses = clsx(mergedClasses, classes.noHeaderSeparator);
    }
    if (noRowBorder) {
        mergedClasses = clsx(mergedClasses, classes.noRowBorder);
    }
    if (striped) {
        mergedClasses = clsx(mergedClasses, classes.striped);
    }
    if (rowCursor) {
        mergedClasses = clsx(mergedClasses, classes[camelCase(['cursor', rowCursor].join('_'))]);
    }
    if (enableCellFocus) {
        mergedClasses = clsx(mergedClasses, classes.cellFocus);
    }
    if (paginationStyle === 'list') {
        mergedClasses = clsx(mergedClasses, classes.hideOriginalPagination);
    }
    if (hideLabelDisplayedRows) {
        mergedClasses = clsx(mergedClasses, classes.hideLabelDisplayedRows);
    }
    return (_jsx(Box, Object.assign({ style: {
            height: height || 'initial',
            maxHeight: maxHeight || 'initial',
            minHeight: minHeight || 300,
        }, position: "relative" }, boxProps, { children: _jsx(ErrorBoundaryWithTryAgainModal, { fallback: CustomErrorOverlay, children: _jsx(MUIDataGrid, Object.assign({ slots: {
                    loadingOverlay: CustomLoadingOverlay,
                    noRowsOverlay: CustomNoRowsOverlay,
                    pagination: GridCustomPagination,
                }, className: mergedClasses, paginationModel: customPaginationModel, onPaginationModelChange: handlePaginationModelChange, rowCount: rowCountState }, omit(props, ['paginationStyle', 'className', 'noRowsText', 'height', 'minHeight', 'maxHeight', 'rowCount']))) }) })));
}
