import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Tabs as MUITabs, Tab as MUITab, Box, Paper, } from '@mui/material';
import clsx from 'clsx';
import { camelCase } from 'lodash';
import chroma from 'chroma-js';
import useStateControl from '../hooks/useStateControl';
import { useResponsive } from '../hooks/responsive';
function Tabs(props) {
    return _jsx(MUITabs, Object.assign({}, props));
}
function Tab(props) {
    return _jsx(MUITab, Object.assign({}, props));
}
const useTabPanelStyles = makeStyles(theme => ({
    hidden: {
        display: 'none',
    },
    visible: {
        display: 'block',
    },
}));
function TabPanel({ children, value, index, className, }) {
    const classes = useTabPanelStyles();
    return (_jsx("div", { className: clsx(className, value !== index ? classes.hidden : classes.visible), children: value === index && (_jsx(Box, { p: 3, children: children })) }));
}
const getIndicatorDefault = indicatorColor => ({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 3,
    minWidth: 0,
    '& > div': {
        width: '70%',
        backgroundColor: chroma(indicatorColor).darken(0).alpha(0.9).hex(),
    },
});
export const getColorStyles = (theme, buttonBackgroundColor, buttonTextColor, indicatorColor) => () => ({
    backgroundColor: buttonBackgroundColor,
    '& .MuiTabs-indicator': getIndicatorDefault(indicatorColor),
    '& .MuiButtonBase-root': {
        color: theme.palette.mode === 'dark' && buttonBackgroundColor !== 'transparent'
            ? chroma(buttonTextColor).darken(buttonTextColor).hex()
            : buttonTextColor,
        opacity: 0.65,
        '&.Mui-selected': {
            opacity: 0.95,
        },
        '&.Mui-disabled': {
            opacity: 0.3,
        },
    },
});
const useStyles = makeStyles(theme => ({
    colorNone: getColorStyles(theme, 'transparent', theme.palette.text.primary, theme.palette.grey[700]),
    colorPrimary: getColorStyles(theme, theme.palette.primary.light, theme.palette.primary.contrastText, theme.palette.primary.main),
    colorSecondary: getColorStyles(theme, theme.palette.secondary.light, theme.palette.secondary.contrastText, theme.palette.secondary.main),
    colorWarning: getColorStyles(theme, theme.palette.warning.light, theme.palette.warning.contrastText, theme.palette.warning.main),
    colorError: getColorStyles(theme, theme.palette.error.light, theme.palette.error.contrastText, theme.palette.error.main),
    colorNeutral: getColorStyles(theme, theme.palette.neutral.main, theme.palette.neutral.contrastText, theme.palette.neutral.dark),
    positionTop: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiTabs-root': {
            flex: 1,
            borderBottom: `solid 1px ${theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200]}`,
        },
    },
    positionLeft: {
        display: 'flex',
        flexDirection: 'row',
        '& .MuiTabs-root': {
            borderRight: `solid 1px ${theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200]}`,
        },
    },
    justifyTabsCenter: {
        '& .MuiTabs-flexContainer': {
            justifyContent: 'center',
        },
    },
    fullWidth: {
        width: '100%',
        flex: 1,
    },
}));
function SimpleTab(props) {
    const { value, onChange, panels, elevation, variant, tabsVariant, square, position, className, color, key, tabsCentered, } = props;
    const [currentValue, setCurrentValue] = useStateControl(value);
    const classes = useStyles();
    const responsive = useResponsive();
    useEffect(() => {
        setTimeout(() => window.dispatchEvent(new CustomEvent('resize')), 0);
    }, []);
    const handleChange = (event, newValue) => {
        if (onChange) {
            onChange(event, newValue);
        }
        setCurrentValue(newValue);
    };
    const tabs = panels.map((panel, ind) => {
        const tabValue = (typeof panel.value !== 'undefined') ? panel.value : ind;
        return (_jsx(Tab, { label: panel.label || null, icon: panel.icon || null, disabled: panel.disabled, value: tabValue, style: panel.style, className: panel.className }, `${key ? `${key}-` : ''}tabs-${tabValue}`));
    });
    const panelComponents = panels.map((panel, ind) => {
        const tabValue = (typeof panel.value !== 'undefined') ? panel.value : ind;
        return (_jsx(TabPanel, { value: currentValue, index: typeof panel.value !== 'undefined' ? panel.value : ind, children: panel.panel }, `${key ? `${key}-` : ''}panel-${tabValue}`));
    });
    return (_jsxs(Paper, { elevation: typeof elevation === 'undefined' ? 0 : elevation, variant: variant || 'elevation', square: square || false, className: clsx(classes.fullWidth, className, classes === null || classes === void 0 ? void 0 : classes[camelCase(['position', (position || 'top')].join('_'))]), children: [_jsx(Box, { display: "flex", style: { justifyContent: 'center' }, children: _jsx(Tabs, { value: currentValue, align: "right", variant: tabsVariant || 'scrollable', TabIndicatorProps: { children: _jsx("div", {}) }, onChange: handleChange, orientation: (position || 'top') === 'top' ? 'horizontal' : 'vertical', className: clsx(classes === null || classes === void 0 ? void 0 : classes[camelCase(['color', (color || 'none')].join('_'))]), centered: tabsVariant === 'scrollable' ? false : tabsCentered, scrollButtons: tabs.length < 2 ? 'off' : responsive({ xs: 'on', sm: 'auto' }), children: tabs }) }), _jsx(Box, { children: panelComponents })] }));
}
export { Tab, Tabs, TabPanel, SimpleTab, };
