import React from 'react';
import { CircularProgress } from '@components/progress/Progress';
import { useRecoilValue } from 'recoil';
import { authState } from '@data/state/auth';

import useComplexData from '@data/hooks/complexDataHook';
import SetAccountPassword from '@pages/password/SetAccountPassword';
import ChangePassword from '@pages/password/ChangePassword';

const useContactDetails = (contactId: number | null) => {
    const { details, optionalResult } = useComplexData(
        'Contact',
        contactId,
        async (complexContact, resultSetter:(_newResult:{hasAccount: boolean})=>void) => {
            const hasAccount = await complexContact.hasRegisteredUser();
            resultSetter({
                hasAccount,
            });
        },
    );
    return {
        contactDetails: details,
        hasAccount: optionalResult?.hasAccount,
    };
};

export default function AccountPassword() {
    const authData = useRecoilValue(authState);
    const { hasAccount } = useContactDetails(authData?.data.contactId);

    if (typeof hasAccount === 'undefined') {
        return <CircularProgress />;
    }

    if (!hasAccount) {
        return <SetAccountPassword />;
    }

    return (
        <ChangePassword />
    );
}
