import React from 'react';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useResponsive } from '@data/hooks/responsive';
import useUniqueAccounts from '@data/hooks/uniqueAccounts';
import AvailableAccountList from '@features/auth/availableAccountList/availableAccountList';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles<Theme>(theme => createStyles({
    companyLogo: {
        '& img': {
            maxHeight: '60px',
            maxWidth: '200px',
            width: 'auto',
            borderRadius: '4px',
            marginRight: theme.spacing(4),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
}));

export default function AccountSelect() {
    const classes = useStyles();
    const responsive = useResponsive();
    const navigate = useNavigate();
    const filteredAccounts = useUniqueAccounts();
    const portalIds = Object.keys(filteredAccounts);

    if (portalIds.length === 0) {
        navigate('/login');
    } if (portalIds.length === 1) {
        navigate(`/login/${portalIds[0]}`);
    }
    return (
        <Box
            className={classes.companyLogo}
            height="100vh"
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexGrow={0}
            flexDirection={responsive({ xs: 'column', sm: 'column' })}
        >
            <Paper elevation={1}>
                <Box p={2}>
                    <Typography variant="h4">
                        Select Account
                    </Typography>
                    <br />
                    <AvailableAccountList />
                </Box>
            </Paper>
        </Box>
    );
}
