import React from 'react';
import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { authState } from '@data/state/auth';
import { useRecoilValue } from 'recoil';
import { useResponsive } from '@data/hooks/responsive';
import PageBlock from '@components/layout/PageBlock';
import { CircularProgress } from '@components/progress/Progress';
import CompanyFooter from '../company/companyFooter';

const useStyles = makeStyles<Theme>(theme => createStyles({
    footer: {
        backgroundColor: theme.palette.customBackgrounds?.dark,
        '& p': {
            fontSize: '1.1rem',
            lineHeight: '2rem',
            color: theme.palette.grey[500],
            '& a': {
                color: theme.palette.grey[500],
            },
        },
    },
    logo: {
        '& img': {
            maxWidth: '250px',
            height: 'auto',
        },
    },
}));

function DefaultLogo() {
    const responsive = useResponsive();
    const classes = useStyles();

    return (
        <Box display="flex" className={classes.logo} justifyContent="flex-end" flexDirection="column">
            <Typography variant="body2" align={responsive({ xs: 'center', md: 'right' })}>
                &nbsp;
                <br />
                <img alt="logo" src="https://static1.squarespace.com/static/5bf7d05ae2ccd1f768efc5c4/t/5bf7de48c2241bd501f0419a/1608050961061/?format=1500w" />
                <br />
                © Powered Now Limited
                <br />
                This customer portal is provided by Powered Now.&nbsp;
                <a target="_blank" rel="noreferrer" href="https://www.powerednow.com/">Learn more</a>
            </Typography>
        </Box>
    );
}

export default function Footer() {
    const responsive = useResponsive();
    const authData = useRecoilValue(authState);
    const companyId = authData?.data.companyId;

    if (!companyId) {
        return <CircularProgress />;
    }

    return (
        <footer>
            <PageBlock neutral p={12} flexDirection={responsive({ xs: 'column', md: 'row' })}>
                <CompanyFooter companyId={companyId} />
                <DefaultLogo />
            </PageBlock>
        </footer>
    );
}
