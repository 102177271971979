const DEFAULT_VERSION = '0.0.0';

const compareVersions = {
    CONSTANTS: {
        EQUAL: 0,
        BIGGER: 1,
        LOWER: 2,
    },

    /**
     * Compare two version numbers
     * @param version1
     * @param version2
     * @returns {number} 1: first is bigger / 2: second is bigger / 0: equal
     * @private
     */
    compare(version1 = DEFAULT_VERSION, version2 = DEFAULT_VERSION) {
        let v1;
        let v2;
        try {
            v1 = version1.split('.');
            v2 = version2.split('.');
        } catch (err) {
            throw Object.assign(err, {
                message: 'Invalid version sent',
                severity: 4,
            });
        }

        for (let i = 0; i < 3; i += 1) {
            v1[i] = v1[i] || 0;
            v2[i] = v2[i] || 0;
            if (Number(v1[i]) > Number(v2[i])) {
                return this.CONSTANTS.BIGGER;
            } if (Number(v1[i]) < Number(v2[i])) {
                return this.CONSTANTS.LOWER;
            }
        }
        return this.CONSTANTS.EQUAL;
    },
    areEqual(...args) {
        return this.compare(...args) === this.CONSTANTS.EQUAL;
    },
    isFirstBigger(...args) {
        return this.compare(...args) === this.CONSTANTS.BIGGER;
    },
    isFirstLower(...args) {
        return this.compare(...args) === this.CONSTANTS.LOWER;
    },

    getBiggest(...versions) {
        const validVersions = versions.filter(this._isValid);
        validVersions.sort((...args) => (this.isFirstLower(...args) ? 1 : -1));
        return validVersions[0];
    },

    _isValid(version) {
        return version && version.split;
    },

};

export default compareVersions;
