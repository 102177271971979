import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import MUIMenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { omit, camelCase } from 'lodash';
import { emitCustomEvent } from 'react-custom-events';
const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
        '& *.MuiButton-label': {
            justifyContent: 'flex-start',
        },
    },
}))(MUIMenuItem);
const useStyles = makeStyles(theme => createStyles({
    iconSmall: {
        minWidth: theme.spacing(7),
        '& svg': { fontSize: theme.spacing(4) },
    },
    iconMedium: {
        minWidth: theme.spacing(8),
        '& svg': { fontSize: theme.spacing(4.5) },
    },
    iconLarge: {
        minWidth: theme.spacing(9),
        '& svg': { fontSize: theme.spacing(5.5) },
    },
    textSmall: { '& .MuiTypography-root': { fontSize: theme.spacing(3) } },
    textMedium: { '& .MuiTypography-root': { fontSize: theme.spacing(3.5) } },
    textLarge: { '& .MuiTypography-root': { fontSize: theme.spacing(4) } },
}));
const MenuItem = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const itemProps = Object.assign({}, omit(props, 'children', 'icon', 'subTitle', 'onClick', 'className'));
    const size = { small: 'small', medium: 'medium', large: 'large' }[props.size || 'medium'];
    const sizeClass = classes[size];
    const iconSizeClass = classes[camelCase(['icon', size].join('_'))];
    const textSizeClass = classes[camelCase(['text', size].join('_'))];
    const extendedChildren = React.Children.map(props.children, (child, ind) => {
        if (React.isValidElement(child)) {
            const childProps = {
                color: props.color, size, onClose: props.onClose, key: `${props.key}_${{ ind }}`,
            };
            return React.cloneElement(child, Object.assign({}, childProps));
        }
        return child;
    });
    return (_jsxs(StyledMenuItem, Object.assign({}, itemProps, { className: clsx(props.className, sizeClass), tabIndex: 0, ref: ref, onClick: e => {
            if (props.onClick)
                props.onClick(e);
            emitCustomEvent('closeMenu');
        }, children: [props.icon && _jsx(ListItemIcon, { className: iconSizeClass, children: props.icon }), _jsx(ListItemText, { className: textSizeClass, primary: extendedChildren, secondary: props.subTitle })] })));
});
export default MenuItem;
