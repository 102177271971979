import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography, } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import LabelledBox from '../LabelledBox/LabelledBox';
import Button from './Button';
import { useResponsive } from '../hooks/responsive';
const useStyles = makeStyles(createStyles({
    buttonGroupBox: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
export default function ButtonGroup({ title, subtitle, buttons, onClick, children = [], }) {
    const classes = useStyles();
    const responsive = useResponsive();
    return (_jsx(LabelledBox, { className: classes.buttonGroupBox, title: title, p: responsive({ xs: '2rem 0 2rem 0', sm: '1rem 0 1rem 0rem', md: 4 }), children: _jsxs(Box, { maxWidth: "75%", children: [_jsx(Typography, { style: { textAlign: 'center' }, children: subtitle }), buttons.map(button => {
                    const { label, key, selected, disabled, } = button;
                    return (_jsx(Button, { onClick: () => onClick(button.key), fullWidth: true, buttonType: selected ? 'primary' : 'secondary', mt: 4, p: 3, pl: 6, pr: 6, disabled: disabled, children: label }, key));
                }), children] }) }));
}
ButtonGroup.defaultProps = { children: [] };
