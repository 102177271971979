import { atom, selector } from 'recoil';
import { authState, dataWrapper } from '@data/state/auth';
import { complexFindModel, ComplexModelEntity } from '@powerednow/models/modelLookup';

export const companyCurrency = atom<Partial<ComplexModelEntity<'Currency'>>>({
    key: 'CompanyCurrency',
    default: selector({
        key: 'CompanyCurrency/Default',
        get: async ({ get }) => {
            const authData = get(authState);
            const { data: { companyId } } = authData;
            const dataWrapperInstance = get(dataWrapper);
            const complexCompany = await dataWrapperInstance.getComplexDataObject(complexFindModel('Company'), companyId!);
            const complexCurrency = await complexCompany.getCurrency();

            const data = complexCurrency?.data?.getPureDataValues();
            return {
                decimal: data?.decimal || 0,
                decimalsep: data?.decimalsep || '.',
                thousandsep: data?.thousandsep || ',',
                symbol: data?.symbol || '£',
                symbol_before: data?.symbol_before || true,
                iso: data?.iso || 'GBP',
            };
        },
    }),
});

export const currency = atom<Partial<ComplexModelEntity<'Currency'>>>({
    key: 'Currency',
    default: selector({
        key: 'Currency/Default',
        get: async ({ get }) => get(companyCurrency),
    }),
});
