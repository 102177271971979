export default {
    SERVICES: {
        MONGODB: 'mongoDb',
        SEQUELIZE: 'sequelize',
        HTTP_SERVER: 'httpServer',
        HEALTH_CHECK_SERVER: 'healthCheckServer',
        RABBIT_MQ: 'rabbit',
        SOCKET: 'socket',
        REDIS: 'redis',
        REDISCACHE: 'rediscache',
        TEMPORAL: 'temporal',
    } as const,
};
