import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { useBoundingclientrectRef } from 'rooks';
export default function ScrollToBottom(props) {
    const { children } = props;
    const containerEndRef = useRef(null);
    const [containerRef, boundingClientRect] = useBoundingclientrectRef();
    const scrollToBottom = () => {
        var _a;
        (_a = containerEndRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
        });
    };
    useEffect(() => {
        scrollToBottom();
    }, [boundingClientRect === null || boundingClientRect === void 0 ? void 0 : boundingClientRect.height]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: containerRef, children: children }), _jsx("div", { ref: containerEndRef })] }));
}
