/**
 * Created by krisztiankormoczi on 2017. 09. 29..
 */

// CONSTANTS
import BaseValidator from './baseValidator';

const CONSTANTS = require('@powerednow/shared/constants').default;

export default class EmailValidator extends BaseValidator {
    protected config: {
        message: string,
        matcher: RegExp,
        maxLength: number,
    };

    constructor(config = {}) {
        Object.assign(config, {
            message: 'notValidEmail',
            matcher: CONSTANTS.EMAIL_VALIDATOR_MATCHER,
            maxLength: 220,
        });
        super(config);
    }

    async runDefaultRule(value, options) {
        const preparedValue = value || '';

        return this._matcherValidator(preparedValue);
    }

    _matcherValidator(value) {
        if (value.trim() === '') {
            return true;
        }
        if (value.length > this.config.maxLength) {
            return this.config.message;
        }

        const { matcher } = this.config;
        const result = matcher && matcher.test(value);

        if (!result) {
            return this.config.message;
        }

        return result;
    }

    isValidEmail(email) {
        const regex = this.config.matcher;
        return regex && regex.test(email);
    }
}
