import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { defaultLightTheme, defaultDarkTheme } from '../Default/stylesheet';
const PortalLightTheme = createTheme(deepmerge(defaultLightTheme, {
    palette: {
        customBackgrounds: {
            main: '#ffffff',
            light: '#ffffff',
            dark: '#F5F6FA',
        },
        senderColors: {
            main: '#fff',
            light: 'rgba(0, 0, 0, 0.87)',
            dark: 'initial',
        },
        receiverColors: {
            main: '#adb8d7',
            light: 'rgba(0, 0, 0, 0.87)',
            dark: 'initial',
        },
    },
    components: {
        DatePicker: {
            styleOverrides: {
                root: {
                    '& .MuiFormLabel-root.MuiInputLabel-root': {
                        fontSize: '1.25rem',
                    },
                    '.MuiFormHelperText-root': {
                        fontSize: '0.875rem',
                    },
                },
            },
        },
        TextField: {
            styleOverrides: {
                root: {
                    '& .MuiFormLabel-root.MuiInputLabel-root': {
                        fontSize: '1.25rem',
                    },
                    '.MuiFormHelperText-root': {
                        fontSize: '0.875rem',
                    },
                },
            },
            defaultProps: {
                InputLabelProps: {
                    shrink: true,
                },
            },
        },
        TimePicker: {
            styleOverrides: {
                root: {
                    '& .MuiFormLabel-root.MuiInputLabel-root': {
                        fontSize: '1.25rem',
                    },
                    '.MuiFormHelperText-root': {
                        fontSize: '0.875rem',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => (Object.assign(Object.assign(Object.assign({}, (ownerState.variant === 'contained'
                    && {
                        backgroundColor: theme.palette[ownerState.color].main,
                        color: (ownerState.color === 'warning' || ownerState.color === 'neutral') ? theme.palette[ownerState.color].contrastText : theme.palette[ownerState.color].light,
                        '&:hover': {
                            backgroundColor: theme.palette[ownerState.color].light,
                            color: (ownerState.color === 'warning' || ownerState.color === 'neutral') ? theme.palette[ownerState.color].contrastText : theme.palette[ownerState.color].main,
                        },
                    })), (ownerState.variant === 'text'
                    && {
                        color: (ownerState.color === 'warning' || ownerState.color === 'neutral') ? theme.palette[ownerState.color].contrastText : theme.palette[ownerState.color].main,
                        boxShadow: 'none',
                    })), (ownerState.variant === 'outlined'
                    && {
                        borderColor: theme.palette[ownerState.color].main,
                        color: (ownerState.color === 'warning' || ownerState.color === 'neutral') ? theme.palette[ownerState.color].contrastText : theme.palette[ownerState.color].main,
                        border: `solid 1px ${theme.palette[ownerState.color].main}`,
                        '&:hover': {
                            backgroundColor: theme.palette[ownerState.color].light,
                            color: theme.palette[ownerState.color].main,
                        },
                    }))),
            },
        },
        DialogTitle: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                }),
            },
        },
    },
}));
const PortalDarkTheme = createTheme(deepmerge(defaultDarkTheme, {
    palette: {
        customBackgrounds: {
            main: '#ffffff',
            light: '#ffffff',
            dark: '#45464A',
        },
        senderColors: {
            main: 'rgba(255, 255, 255, 0.77)',
            light: 'rgba(0, 0, 0, 0.87)',
            dark: 'initial',
        },
        receiverColors: {
            main: '#8d9fd4',
            light: 'rgba(0, 0, 0, 0.87)',
            dark: 'initial',
        },
    },
}));
export { PortalLightTheme, PortalDarkTheme, };
