/**
 * Collection of object-related functions
 *
 */
import { ValueOf } from '@powerednow/type-definitions/index';
import {
    find,
    partialRight,
    merge,
    isString,
    isArray,
    isDate,
    transform,
    isObject,
    isEmpty,
    mapValues,
    cloneDeep,
} from 'lodash';

import objectHash from 'object-hash';
import converter from './converter';

export function hash(object) {
    return objectHash(object);
}
export function getKeyByValue(object, value: string) {
    if (typeof object !== 'object' || object === null) {
        return null;
    }
    return Object.keys(object).find(key => object[key] === value);
}

export function cloneDeepSafe(model) {
    if (typeof structuredClone === 'function') {
        return structuredClone(model);
    } 
    return cloneDeep(model);
}

export function getKeysByValue(object, value: string) {
    if (typeof object !== 'object' || object === null) {
        return [];
    }
    return Object.keys(object).filter(key => object[key] === value);
}

export function removePathFromKeyName(object, splitter = '.') {
    return Object.keys(object).reduce((previous, key) => {
        const newKey = key.split(splitter)
            .filter(item => item !== '')
            .pop() || key;
        return Object.assign(previous, { [newKey]: object[key] });
    }, {});
}

/**
 * assigns a property to an Object
 * @private
 */
export function _assignToObject(objectToAssign, key, value) {
    return Object.assign(objectToAssign, {
        [key]: value,
    });
}

export function translatePropertyNames(nameMap = {}, data = {}) {
    return Object.keys(data).reduce((newObj, dataKey) => {
        const newKeys = getKeysByValue(nameMap, dataKey);
        if (newKeys.length) {
            newKeys.forEach(newKey => {
                _assignToObject(newObj, newKey, data[dataKey]);
            });
        } else {
            _assignToObject(newObj, dataKey, data[dataKey]);
        }
        return newObj;
    }, {});
}

/**
 * Flatten object
 * source: https://gist.github.com/gdibble/9e0f34f0bb8a9cf2be43
 */
export function flatten(object) {
    const toReturn = {};
    let flatObject;
    Object.keys(object).forEach(i => {
        if (object[i] && (typeof object[i]) === 'object') {
            flatObject = flatten(object[i]);
            Object.keys(flatObject).forEach(x => {
                toReturn[`${i}.${x}`] = flatObject[x];
            });
        } else {
            toReturn[i] = object[i];
        }
    });
    return toReturn;
}

export function pickPropertyNamesByPaths(map, data) {
    const mapEntries = Object.keys(map).map(key => [key, map[key]]);
    const flatData = flatten(data);

    return mapEntries.reduce((result, [key, value]) => Object.assign(result, {
        [key]: flatData[value] !== undefined ? flatData[value] : null,
    }), {});
}

export function _isPrimitive(test) {
    return (test !== Object(test));
}

export function _instanceofAny(property, constructors) {
    return constructors.some(c => property instanceof c);
}

/**
 * Replace key name recursively by given pattern in nested object
 */
export function replacePropertyNames(object, {
    from = /\./g, to = '_', parentObjects = new Set(), useToJSON = false,
} = {}) {
    let data = object;
    if (useToJSON && data.toJSON) {
        data = data.toJSON();
    }
    return Object.keys(data).reduce((accumulator, property) => {
        const sanitizedProperty = property.match(from)
            ? property.replace(from, to)
            : property;

        if (!_isPrimitive(data[property])) {
            if (parentObjects.has(data[property])) {
                return Object.assign(accumulator, { [sanitizedProperty]: '[circular]' });
            }
        }

        return Object.assign(accumulator, {
            [sanitizedProperty]: (data[property] instanceof Object && !_instanceofAny(data[property], [Error, Date]))
                ? replacePropertyNames(data[property], {
                    from,
                    to,
                    useToJSON,
                    parentObjects: new Set([...parentObjects, data]),
                })
                : data[property],
        });
    }, Array.isArray(data) ? [] : {});
}

/**
 * Translate object to array where the elements of the new array
 * are objects where the original property name is the value of the
 * property with `newKeyName` of the new elements.
 * see test to understand :)
 */
export function translatePropertiesToArrayElements(data, newKeyName = 'A_Y-m', forced = false) {
    return Object.keys(data)
        .map(key => {
            if (!forced && typeof data[key][newKeyName] !== 'undefined') {
                throw new Error(`Key already exists in object: ${newKeyName}`);
            }
            return Object.assign(data[key], { [newKeyName]: key });
        });
}

export function filterObjectByProperties(object, properties) {
    return properties.reduce((filtered, key) => Object.assign(filtered, { [key]: object[key] }), {});
}

export function prefixAddToObjectProperty(object, prefix, property, separator = '') {
    const value = object[property] ? object[property] : '';
    const newValue = `${prefix}${separator}${value}`;
    return Object.assign(object, { [property]: newValue });
}

/**
 * Inverse the key-value pairs for an Object
 */
export function inverseObjectKeyValue(objectForInverse) {
    return Object.keys(objectForInverse).reduce((inversedObject, key) => _assignToObject(inversedObject, objectForInverse[key], converter.toNumberIfPossible(key)), {});
}

export function getFilterValue(filters, property, operator = '=') {
    const filter = find(filters, {
        property,
        operator,
    });
    return (filter && filter.value) || null;
}

/**
 * ex.: { a:1, b:2 } -> ['a', 'b']
 */
export function getHeaders(object) {
    return Object.keys(object).reduce((prev, property) => [...prev, property], []);
}

/**
 * ex.: { a:1, b:2 } -> [1, 2]
 */
export function getDataToArray(object) {
    return Object.keys(object).reduce((prev, property) => [...prev, object[property]], []);
}

export function getValuesIncludingSymbolProperties(object, excludedSymbols = []) {
    const normalValues = Object.values(object);

    return Object.getOwnPropertySymbols(object)
        .reduce((accumulatedValues, symbolKey) => {
            if (!excludedSymbols.includes(symbolKey)) {
                accumulatedValues.push(object[symbolKey]);
            }
            return accumulatedValues;
        }, normalValues);
}

export function entriesWithSymbols(object, excludedSymbols = []): [string | number | symbol, any][] {
    const normalEntries = Object.entries(object);
    return Object.getOwnPropertySymbols(object)
        .reduce((accumulatedEntries, symbolKey) => {
            if (!excludedSymbols.includes(symbolKey)) {
                accumulatedEntries.push([symbolKey as any, object[symbolKey]]);
            }
            return accumulatedEntries;
        }, normalEntries);
}

export function defaultsDeep() {
    partialRight(merge, function deep(value, other) {
        if (isString(value) || isArray(value) || isDate(other)) {
            return value;
        }
        return merge(value, other, deep);
    });
}

export function copyRenamedProperties(propertyNameMap, source, destination) {
    Object.keys(propertyNameMap).forEach(fieldKey => {
        if (source[fieldKey]) {
            Object.assign(destination, {
                [propertyNameMap[fieldKey]]: source[fieldKey],
            });
        }
    });
}

export function renameProperty(object, oldKey, newKey) {
    if (oldKey !== newKey) {
        Object.defineProperty(
            object, 
            newKey,
            Object.getOwnPropertyDescriptor(object, oldKey),
        );
        delete object[oldKey];
    }
}

export function deletePropertiesDeeply(iterable, deleteKeys) {
    if (_isPrimitive(iterable)) {
        return;
    }

    Object.keys(iterable)
        .forEach(key => {
            if (deleteKeys.includes(key)) {
                // eslint-disable-next-line
                delete iterable[key];
            } else {
                deletePropertiesDeeply(iterable[key], deleteKeys);
            }
        });
}

export function errorToJSON(error) {
    const errorObject = Object.getOwnPropertyNames(error).reduce((object, propertyName) => Object.assign(object, { [propertyName]: error[propertyName] }), {});
    return JSON.parse(JSON.stringify(errorObject));
}

export type SingleReplacer<T> = {
    destinationKey: string
    handler: (value: any, replacerItem?: SingleReplacer<T>, item?: T) => any
    selector?: (value: any, item: T) => any
    key?: string
}

export type ReplacerDefinition<T> = {
    [key in Exclude<keyof T, 'constructor'>]?: Array<SingleReplacer<T>>
}

export function extractDeepProperties<T extends Record<string, any>>(
    item: T,
    replacers: ReplacerDefinition<T> = {},
    keepOriginal: boolean = false,
) {
    return Object.entries(item).reduce((flattenedItem, [key, value]) => {
        const replacerItems = replacers[key];
        if (replacerItems) {
            replacerItems.forEach(replacerItem => {
                const newValue = replacerItem.handler(value, replacerItem, item);
                Object.assign(flattenedItem, {
                    [replacerItem.destinationKey || key]: newValue,
                });
            });
        }
        if (!replacerItems || keepOriginal) {
            Object.assign(flattenedItem, {
                [key]: value,
            });
        }
        return flattenedItem;
    }, {});
}

export function replaceKeysDeep(obj, keysMap) { // keysMap = { oldKey1: newKey1, oldKey2: newKey2, etc...
    return transform(obj, (result, value, key) => { // transform to a new object
        const currentKey = keysMap[key] || key; // if the key is in keysMap use the replacement, if not use the original key

        result[currentKey] = isObject(value) ? replaceKeysDeep(value, keysMap) : value; // if the key is an object run it through the inner function - replaceKeys
    });
}

export function removeSymbolKeysFromObject(object) {
    Object.getOwnPropertySymbols(object).forEach(key => {
        delete object[key];
    });
    return object;
}

export function getCircularReplacer() {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return null;
            }
            seen.add(value);
        }
        return value;
    };
}

export function isNonEmpty<T>(object: T | {}): object is T {
    return !isEmpty(object);
}

export function hasEnumerable(target, key): boolean {
    const ownPropertyDescriptor = Object.getOwnPropertyDescriptor(target, key);
    return Boolean(ownPropertyDescriptor && ownPropertyDescriptor.enumerable);
}

export function resolvePlaceholders(target, placeholders) {
    return mapValues(target || {}, value => {
        const resolver = placeholders[`${value}`];
        if (resolver) {
            return typeof resolver === 'function' ? resolver() : resolver;
        }

        return value;
    });
}

export function getGetterReference(target, getter, filter = (_v: any) => true) {
    if (target === null) {
        return null;
    }
    const getterReference = Object.getOwnPropertyDescriptor(target, getter)?.get;
    if (!getterReference || (getterReference && !filter(getterReference))) {
        return getGetterReference(Object.getPrototypeOf(target), getter, filter);
    }
    return getterReference;
}

export function isPlainObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
}

export function replaceValues(target, replacer = v => v) {
    return Object.keys(target).reduce((acc, key) => {
        const value = target[key];
        if (isPlainObject(value)) {
            acc[key] = replaceValues(value, replacer);
        } else {
            acc[key] = replacer(value);
        }
        return acc;
    }, {});
}
