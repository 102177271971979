import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import AppointmentRoutes, { baseRoute as appointmentBase } from '@pages/customerPortal/appointment/appointmentRoutes';
import AccountRoutes, { baseRoute as accountBase } from '@pages/customerPortal/account/accountRoutes';
import DocumentRoutes, { baseRoute as documentBase } from '@pages/customerPortal/document/documentRoutes';
import PaymentRoutes, { baseRoute as paymentBase } from '@pages/customerPortal/payment/paymentRoutes';
import FormRoutes, { baseRoute as formBase } from '@pages/customerPortal/form/formRoutes';
import MessageRoutes, { baseRoute as messageBase } from '@pages/customerPortal/message/messageRoutes';
import CustomerHome from '@pages/customerPortal/customerHome/customerHome';
import MESSAGES from '@powerednow/shared/constants/messages';

import Header from '@features/header/header';
import Footer from 'src/features/footer/footer';
import BasePage from '@components/BasePage/BasePage';
import SecondaryAction from '@features/secondaryActions/secondaryActions';
import FAQ from '@features/faq';
import { isPortalAvailable, hasFullPortalAccess } from '@data/state/subscription';
import DefaultMessages from '@powerednow/shared/constants/customerEmailTemplateValues';
import { AuthData } from '@data/state/auth';
import NotFound from '@components/NotFound/NotFound';
import RequireFeature from 'src/features/permission/requireFeature';

export const mainRoute = '/portal';
export const baseRoute = `${mainRoute}/:portalId`;

export function getRedirectPath(authData: AuthData) {
    if (typeof authData?.data?.type === 'undefined' || authData.data.type === null) {
        return null;
    }
    //
    // If a direct message and we got the message id then show the message page
    //
    if (authData.data.messageType === DefaultMessages.DIRECT_MESSAGE.ID && authData.data.messageId) {
        return `${mainRoute}/${authData.portalId}/${messageBase}/${authData.data.messageId}/view`;
    }
    //
    // Otherwise find out based on the linked item type what do we need to display
    //
    const typeUrl = {
        [MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.DOCUMENT]: documentBase,
        [MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.APPOINTMENT]: appointmentBase,
        [MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.FORMDOCUMENT]: formBase,
        [MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.PAYMENT]: paymentBase,
        [MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.MESSAGE]: messageBase,
    }[authData.data.type];
    return `${mainRoute}/${authData.portalId}/${typeUrl}/${authData.data.linkedId}/view`;
}

export default function CustomerPortalRoutes() {
    return (
        <BasePage pb={10} pt={10} containerMaxWidth="lg" paperElevation={2}>
            <RequireFeature feature={hasFullPortalAccess}>
                <Header />
            </RequireFeature>
            <Routes>
                <Route element={(
                    <RequireFeature feature={isPortalAvailable} fallbackComponent={<NotFound />}>
                        <Outlet />
                    </RequireFeature>
                )}
                >
                    <Route path={`${accountBase}/*`} element={<AccountRoutes />} />
                    <Route path={`${messageBase}/*`} element={<MessageRoutes />} />
                </Route>
                <Route path={`${appointmentBase}/*`} element={<AppointmentRoutes />} />
                <Route path={`${paymentBase}/*`} element={<PaymentRoutes />} />
                <Route path={`${documentBase}/*`} element={<DocumentRoutes />} />
                <Route path={`${formBase}/*`} element={<FormRoutes />} />
                <Route path="/" element={<CustomerHome />} />
            </Routes>
            <RequireFeature feature={hasFullPortalAccess}>
                <>
                    <SecondaryAction />
                    <FAQ />
                </>
            </RequireFeature>
            <Footer />
        </BasePage>
    );
}
