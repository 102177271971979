import React from 'react';
import { Box, Typography } from '@mui/material';
import UserPager from '@features/user/userPager';
import ComponentMatrix from '@components/layout/ComponentMatrix';
import useComplexData from '@data/hooks/complexDataHook';
import { useResponsive } from '@data/hooks/responsive';
import { CircularProgress } from '@components/progress/Progress';
import { authState } from '@data/state/auth';
import { useRecoilValue } from 'recoil';
import DownloadICS from '@app/connection/icsGenerator';
import ItemDetailSection from '@components/ItemInformations/itemDetailSection';
import ItemActionSection from '@components/ItemInformations/itemActionSection';
import DocumentContainer from '@features/document/DocumentContainer';
import { useNavigateAppointmentMessage } from '@data/hooks/navigationHook';
import ActionButton from '@components/button/ActionButton';
import { getFormattedDate } from '@powerednow/portal/src/helper/date';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import PortalSendMessageButton from '@features/buttons/PortalSendMessageButton';
import { patterns } from '@powerednow/shared/modules/utilities/date';
import htmlDecode from '@components/helper/htmlDecode';
import { ComplexModelEntity } from '@powerednow/models/modelLookup';

type UserDataType = ComplexModelEntity<'User'>;

type ActionIdProp = {
    actionId: number;
}

type AppointmentBlockProps = ActionIdProp & {
    dt_start?: string;
    action?: string;
    description?: string;
};

type AppointmentDetailsProps = {
    description?: string,
};

const useStyles = makeStyles<Theme>(() => createStyles({
    appointmentDetails: {
        wordBreak: 'break-word',
        fontWeight: 'bold',
    },
}));

const useAppointment = appointmentId => {
    const { details, optionalResult } = useComplexData(
        'Action',
        appointmentId,
        async (complexAppointment, resultSetter: (_newResult:{ users: UserDataType[] })=>void) => {
            const complexUsers = await complexAppointment.getAssignedUsers();
            const users = complexUsers.map(user => user.data.getPureDataValues());
            resultSetter({ users });
        },
    );
    return {
        appointmentData: details,
        allocatedUsers: optionalResult?.users,
    };
};

function AppointmentDetails(props: AppointmentDetailsProps) {
    const { description } = props;
    if (!description) {
        return null;
    }

    return <ItemDetailSection title="Details:" text={description} display="block" />;
}

function AppointmentActions(props: ActionIdProp) {
    const { actionId } = props;
    const responsive = useResponsive();
    const authData = useRecoilValue(authState);
    const { navigateHandler, loaded } = useNavigateAppointmentMessage(actionId);
    return (
        <ItemActionSection>
            <ComponentMatrix
                spacing={4}
                columns={responsive({ xs: 1, md: 2 })}
                mt={-1}
            >
                <PortalSendMessageButton onClick={navigateHandler} loaded={loaded} />
                <ActionButton fullWidth onClick={() => DownloadICS(authData, actionId)}>
                    Add to your calendar
                </ActionButton>
            </ComponentMatrix>
        </ItemActionSection>
    );
}

function AppointmentBlock(props: AppointmentBlockProps) {
    const {
        dt_start, actionId, action, description, 
    } = props;
    const responsive = useResponsive();
    const formattedDate = getFormattedDate(dt_start, patterns.shortDateWithTime);
    const appointmentTitleText = formattedDate && `Your appointment is on ${formattedDate}`;
    const classes = useStyles();

    return (
        <Box
            display="flex"
            flex={responsive({ sm: 2, md: 5 })}
            mb={8}
            mr={8}
            flexDirection="column"
        >
            <Typography variant="h4">
                <Box className={classes.appointmentDetails}>
                    {appointmentTitleText}
                    {appointmentTitleText && <br />}
                    {htmlDecode(action)}
                </Box>
            </Typography>
            <AppointmentDetails description={description} />
            <AppointmentActions actionId={actionId} />
        </Box>
    );
}

export default function AppointmentNotification(props: ActionIdProp) {
    const responsive = useResponsive();
    const { actionId } = props;
    const { appointmentData, allocatedUsers } = useAppointment(actionId);

    if (!appointmentData || !allocatedUsers) {
        return <CircularProgress />;
    }

    return (
        <DocumentContainer>
            <AppointmentBlock
                actionId={actionId}
                action={appointmentData.action}
                dt_start={appointmentData.dt_start as string}
                description={appointmentData.description}
            />
            <UserPager
                flex={responsive({ sm: 1, md: 3 })}
                ml={responsive({ xs: 'auto', sm: 'auto', md: 8 })}
                mr={responsive({ xs: 'auto', sm: 'auto', md: 'inherit' })}
                users={allocatedUsers}
                actionId={actionId}
            />
        </DocumentContainer>
    );
}
