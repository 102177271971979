import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSimpleComplexData } from '@data/hooks/complexDataHook';
import MenuButton from '@components/menu/MenuButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { omit } from 'lodash';
import { useResponsive } from '@data/hooks/responsive';
import AvailableAccountList from '@features/auth/availableAccountList/availableAccountList';
import { CircularProgress } from '@components/progress/Progress';
import TokenStorage from 'src/app/data/tokenStorage';

interface Props extends BoxProps {
    companyId: number,
}

const useStyles = makeStyles<Theme>(theme => createStyles({
    frame: {
        display: 'flex',
        flexGrow: 0,
    },
}));

interface FrameProps {
    children?: ReactNode,
    boxProps: BoxProps,
}

function Frame(props: FrameProps) {
    const { children, boxProps } = props;
    const classes = useStyles();
    const responsive = useResponsive();

    return (
        <Box
            className={classes.frame}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...omit(boxProps, ['companyId'])}
            flexDirection={responsive({ xs: 'row', sm: 'row' })}
        >
            {children}
        </Box>
    );
}

export default function CompanyHeader(props: Props) {
    const { companyId } = props;
    const companyDetails = useSimpleComplexData('Company', companyId);
    const loginPortalIds = TokenStorage.getPortalIds();
    const responsive = useResponsive();

    if (!companyDetails) {
        return <CircularProgress />;
    }

    if (loginPortalIds.length > 1) {
        return (
            <Frame boxProps={props}>
                <MenuButton
                    maxWidth={responsive({
                        xs: 120, sm: 300, md: 350, lg: 450, 
                    })}
                    size="medium"
                    emphasis="medium"
                    endIcon={<ExpandMoreIcon />}
                    text={companyDetails?.name}
                >
                    <AvailableAccountList selectedCompanyId={companyDetails?.id} />
                </MenuButton>
            </Frame>
        );
    }

    return (
        <Frame boxProps={props} />
    );
}
