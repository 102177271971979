import React from 'react';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useResponsive } from '@data/hooks/responsive';
import PageBlock from '@components/layout/PageBlock';
import { SimpleTab } from '@components/layout/Tabs';
import AppointmentsList from '@features/appointment/list/appointmentsList';
import DocumentsList from '@features/document/list/documentsList';
import MessagesList from '@features/message/list/messagesList';
import PaymentList from '@features/payment/list/paymentList';
import FilesList from '@features/file/list/filesList';
import FormList from '@features/form/list/formList';
import { useRecoilValue } from 'recoil';
import { TABS, selectedTab } from '@data/state/selectedTab';
import { displayProjectFilesTabInThePortal } from '@data/state/companySettings';

const useStyles = makeStyles<Theme>(theme => createStyles({
    closeButton: {
        position: 'absolute',
        right: '10px',
        top: '10px',
    },
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.customBackgrounds?.light,
    },
    dialogTitleContent: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    helpButton: {
        position: 'absolute',
        top: -15,
        right: -15,
        borderRadius: '50%',
        cursor: 'pointer',
        backgroundColor: theme.palette.mode === 'light' ? 'white' : 'black',
        color: theme.palette.mode === 'light' ? 'black' : 'white',
    },
    simpleTabContainer: {
        flex: 1,
        display: 'flex',
        position: 'relative',
        flexDirection: 'row',
    },
}));

export type TabElement = {
    index: number,
    name: string,
    element: JSX.Element,
}

export const secondaryTabsObject: Record<string, TabElement> = {
    AppointmentsList: {
        index: TABS.Appointment,
        name: 'Appointments',
        element: <AppointmentsList />,
    },
    DocumentsList: {
        index: TABS.Document,
        name: 'Documents',
        element: <DocumentsList />,
    },
    FormList: {
        index: TABS.Form,
        name: 'Forms/Certificates',
        element: <FormList />,
    },
    PaymentList: {
        index: TABS.Payment,
        name: 'Payments',
        element: <PaymentList />,
    },
    MessagesList: {
        index: TABS.Message,
        name: 'Messages',
        element: <MessagesList />,
    },
};

export default function SecondaryAction() {
    const classes = useStyles();
    const responsive = useResponsive();
    const tabIndexValue = useRecoilValue(selectedTab);
    const displayProjectFilesTabInThePortalValue = useRecoilValue(displayProjectFilesTabInThePortal);

    if (displayProjectFilesTabInThePortalValue) {
        secondaryTabsObject.FilesList = {
            index: TABS.File,
            name: 'Files',
            element: <FilesList />,
        };
    }

    const panels = Object.values(secondaryTabsObject).map(tab => ({
        label: tab.name,
        panel: tab.element,
        order: tab.index,
    }));

    return (
        <PageBlock
            neutral
            pt={0}
            pr={0}
            pl={0}
            pb={0}
        >
            <Box
                display="flex"
                flexDirection={responsive({ xs: 'column', md: 'row' })}
            >
                <Box className={classes.simpleTabContainer}>
                    <SimpleTab
                        value={tabIndexValue}
                        color="primary"
                        elevation={4}
                        panels={panels}
                    />
                </Box>
            </Box>
        </PageBlock>
    );
}
