import { jsx as _jsx } from "react/jsx-runtime";
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';
import Mask from './Mask';
import Progress from '../progress/Progress';
const useStyles = makeStyles(theme => ({
    root(props) {
        const { fullWidth } = props;
        return Object.assign(Object.assign({}, (fullWidth ? {
            flex: '1 1 auto',
            display: 'flex',
        } : {
            display: 'inline-block',
        })), { position: 'relative', minWidth: 0, minHeight: 0 });
    },
    maskStyle: {
        width: '100%',
        height: '100%',
    },
}));
export default function ComponentMask({ children, showMask, color, fullWidth, }) {
    const classes = useStyles({
        fullWidth,
    });
    return (_jsx(Box, { className: classes.root, children: _jsx(Mask, { className: classes.maskStyle, sx: { flex: 1 }, blur: 2, backgroundColor: "white", opacity: 0.5, show: showMask, content: (_jsx(Progress, { type: "circular", size: "large", color: color })), children: children }) }));
}
