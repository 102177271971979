import React from 'react';
import Pagination from '@components/pagination/Pagination';
import { SimpleTab } from '@components/layout/Tabs';
import UserDetails from '@features/user/userDetails';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { pagerIndex } from '@data/state/pagers';
import { useRecoilState } from 'recoil';
import { Box, BoxProps } from '@mui/material';
import { useResponsive } from '@data/hooks/responsive';

type Props = BoxProps & {
    users: Record<string, any>[],
    actionId: number,
}

const useStyles = makeStyles<Theme>(theme => createStyles({
    userDetails: {
        backgroundColor: theme.palette.neutral.main,
    },
    pagination: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    engineerDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 330,
        flex: 1,
    },
    paginationContainer: {
        display: 'flex',
    },
}));

export default function UserPager({ users, actionId }: Props) {
    const responsive = useResponsive();
    const classes = useStyles();
    const [defaultIndex, setDefaultIndex] = useRecoilState(pagerIndex({ name: 'UserPager', id: actionId }));
    const areMultipleUsersAssigned = users.length > 1;
    const selectedUserId = (users[Math.min(defaultIndex, users.length) - 1] || {}).id;
    const panels = [
        {
            label: 'Who will visit',
            panel: <UserDetails
                className={classes.userDetails}
                userId={selectedUserId}
                m={-3}
            />,
        },
    ];

    const handlePageChange = (event, value) => {
        setDefaultIndex(value);
    };

    const pager = (
        <Box
            className={classes.pagination}
            px={4}
            py={2}
        >
            <Pagination
                size="small"
                count={users.length}
                page={defaultIndex}
                onChange={handlePageChange}
                justifyContent={responsive({ xs: 'center', md: 'flex-end' })}
            />
        </Box>
    );

    return (
        <Box
            className={classes.engineerDetails}
            mt={6}
            minWidth={responsive({ xs: '80%', sm: 400 })}
        >
            <SimpleTab
                color="primary"
                value={0}
                position="top"
                panels={panels}
            />
            <Box className={classes.paginationContainer}>
                {areMultipleUsersAssigned && pager}
            </Box>
        </Box>
    );
}
