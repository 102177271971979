import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useRecoilState, atom, useSetRecoilState } from 'recoil';
const Alert = React.forwardRef(({ onClose, severity, children }, ref) => (_jsx(MuiAlert, { elevation: 6, variant: "filled", onClose: onClose, severity: severity, ref: ref, children: children })));
export const toastAtom = atom({
    key: 'toast',
    default: {
        message: '',
    },
});
export function useToast() {
    const setToast = useSetRecoilState(toastAtom);
    return {
        setToast,
        warning(toastMessage) { setToast({ message: toastMessage, severity: 'warning' }); },
        error(toastMessage) { setToast({ message: toastMessage, severity: 'error' }); },
        success(toastMessage) { setToast({ message: toastMessage, severity: 'success' }); },
        info(toastMessage) { setToast({ message: toastMessage, severity: 'info' }); },
    };
}
export default function Toast() {
    const [toastDetails, setToastDetails] = useRecoilState(toastAtom);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastDetails(Object.assign(Object.assign({}, toastDetails), { message: '' }));
    };
    return (_jsx(Snackbar, { open: toastDetails.message !== '', autoHideDuration: 6000, onClose: handleClose, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, children: _jsx(Alert, { onClose: handleClose, severity: (toastDetails === null || toastDetails === void 0 ? void 0 : toastDetails.severity) || 'success', children: toastDetails.message }) }));
}
