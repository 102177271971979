import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Button from '../button/Button';
import ColoredButton from '../button/ColoredButton';
const errorMessages = {
    NetworkError: 'There was an error while communicating with the server. \nPlease try again.',
};
export default function TryAgainFallback({ error, resetErrorBoundary }) {
    return (_jsxs(Box, { role: "alert", children: [_jsx("p", { children: "Something went wrong:" }), _jsx("pre", { children: error.message }), _jsx(Button, { onClick: resetErrorBoundary, children: "Try again" })] }));
}
export function TryAgainFallbackModal({ error, resetErrorBoundary }) {
    return (_jsxs(Dialog, { open: true, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", children: [_jsx(DialogTitle, { id: "alert-dialog-title", children: "Something went wrong" }), _jsx(DialogContent, { children: _jsx(DialogContentText, { id: "alert-dialog-description", children: errorMessages[error.name] || error.message }) }), _jsx(DialogActions, { children: _jsx(ColoredButton, { onClick: resetErrorBoundary, color: "primary", autoFocus: true, children: "Close error" }) })] }));
}
