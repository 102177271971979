import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled, useThemeProps } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MenuItem, Select as MUISelect, FormControl, InputLabel, FormHelperText, Box, } from '@mui/material';
import clsx from 'clsx';
import { omit } from 'lodash';
import htmlDecode from '../helper/htmlDecode';
const getFontSizes = (theme, size) => ({
    '&> label': {
        fontSize: theme.spacing(size * 1.25),
    },
    '&> div > .MuiInput-input': {
        fontSize: theme.spacing(size),
    },
});
const labelPositions = {
    top: {
        alignItems: 'start',
        flexDirection: 'column',
    },
    bottom: {
        alignItems: 'start',
        flexDirection: 'column-reverse',
    },
    left: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: '5px',
    },
    right: {
        flexDirection: 'row-reverse',
        justifyContent: 'start',
        alignItems: 'center',
    },
};
const useStyles = makeStyles(theme => createStyles({
    small: getFontSizes(theme, 3.5),
    medium: getFontSizes(theme, 4),
    formControl: {
        minWidth: 120,
        [theme.breakpoints.between('xs', 'lg')]: {
            maxWidth: 300,
        },
        [theme.breakpoints.between('sm', 'xl')]: {
            maxWidth: 600,
        },
        [theme.breakpoints.between('lg', 'xl')]: {
            maxWidth: 900,
        },
    },
    menuPaper: {
        maxHeight: 300,
    },
    menuList: {
        '&>.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
        },
        '&>.Mui-focusVisible': {
            color: theme.palette.primary.main,
        },
    },
    labelPosition: ({ labelPosition }) => (Object.assign(Object.assign({}, (labelPosition in labelPositions ? Object.assign({}, labelPositions[labelPosition]) : Object.assign({}, labelPositions.top))), { display: 'flex' })),
}));
const SelectRoot = styled(MUISelect, {
    name: 'Select',
    slot: 'root',
})(() => ({}));
export default function Select(inProps) {
    const props = useThemeProps({ props: inProps, name: 'Select' });
    const { label, size, focused, items, error, helperText, className, valueProperty = 'id', displayProperty = 'text', labelPosition = 'top', variant = 'standard', shrink = false, } = props;
    const classes = useStyles({ labelPosition });
    const sizeClass = classes[size || 'medium'];
    const controlProps = {
        className: clsx(classes.formControl, className, sizeClass),
        style: {
            minWidth: 120,
            maxHeight: 200,
        },
        size,
        focused,
        variant,
    };
    const inputProps = Object.assign({}, omit(props, 'className', 'label', 'helperText', 'displayProperty', 'valueProperty', 'labelPosition'));
    const containerProps = {
        className: clsx(classes.labelPosition, className),
        size,
        focused,
    };
    const renderItems = () => {
        if (typeof items === 'undefined')
            return null;
        if (Array.isArray(items)) {
            return items.map((item, key) => (_jsx(MenuItem, { value: item === null || item === void 0 ? void 0 : item[valueProperty], children: htmlDecode(item === null || item === void 0 ? void 0 : item[displayProperty]) }, `option_${key}`)));
        }
        if (typeof items === 'object') {
            return Object.keys(items).map(key => _jsx(MenuItem, { value: key, children: items === null || items === void 0 ? void 0 : items[key] }));
        }
        return null;
    };
    return (_jsxs(Box, Object.assign({}, containerProps, { children: [label && (_jsx(InputLabel, { shrink: shrink, error: error, children: label })), _jsxs(FormControl, Object.assign({}, controlProps, { children: [_jsx(SelectRoot, Object.assign({}, inputProps, { MenuProps: { classes: { paper: classes.menuPaper, list: classes.menuList } }, children: renderItems() })), helperText && _jsx(FormHelperText, { error: error, children: helperText })] }))] })));
}
