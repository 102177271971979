import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useController, useFormContext } from 'react-hook-form';
import { omit } from 'lodash';
import TextField from '../fields/TextField';
function ControlledTextField(_a) {
    var _b;
    var { name, type = 'text', rules } = _a, rest = __rest(_a, ["name", "type", "rules"]);
    const { control } = useFormContext();
    const { field: { onChange, onBlur, value, ref, name: controlledName, }, formState: { errors, }, } = useController({
        name,
        control,
        rules,
        defaultValue: '',
    });
    return (_jsx(TextField, Object.assign({ type: type, onBlur: onBlur, name: name, onChange: onChange, value: value, inputRef: ref, error: !!errors[name], helperText: (_b = errors[name]) === null || _b === void 0 ? void 0 : _b.message }, omit(rest, ['onChange', 'onBlur', 'value', 'ref', 'name']))));
}
export default ControlledTextField;
