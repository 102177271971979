import { Nullable } from '@powerednow/type-definitions';

export enum ConsumptionExtraType {
    users = 'users',
    documentCreation = 'documentCreation',
}

const consumableUserDefault = {
    type: ConsumptionExtraType.users,
    count: 1,
};

const consumableDocumentDefault = {
    type: ConsumptionExtraType.documentCreation,
    count: 2,
};

export enum PurchaseOrigin {
    braintree = null,
    ios = 1,
    android,
    chargebee,
    marketing_free,
}

// eslint-disable-next-line no-shadow
export enum SUBSCRIPTION_PLAN {
    Trial = 1,
    MarketingFree = 2,
    Expired = 3,
    OneTradeAppTrial = 9,
    OneTradeAppExpired = 20,
    StarterMonthly = 26,
    StarterYearly = 27,
    BusinessMonthly = 40,
    BusinessYearly = 46,
    ProfessionalMonthly = 44,
    ProfessionalYearly = 45,
    OneTradeAppProfessionalYearly = 47,
    PremiumMonthly = 49,
    PremiumYearly = 48,
    SfPremiumMonthly = 52,
    SfPremiumYearly = 53,
    SfProfessionalMonthly = 54,
    SfProfessionalYearly = 55,
    SfBusinessMonthly = 56,
    SfBusinessYearly = 57,
    SfTrial = 64,
    SfExpired = 65,
}

// eslint-disable-next-line no-shadow
export enum CONSUMABLE {
    ExtraUserProfessionalMonthly = 42,
    ExtraUserProfessionalYearly = 43,
    ExtraUserPremiumMonthly = 50,
    ExtraUserPremiumYearly = 51,
    SfExtraUserProfessionalMonthly = 58,
    SfExtraUserProfessionalYearly = 59,
    SfExtraUserPremiumMonthly = 60,
    SfExtraUserPremiumYearly = 61,
    SfExtraUserBusinessMonthly = 62,
    SfExtraUserBusinessYearly = 63,
}

export type ProductType = {
    recordId: number,
    id: {
        web: Nullable<string>,
        ios: Nullable<string>,
        android: Nullable<string>,
    },
    consumable?: boolean,
    cb_price: {
        decimal: number,
        currency: string,
    },
    type?: ConsumptionExtraType.users | ConsumptionExtraType.documentCreation
};

export const TIERS = {
    PN_DEFAULT: 1,
    PN_TIER1: 2,
    PN_TIER2: 3,
    PN_TIER3: 4,
    PN_TIER4: 5,
    PN_TRIAL: 6,
    '1TA_DEFAULT': 7,
    '1TA_TIER3': 8,
    '1TA_TRIAL': 9,
};

export const PRODUCT_TIER_TO_TEAM_SIZE = {
    1: TIERS.PN_TIER2,
    3: TIERS.PN_TIER3,
    6: TIERS.PN_TIER3,
    7: TIERS.PN_TIER4,
} as const;

export const WHITE_LABEL_IDS = {
    POWEREDNOW: 1,
    GRAHAM: 2,
    ONE_TRADE_APP: 3,
    SCREWFIX: 18,
};

export const WHITE_LABEL_TRIAL_MAP = {
    [WHITE_LABEL_IDS.POWEREDNOW]: SUBSCRIPTION_PLAN.Trial,
    [WHITE_LABEL_IDS.ONE_TRADE_APP]: SUBSCRIPTION_PLAN.OneTradeAppTrial,
    [WHITE_LABEL_IDS.SCREWFIX]: SUBSCRIPTION_PLAN.SfTrial,
};

export const WHITE_LABEL_EXPIRED_MAP = {
    [WHITE_LABEL_IDS.POWEREDNOW]: SUBSCRIPTION_PLAN.Expired,
    [WHITE_LABEL_IDS.ONE_TRADE_APP]: SUBSCRIPTION_PLAN.OneTradeAppExpired,
    [WHITE_LABEL_IDS.SCREWFIX]: SUBSCRIPTION_PLAN.SfExpired,
};

export const CALCULATED_FEATURES = {
    CAN_SEND_DOC: 'CAN_SEND_DOC',
    CAN_CREATE_DOC: 'CAN_CREATE_DOC',
    CAN_CREATE_CUSTOMER: 'CAN_CREATE_CUSTOMER',
    CAN_USE_POSTCODE: 'CAN_USE_POSTCODE',
    CAN_USE_SMS: 'CAN_USE_SMS',
    CAN_SEND_LIMITED_MESSAGE: 'CAN_SEND_LIMITED_MESSAGE',
};

export const FEATURES = {
    EMAIL_LIMIT: 1,
    SMS_LIMIT: 2,
    CAN_USE_SALES_DOCUMENTS: 3,
    CAN_USE_COST_DOCUMENTS: 4,
    DOCUMENT_CREATION_LIMIT: 5,
    CAN_CREATE_INVOICE: 6,
    CAN_CREATE_CREDITNOTE: 7,
    CAN_CREATE_QUOTE: 8,
    CAN_CREATE_EXPENSE: 9,
    CAN_CREATE_EXPENSE_CREDITNOTE: 10,
    CAN_USE_JOBSHEETS: 11,
    CAN_CREATE_SUPPLIER_INVOICE: 12,
    CAN_CREATE_SUPPLIER_INVOICE_CREDIT: 13,
    CAN_CREATE_PURCHASE_ORDER: 14,
    CAN_CREATE_SIGNATURE: 15,
    CUSTOMER_LIMIT: 16,
    DEVICE_SWITCH: 17,
    USER_CONCURRENT_LOGIN_LIMIT: 19,
    POSTCODE_LIMIT: 20,
    DOCUMENT_SEND_LIMIT: 21,
    CAN_USE_TEAM_FEATURES: 23,
    CAN_USE_COMPANY_LINKING: 24,
    CAN_USE_SUPPLIERS: 26,
    CAN_USE_CIS: 27,
    CAN_USE_PRODUCTS: 29,
    CAN_USE_SITES: 30,
    CAN_USE_FORMS: 31,
    CAN_USE_TRACKING: 32,
    CAN_USE_TAX_HISTORY: 33,
    CAN_USE_ACCOUNTING_INTEGRATION: 34,
    CAN_USE_DIARY: 35,
    CAN_USE_DIARY_SCHEDULE: 36,
    CAN_USE_REPLY_MESSAGES: 37,
    CAN_USE_PORTAL: 38,
    CAN_USE_PROJECTS: 39,
    CAN_USE_NOTES: 40,
    CAN_USE_AUTOCOMPLETE: 41,
    CAN_USE_MAPS: 42,
    CAN_USE_BUSINESS_PERFORMANCE_REPORT: 43,
    CAN_USE_CHART_REPORT: 44,
    CAN_USE_DOCUMENT_REPORT: 45,
    CAN_USE_ACCOUNT_EXPORT_REPORT: 46,
    CAN_USE_CIS_REPORT: 47,
    CAN_CHANGE_NOTIFICATION_SETTINGS: 48,
    CAN_CHANGE_LAYOUT_SETTINGS: 49,
    CAN_USE_COMPANY_LOOKUP: 50,
    CAN_MANAGE_PAYMENTS: 51,
    MAX_USERS: 52,
    CAN_USE_CONTACTS: 53,
    CAN_USE_REFERRAL_SCHEME: 54,
    CAN_USE_MAXZS_CHARTS: 55,
    CAN_USE_WORKFLOW: 57,
    CAN_EDIT_CHASED: 58,
    CAN_USE_DOCUMENT_REMINDERS: 59,
    CAN_USE_SKETCH: 60,
};

type ProductTypeKeys =
    'EXPIRED'
    | 'EXPIRED_NO_DOC'
    | 'TRIAL'
    | '1TA_TRIAL'
    | 'PN'
    | 'PN1Y'
    | 'PN_MU3'
    | 'PN_MU6'
    | 'PN_CONSUMABLE_USER_1Y'
    | 'PN_CONSUMABLE_USER_1MONTH'
    | 'PN_CONSUMABLE_USER_1YEAR'
    | 'PN_CONSUMABLE_USER_1M'
    | 'PN_CONSUMABLE_USER_2Y'
    | 'PN_CONSUMABLE_USER_2YEARS'
    | 'PN_CONSUMABLE_DOCUMENT_2'
    | 'PN_BUSINESS_1M'
    | 'PN_BUSINESS_1Y'
    | 'PN_BUSINESS_1Y_20170601'
    | 'PN_BUSINESS_2Y'
    | 'PN_TEAM_1M'
    | 'PN_TEAM_1Y'
    | 'PN_TEAM_1Y_20170601'
    | 'PN_TEAM_2Y'
    | 'PN_STARTER_1M'
    | 'PN_STARTER_1Y'
    | 'PN_STARTER_5DOC_1M'
    | 'PN_STARTER_5DOC_1Y'
    | 'PN_BUSINESS_NEW_1Y'
    | 'PN_BUSINESS_NEW_1M'
    | 'BUSINESS_1M_2019_08'
    | 'BUSINESS_1Y_2019_08'
    | 'BUSINESS_1Y_2019_11'
    | 'PN_PRO_NEW_1Y'
    | 'PN_PRO_NEW_1M'
    | 'PN_PRO_201910_1Y'
    | '1TA_PRO_1Y'
    | 'PN_PRO_201910_1M'
    | 'PN_CONSUMABLE_BUSINESS_EXTRA_USER_1Y'
    | 'PN_CONSUMABLE_BUSINESS_EXTRA_USER_1M'
    | 'PN_CONSUMABLE_PROFESSIONAL_EXTRA_USER_1Y'
    | 'PN_CONSUMABLE_PROFESSIONAL_EXTRA_USER_1M'
    | 'PN_CONSUMABLE_PREMIUM_EXTRA_USER_1MONTH'
    | 'PN_CONSUMABLE_PREMIUM_EXTRA_USER_1YEAR'
    | 'CONSUMABLE_EXTRA_USER_1M_201908'
    | 'CONSUMABLE_EXTRA_USER_1Y_201908'
    | 'PREMIUM_ANNUAL'
    | 'PREMIUM_MONTHLY'
    | 'SF_PREMIUM_MONTHLY'
    | 'SF_PREMIUM_ANNUAL'
    | 'SF_PRO_MONTHLY'
    | 'SF_PRO_ANNUAL'
    | 'SF_BUSINESS_MONTHLY'
    | 'SF_BUSINESS_ANNUAL'
    | 'SF_CONSUMABLE_PREMIUM_EXTRA_USER_MONTHLY'
    | 'SF_CONSUMABLE_PREMIUM_EXTRA_USER_ANNUAL'
    | 'SF_CONSUMABLE_PRO_EXTRA_USER_MONTHLY'
    | 'SF_CONSUMABLE_PRO_EXTRA_USER_ANNUAL'
    | 'SF_CONSUMABLE_BUSINESS_EXTRA_USER_MONTHLY'
    | 'SF_CONSUMABLE_BUSINESS_EXTRA_USER_ANNUAL'
    | 'SF_TRIAL'
    | 'SF_EXPIRED'

export const PERIOD_PAIRS: Partial<Record<ProductTypeKeys, ProductTypeKeys>> = {
    PN: 'PN1Y',
    PN1Y: 'PN',
    PN_STARTER_5DOC_1M: 'PN_STARTER_5DOC_1Y',
    PN_STARTER_5DOC_1Y: 'PN_STARTER_5DOC_1M',
    PN_BUSINESS_1M: 'PN_BUSINESS_1Y',
    PN_BUSINESS_1Y: 'PN_BUSINESS_1M',
    BUSINESS_1M_2019_08: 'BUSINESS_1Y_2019_11',
    BUSINESS_1Y_2019_11: 'BUSINESS_1M_2019_08',
    PN_TEAM_1M: 'PN_TEAM_1Y_20170601',
    PN_TEAM_1Y_20170601: 'PN_TEAM_1M',
    PN_PRO_NEW_1Y: 'PN_PRO_NEW_1M',
    PN_PRO_NEW_1M: 'PN_PRO_NEW_1Y',
    PN_PRO_201910_1M: 'PN_PRO_201910_1Y',
    PN_PRO_201910_1Y: 'PN_PRO_201910_1M',
    PREMIUM_MONTHLY: 'PREMIUM_ANNUAL',
    PREMIUM_ANNUAL: 'PREMIUM_MONTHLY',
    CONSUMABLE_EXTRA_USER_1M_201908: 'CONSUMABLE_EXTRA_USER_1Y_201908',
    CONSUMABLE_EXTRA_USER_1Y_201908: 'CONSUMABLE_EXTRA_USER_1M_201908',
    PN_CONSUMABLE_PREMIUM_EXTRA_USER_1MONTH: 'PN_CONSUMABLE_PREMIUM_EXTRA_USER_1MONTH',
    PN_CONSUMABLE_PREMIUM_EXTRA_USER_1YEAR: 'PN_CONSUMABLE_PREMIUM_EXTRA_USER_1YEAR',
    SF_PREMIUM_MONTHLY: 'SF_PREMIUM_ANNUAL',
    SF_PREMIUM_ANNUAL: 'SF_PREMIUM_MONTHLY',
    SF_PRO_MONTHLY: 'SF_PRO_ANNUAL',
    SF_PRO_ANNUAL: 'SF_PRO_MONTHLY',
    SF_BUSINESS_MONTHLY: 'SF_BUSINESS_ANNUAL',
    SF_BUSINESS_ANNUAL: 'SF_BUSINESS_MONTHLY',
    SF_CONSUMABLE_PREMIUM_EXTRA_USER_MONTHLY: 'SF_CONSUMABLE_PREMIUM_EXTRA_USER_ANNUAL',
    SF_CONSUMABLE_PREMIUM_EXTRA_USER_ANNUAL: 'SF_CONSUMABLE_PREMIUM_EXTRA_USER_MONTHLY',
    SF_CONSUMABLE_PRO_EXTRA_USER_MONTHLY: 'SF_CONSUMABLE_PRO_EXTRA_USER_ANNUAL',
    SF_CONSUMABLE_PRO_EXTRA_USER_ANNUAL: 'SF_CONSUMABLE_PRO_EXTRA_USER_MONTHLY',
    SF_CONSUMABLE_BUSINESS_EXTRA_USER_MONTHLY: 'SF_CONSUMABLE_BUSINESS_EXTRA_USER_ANNUAL',
    SF_CONSUMABLE_BUSINESS_EXTRA_USER_ANNUAL: 'SF_CONSUMABLE_BUSINESS_EXTRA_USER_MONTHLY',
};

export const PRODUCTS: Record<ProductTypeKeys, ProductType> = {
    EXPIRED: {
        recordId: SUBSCRIPTION_PLAN.Expired,
        id: {
            web: '',
            ios: '',
            android: '',
        },
        cb_price: {
            decimal: 0,
            currency: 'GBP',
        },
    },
    EXPIRED_NO_DOC: {
        recordId: 20,
        id: {
            web: '',
            ios: '',
            android: '',
        },
        cb_price: {
            decimal: 0,
            currency: 'GBP',
        },
    },
    TRIAL: {
        recordId: SUBSCRIPTION_PLAN.Trial,
        id: {
            web: '',
            ios: '',
            android: '',
        },
        cb_price: {
            decimal: 0,
            currency: 'GBP',
        },
    },
    '1TA_TRIAL': {
        recordId: SUBSCRIPTION_PLAN.OneTradeAppTrial,
        id: {
            web: '',
            ios: '',
            android: '',
        },
        cb_price: {
            decimal: 0,
            currency: 'GBP',
        },
    },
    PN: {
        recordId: 4,
        id: {
            web: 'PN',
            ios: 'PN',
            android: 'pn_monthly_1',
        },
        cb_price: {
            decimal: 4.00,
            currency: 'GBP',
        },
    },
    PN1Y: {
        recordId: 6,
        id: {
            web: 'PN1Y',
            ios: 'PN1Y',
            android: 'pn_annual',
        },
        cb_price: {
            decimal: 40.99,
            currency: 'GBP',
        },
    },
    PN_MU3: {
        recordId: 7,
        id: {
            web: 'PN_MU3',
            ios: 'PN_MU3',
            android: 'pn_mu3_monthly_1',
        },
        cb_price: {
            decimal: 28.79,
            currency: 'GBP',
        },
    },
    PN_MU6: {
        recordId: 8,
        id: {
            web: 'PN_MU6',
            ios: 'PN_MU6',
            android: 'pn_mu6_monthly_1',
        },
        cb_price: {
            decimal: 59.99,
            currency: 'GBP',
        },
    },
    PN_CONSUMABLE_USER_1Y: {
        recordId: 10,
        id: {
            web: 'PN_CONSUMABLE_USER_1Y',
            ios: 'PN_CONSUMABLE_USER_1Y',
            android: 'pn_consumable_user_1y',
        },
        consumable: true,
        cb_price: {
            decimal: 90.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    PN_CONSUMABLE_USER_1MONTH: {
        recordId: 20,
        id: {
            web: 'PN_CONSUMABLE_USER_1MONTH',
            ios: null,
            android: null,
        },
        consumable: true,
        cb_price: {
            decimal: 15.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    PN_CONSUMABLE_USER_1YEAR: {
        recordId: 25,
        id: {
            web: 'PN_CONSUMABLE_USER_1YEAR',
            ios: 'PN_CONSUMABLE_USER_1Y',
            android: '2017_9_professional_extra_user_annual',
        },
        consumable: true,
        cb_price: {
            decimal: 150.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    PN_CONSUMABLE_USER_1M: {
        recordId: 18,
        id: {
            web: 'PN_CONSUMABLE_USER_1M',
            ios: null,
            android: null,
        },
        consumable: true,
        cb_price: {
            decimal: 15.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    PN_CONSUMABLE_USER_2Y: {
        recordId: 19,
        id: {
            web: 'PN_CONSUMABLE_USER_2Y',
            ios: null,
            android: 'pn_consumable_user_2y',
        },
        consumable: true,
        cb_price: {
            decimal: 132.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    PN_CONSUMABLE_USER_2YEARS: {
        recordId: 24,
        id: {
            web: 'PN_CONSUMABLE_USER_2YEARS',
            ios: null,
            android: null,
        },
        consumable: true,
        cb_price: {
            decimal: 270.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    PN_CONSUMABLE_DOCUMENT_2: {
        recordId: 11,
        id: {
            web: 'PN_CONSUMABLE_DOCUMENT_2',
            ios: 'PN_CONSUMABLE_DOCUMENT_2',
            android: 'pn_consumable_document_2',
        },
        consumable: true,
        cb_price: {
            decimal: 0.00,
            currency: 'GBP',
        },
        ...consumableDocumentDefault,
    },
    PN_BUSINESS_1M: {
        recordId: 12,
        id: {
            web: 'PN_BUSINESS_1M',
            ios: 'PN_BUSINESS_1M',
            android: 'pn_business_1m_1',
        },
        cb_price: {
            decimal: 27.00,
            currency: 'GBP',
        },
    },
    PN_BUSINESS_1Y: {
        recordId: 13,
        id: {
            web: 'PN_BUSINESS_1Y',
            ios: 'PN_BUSINESS_1Y',
            android: 'pn_business_1y_1',
        },
        cb_price: {
            decimal: 270.00,
            currency: 'GBP',
        },
    },
    PN_BUSINESS_1Y_20170601: {
        recordId: 29,
        id: {
            web: 'PN_BUSINESS_1Y_20170601',
            ios: 'PN_BUSINESS_1Y_20170601',
            android: 'pn_business_1y_20170601',
        },
        cb_price: {
            decimal: 270.00,
            currency: 'GBP',
        },
    },
    PN_BUSINESS_2Y: {
        recordId: 16,
        id: {
            web: 'PN_BUSINESS_2Y',
            ios: null,
            android: 'pn_business_2y',
        },
        cb_price: {
            decimal: 166.00,
            currency: 'GBP',
        },
    },
    PN_TEAM_1M: {
        recordId: 14,
        id: {
            web: 'PN_TEAM_1M',
            ios: 'PN_TEAM_1M',
            android: 'pn_team_1m_1',
        },
        cb_price: {
            decimal: 37.00,
            currency: 'GBP',
        },
    },
    PN_TEAM_1Y: {
        recordId: 15,
        id: {
            web: 'PN_TEAM_1Y',
            ios: 'PN_TEAM_1Y',
            android: 'pn_team_1y',
        },
        cb_price: {
            decimal: 262.00,
            currency: 'GBP',
        },
    },
    PN_TEAM_1Y_20170601: {
        recordId: 28,
        id: {
            web: 'PN_TEAM_1Y_20170601',
            ios: 'PN_TEAM_1Y_20170601',
            android: null,
        },
        cb_price: {
            decimal: 370.00,
            currency: 'GBP',
        },
    },
    PN_TEAM_2Y: {
        recordId: 17,
        id: {
            web: 'PN_TEAM_2Y',
            ios: null,
            android: 'pn_team_2y',
        },
        cb_price: {
            decimal: 402.00,
            currency: 'GBP',
        },
    },
    PN_STARTER_1M: {
        recordId: 21,
        id: {
            web: 'PN_STARTER_1M',
            ios: 'PN_STARTER_1M',
            android: 'pn_starter_1m',
        },
        cb_price: {
            decimal: 4.99,
            currency: 'GBP',
        },
    },
    PN_STARTER_1Y: {
        recordId: 22,
        id: {
            web: 'PN_STARTER_1Y',
            ios: 'PN_STARTER_1Y',
            android: 'pn_starter_1y',
        },
        cb_price: {
            decimal: 49.99,
            currency: 'GBP',
        },
    },
    PN_STARTER_5DOC_1M: {
        recordId: SUBSCRIPTION_PLAN.StarterMonthly,
        id: {
            web: 'PN_STARTER_5DOC_1M',
            ios: 'PN_STARTER_5DOC_1M',
            android: 'pn_starter_5doc_1m',
        },
        cb_price: {
            decimal: 4.99,
            currency: 'GBP',
        },
    },
    PN_STARTER_5DOC_1Y: {
        recordId: SUBSCRIPTION_PLAN.StarterYearly,
        id: {
            web: 'PN_STARTER_5DOC_1Y',
            ios: 'PN_STARTER_5DOC_1Y',
            android: 'pn_starter_5doc_1y',
        },
        cb_price: {
            decimal: 49.99,
            currency: 'GBP',
        },
    },
    PN_BUSINESS_NEW_1Y: {
        recordId: 32,
        id: {
            web: 'PN_BUSINESS_NEW_1Y',
            ios: null,
            android: null,
        },
        cb_price: {
            decimal: 270.00,
            currency: 'GBP',
        },
    },
    PN_BUSINESS_NEW_1M: {
        recordId: 33,
        id: {
            web: 'PN_BUSINESS_NEW_1M',
            ios: 'PN_BUSINESS_NEW_1M',
            android: 'pn_business_new_1m',
        },
        cb_price: {
            decimal: 27.00,
            currency: 'GBP',
        },
    },
    BUSINESS_1M_2019_08: {
        recordId: SUBSCRIPTION_PLAN.BusinessMonthly,
        id: {
            web: 'BUSINESS_1M_2019_08',
            ios: 'BUSINESS_1M_2019_08',
            android: 'business_1m_2019_08',
        },
        cb_price: {
            decimal: 27.00,
            currency: 'GBP',
        },
    },
    BUSINESS_1Y_2019_08: {
        recordId: 41,
        id: {
            web: 'BUSINESS_1Y_2019_08',
            ios: 'BUSINESS_1Y_2019_08',
            android: 'business_1y_2019_08',
        },
        cb_price: {
            decimal: 270.00,
            currency: 'GBP',
        },
    },
    BUSINESS_1Y_2019_11: {
        recordId: SUBSCRIPTION_PLAN.BusinessYearly,
        id: {
            web: 'BUSINESS_1Y_2019_11',
            ios: 'BUSINESS_1Y_2019_11',
            android: 'business_1y_2019_11',
        },
        cb_price: {
            decimal: 270.00,
            currency: 'GBP',
        },
    },
    PN_PRO_NEW_1Y: {
        recordId: 34,
        id: {
            web: 'PN_PRO_NEW_1Y',
            ios: null,
            android: null,
        },
        cb_price: {
            decimal: 370.00,
            currency: 'GBP',
        },
    },
    PN_PRO_NEW_1M: {
        recordId: 35,
        id: {
            web: 'PN_PRO_NEW_1M',
            ios: 'PN_PRO_NEW_1M',
            android: 'pn_pro_new_1m',
        },
        cb_price: {
            decimal: 37.00,
            currency: 'GBP',
        },
    },
    PN_PRO_201910_1Y: {
        recordId: SUBSCRIPTION_PLAN.ProfessionalYearly,
        id: {
            web: 'PN_PRO_201910_1Y',
            ios: null,
            android: null,
        },
        cb_price: {
            decimal: 300.00,
            currency: 'GBP',
        },
    },
    '1TA_PRO_1Y': {
        recordId: SUBSCRIPTION_PLAN.OneTradeAppProfessionalYearly,
        id: {
            web: '1TA_PRO_1Y',
            ios: '1TA_PRO_1Y',
            android: '1ta_pro_1y',
        },
        cb_price: {
            decimal: 49.99,
            currency: 'GBP',
        },
    },
    PN_PRO_201910_1M: {
        recordId: SUBSCRIPTION_PLAN.ProfessionalMonthly,
        id: {
            web: 'PN_PRO_201910_1M',
            ios: 'PN_PRO_201910_1M',
            android: 'pn_pro_201910_1m',
        },
        cb_price: {
            decimal: 30.00,
            currency: 'GBP',
        },
    },
    PN_CONSUMABLE_BUSINESS_EXTRA_USER_1Y: {
        recordId: 36,
        consumable: true,
        id: {
            web: 'PN_CONSUMABLE_BUSINESS_EXTRA_USER_1Y',
            ios: null,
            android: null,
        },
        cb_price: {
            decimal: 270.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    PN_CONSUMABLE_BUSINESS_EXTRA_USER_1M: {
        recordId: 37,
        consumable: true,
        id: {
            web: 'PN_CONSUMABLE_BUSINESS_EXTRA_USER_1M',
            ios: 'PN_CONSUMABLE_BUSINESS_EXTRA_USER_1M',
            android: 'pn_consumable_business_extra_user_1m',
        },
        cb_price: {
            decimal: 27.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    PN_CONSUMABLE_PROFESSIONAL_EXTRA_USER_1Y: {
        recordId: 38,
        consumable: true,
        id: {
            web: 'PN_CONSUMABLE_PROFESSIONAL_EXTRA_USER_1Y',
            ios: null,
            android: null,
        },
        cb_price: {
            decimal: 300.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    PN_CONSUMABLE_PROFESSIONAL_EXTRA_USER_1M: {
        recordId: 39,
        consumable: true,
        id: {
            web: 'PN_CONSUMABLE_PROFESSIONAL_EXTRA_USER_1M',
            ios: 'PN_CONSUMABLE_PROFESSIONAL_EXTRA_USER_1M',
            android: 'pn_consumable_professional_extra_user_1m',
        },
        cb_price: {
            decimal: 30.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    CONSUMABLE_EXTRA_USER_1M_201908: {
        recordId: CONSUMABLE.ExtraUserProfessionalMonthly,
        consumable: true,
        id: {
            web: 'CONSUMABLE_EXTRA_USER_1M_201908',
            ios: 'CONSUMABLE_EXTRA_USER_1M_201908',
            android: 'consumable_extra_user_1m_201908',
        },
        cb_price: {
            decimal: 30.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    CONSUMABLE_EXTRA_USER_1Y_201908: {
        recordId: CONSUMABLE.ExtraUserProfessionalYearly,
        consumable: true,
        id: {
            web: 'CONSUMABLE_EXTRA_USER_1Y_201908',
            ios: null,
            android: null,
        },
        cb_price: {
            decimal: 300.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    PN_CONSUMABLE_PREMIUM_EXTRA_USER_1MONTH: {
        recordId: CONSUMABLE.ExtraUserPremiumMonthly,
        consumable: true,
        id: {
            web: 'PN_CONSUMABLE_PREMIUM_EXTRA_USER_1MONTH',
            ios: 'PN_CONSUMABLE_PREMIUM_EXTRA_USER_1MONTH',
            android: 'pn_consumable_premium_extra_user_1month',
        },
        cb_price: {
            decimal: 37.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    PN_CONSUMABLE_PREMIUM_EXTRA_USER_1YEAR: {
        recordId: CONSUMABLE.ExtraUserPremiumYearly,
        consumable: true,
        id: {
            web: 'PN_CONSUMABLE_PREMIUM_EXTRA_USER_1YEAR',
            ios: 'PN_CONSUMABLE_PREMIUM_EXTRA_USER_1YEAR',
            android: 'pn_consumable_premium_extra_user_1year',
        },
        cb_price: {
            decimal: 370.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    PREMIUM_ANNUAL: {
        recordId: SUBSCRIPTION_PLAN.PremiumYearly,
        id: {
            web: 'PREMIUM_ANNUAL',
            ios: null,
            android: null,
        },
        cb_price: {
            decimal: 370.00,
            currency: 'GBP',
        },
    },
    PREMIUM_MONTHLY: {
        recordId: SUBSCRIPTION_PLAN.PremiumMonthly,
        id: {
            web: 'PREMIUM_MONTHLY',
            ios: 'PREMIUM_MONTHLY',
            android: 'premium_monthly',
        },
        cb_price: {
            decimal: 37.00,
            currency: 'GBP',
        },
    },
    SF_PREMIUM_ANNUAL: {
        recordId: SUBSCRIPTION_PLAN.SfPremiumYearly,
        id: {
            web: 'SF_PREMIUM_ANNUAL',
            ios: null,
            android: null,
        },
        cb_price: {
            decimal: 370.00,
            currency: 'GBP',
        },
    },
    SF_PREMIUM_MONTHLY: {
        recordId: SUBSCRIPTION_PLAN.SfPremiumMonthly,
        id: {
            web: 'SF_PREMIUM_MONTHLY',
            ios: 'SF_PREMIUM_MONTHLY',
            android: 'sf_premium_monthly',
        },
        cb_price: {
            decimal: 37.00,
            currency: 'GBP',
        },
    },
    SF_PRO_MONTHLY: {
        recordId: SUBSCRIPTION_PLAN.SfProfessionalMonthly,
        id: {
            web: 'SF_PRO_MONTHLY',
            ios: 'SF_PRO_MONTHLY',
            android: 'sf_pro_monthly',
        },
        cb_price: {
            decimal: 30.00,
            currency: 'GBP',
        },
    },
    SF_PRO_ANNUAL: {
        recordId: SUBSCRIPTION_PLAN.SfProfessionalYearly,
        id: {
            web: 'SF_PRO_ANNUAL',
            ios: null,
            android: null,
        },
        cb_price: {
            decimal: 300.00,
            currency: 'GBP',
        },
    },
    SF_BUSINESS_MONTHLY: {
        recordId: SUBSCRIPTION_PLAN.SfBusinessMonthly,
        id: {
            web: 'SF_BUSINESS_MONTHLY',
            ios: 'SF_BUSINESS_MONTHLY',
            android: 'sf_business_monthly',
        },
        cb_price: {
            decimal: 27.00,
            currency: 'GBP',
        },
    },
    SF_BUSINESS_ANNUAL: {
        recordId: SUBSCRIPTION_PLAN.SfBusinessYearly,
        id: {
            web: 'SF_BUSINESS_ANNUAL',
            ios: 'SF_BUSINESS_ANNUAL',
            android: 'sf_business_annual',
        },
        cb_price: {
            decimal: 270.00,
            currency: 'GBP',
        },
    },
    SF_CONSUMABLE_PREMIUM_EXTRA_USER_MONTHLY: {
        recordId: CONSUMABLE.SfExtraUserPremiumMonthly,
        consumable: true,
        id: {
            web: 'SF_CONSUMABLE_PREMIUM_EXTRA_USER_MONTHLY',
            ios: 'SF_CONSUMABLE_PREMIUM_EXTRA_USER_MONTHLY',
            android: 'sf_consumable_premium_extra_user_monthly',
        },
        cb_price: {
            decimal: 37.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    SF_CONSUMABLE_PREMIUM_EXTRA_USER_ANNUAL: {
        recordId: CONSUMABLE.SfExtraUserPremiumYearly,
        consumable: true,
        id: {
            web: 'SF_CONSUMABLE_PREMIUM_EXTRA_USER_ANNUAL',
            ios: 'SF_CONSUMABLE_PREMIUM_EXTRA_USER_ANNUAL',
            android: 'sf_consumable_premium_extra_user_annual',
        },
        cb_price: {
            decimal: 370.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    SF_CONSUMABLE_PRO_EXTRA_USER_MONTHLY: {
        recordId: CONSUMABLE.SfExtraUserProfessionalMonthly,
        consumable: true,
        id: {
            web: 'SF_CONSUMABLE_PRO_EXTRA_USER_MONTHLY',
            ios: 'SF_CONSUMABLE_PRO_EXTRA_USER_MONTHLY',
            android: 'sf_consumable_pro_extra_user_monthly',
        },
        cb_price: {
            decimal: 30.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    SF_CONSUMABLE_PRO_EXTRA_USER_ANNUAL: {
        recordId: CONSUMABLE.SfExtraUserProfessionalYearly,
        consumable: true,
        id: {
            web: 'SF_CONSUMABLE_PRO_EXTRA_USER_ANNUAL',
            ios: null,
            android: null,
        },
        cb_price: {
            decimal: 300.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    SF_CONSUMABLE_BUSINESS_EXTRA_USER_MONTHLY: {
        recordId: CONSUMABLE.SfExtraUserBusinessMonthly,
        consumable: true,
        id: {
            web: 'SF_CONSUMABLE_BUSINESS_EXTRA_USER_MONTHLY',
            ios: 'SF_CONSUMABLE_BUSINESS_EXTRA_USER_MONTHLY',
            android: 'sf_consumable_business_extra_user_monthly',
        },
        cb_price: {
            decimal: 27.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    SF_CONSUMABLE_BUSINESS_EXTRA_USER_ANNUAL: {
        recordId: CONSUMABLE.SfExtraUserBusinessYearly,
        consumable: true,
        id: {
            web: 'SF_CONSUMABLE_BUSINESS_EXTRA_USER_ANNUAL',
            ios: null,
            android: null,
        },
        cb_price: {
            decimal: 270.00,
            currency: 'GBP',
        },
        ...consumableUserDefault,
    },
    SF_EXPIRED: {
        recordId: SUBSCRIPTION_PLAN.SfExpired,
        id: {
            web: '',
            ios: '',
            android: '',
        },
        cb_price: {
            decimal: 0,
            currency: 'GBP',
        },
    },
    SF_TRIAL: {
        recordId: SUBSCRIPTION_PLAN.SfTrial,
        id: {
            web: '',
            ios: '',
            android: '',
        },
        cb_price: {
            decimal: 0,
            currency: 'GBP',
        },
    },
};

export const MODE = {
    EXPIRED: 'expired',
    TRIAL: 'trial',
    SUBSCRIBED: 'subscribed',
} as const;

export type ModeValues = typeof MODE[keyof typeof MODE];
export const PURCHASE_ORIGIN_ID = {
    BRAINTREE: null,
    ITUNES: 0,
    ANDROID: 2,
    CHARGEBEE: 3,
    MARKETING_FREE: 4,
    PROMOTIONAL: 5,
    TRIAL_UPGRADE: 6,
    CORRECTION: 7,
    START_DIRECT_DEBIT: 8,
} as const;

export const FREE_SUBSCRIPTION_SETUPS = {
    PROMOTIONAL: {
        PURCHASE_ORIGIN_ID: 5,
        DEFAULT_LENGTH: 365,
        INCLUDE_IN_REPORTS: false,
        LABEL: 'Free year',
    },
    TRIAL_UPGRADE: {
        PURCHASE_ORIGIN_ID: 6,
        DEFAULT_LENGTH: 30,
        INCLUDE_IN_REPORTS: false,
        LABEL: 'Trial upgrade',
    },
    CORRECTION: {
        PURCHASE_ORIGIN_ID: 7,
        DEFAULT_LENGTH: 30,
        INCLUDE_IN_REPORTS: true,
        LABEL: 'Correction for month',
    },
    START_DIRECT_DEBIT: {
        PURCHASE_ORIGIN_ID: 8,
        DEFAULT_LENGTH: 10,
        INCLUDE_IN_REPORTS: true,
        LABEL: 'Direct debit',
    },
} as const;

export default {
    PURCHASE_INFO: {
        TYPES: {
            UPGRADE: 1,
            DOWNGRADE: 2,
            NO_CHANGE: 3,
            FREE: 4,
            CHURN: 5,
            NEW: 6,
            UNSUBSCRIBED: 7,
            NO_DATA: 8,
        },
    },
    RENEWAL_TOLERANCE_HOURS: 8,
    PAYMENT_TYPES: {
        IAB: 'IAB',
        IAP: 'IAP',
        BT: 'BT',
    },
    PURCHASE_ORIGIN_ID,
    MODE,
    PURCHASE_SETUP_ID: {
        EXPIRED: 3,
        EXPIRED_NO_DOC: 20,
        TRIAL: 1,
        '1TA_TRIAL': 9,
        SCREWFIX_TRIAL: 64,
        SCREWFIX_EXPIRED: 65,
    },

    PRODUCT: PRODUCTS,

    PLAN_SIZE: {
        PS_2: 2,
        PS_3: 3,
        PS_6: 6,
    },
    OVERTIME: 14,
    TRIAL_LENGTH: 14,
    TRIAL_EXTEND_LENGTH: 14,
    PRODUCT_TIER_TO_TEAM_SIZE,
    PERIOD_PAIRS,
    SUBSCRIPTION_PLAN,
    CONSUMABLE,
    ERROR_MESSAGE: {
        CHANGE_LAYOUT_SETTINGS: 'You don\'t have permission to change pricing layout!',
    },
    WHITE_LABEL_IDS,
};
