import React from 'react';
import useComplexData from '@data/hooks/complexDataHook';
import textUtils from '@powerednow/shared/modules/utilities/textUtils';
import { authState } from '@data/state/auth';
import { useRecoilValue } from 'recoil';
import config from 'src/config';
import CircularProgress from '@mui/material/CircularProgress';
import useFormatMoney from '@data/hooks/MoneyFormatter';
import usePrompt from '@data/hooks/promptHook';
import useQuoteName from '@data/hooks/quoteName';
import DocumentContainer from '@features/document/DocumentContainer';
import ItemActionSection from '@components/ItemInformations/itemActionSection';
import DocumentBlock from '@features/document/DocumentBlock';
import DocumentAction from '@features/document/documentAction';
import { DOCUMENT } from '@powerednow/shared/constants';
import generateDocumentUrlUuidString from '@powerednow/shared/modules/utilities/generateDocumentUrlUuidString';
import { isInvoicePaid } from '@features/document/list/documentsListHelpers';
import { getFormattedDate } from '@powerednow/portal/src/helper/date';
import { complexFindModel } from '@powerednow/models/modelLookup';
import DocumentWithPreview from './DocumentWithPreview';

type DocumentDetailsProps = {
    documentId: number
}

export default function DocumentDetails(documentDetails: DocumentDetailsProps) {
    const L = usePrompt();
    const { documentId } = documentDetails;

    const authData = useRecoilValue(authState);
    const { data: { companyId } } = authData;

    const complexDocument = useComplexData(
        'Document',
        documentId,
        async (complexInstance, resultSetter: (_newResult: {
            summaryData: any
        })=>void) => {
            const summaryData = await complexInstance.getSummaryData();
            resultSetter({
                summaryData,
            });
        },
    );
    const documentData = complexDocument.details;
    const summaryData = complexDocument.optionalResult?.summaryData || {};
    const documentTotalWithCurrencySymbol = useFormatMoney(documentData?.total);
    const quoteName = useQuoteName();
    if (!documentData) {
        return (<CircularProgress />);
    }

    const {
        uuid,
        status: documentStatus,
        total: documentTotal,
        type: documentTypeId,
        displaynumber: documentDisplayNumber,
        refusereason: refuseReason,
        dueDate,
    } = documentData || {};

    const formattedFormDocumentId = generateDocumentUrlUuidString(uuid, companyId);
    const requestUrl = `${config.API_URL}/document/preview/${formattedFormDocumentId}/`;
    const downloadUrl = `${config.API_URL}/document/pdf/${formattedFormDocumentId}`;

    const documentPrefix = 'You have received a new ';

    const getDocumentType = () => {
        const documentTypeString = documentTypeId === DOCUMENT.TYPES_VALUES.QUOTE ? quoteName : DOCUMENT.TYPES[documentTypeId];
        return L(`InvoiceLabel.${documentTypeString}`, `${documentTypeString}`.replace(/_/g, ' '));
    };

    const documentType = getDocumentType();
    const { promptId, defaultValue } = complexFindModel('Document').getStatusStringByTypeAndStatus(documentTypeId || 0, documentStatus || 0);
    const status = L(promptId, defaultValue);

    const shouldShowDueDataText = !isInvoicePaid(document, documentStatus) && dueDate;

    const formattedDate = getFormattedDate(dueDate);

    const dueDateText = shouldShowDueDataText ? formattedDate?.split(' ')[0] : undefined;
    const documentTitle = `${documentPrefix}${documentType}`;

    const totalText = summaryData?.showTotal ? documentTotalWithCurrencySymbol : undefined;

    return (
        <DocumentContainer>
            <DocumentBlock
                type={documentTypeId}
                title={documentTitle}
                summaryData={summaryData}
                total={totalText}
                dueDate={dueDateText}
                status={status}
                refuseReason={refuseReason}
            >
                <ItemActionSection>
                    <DocumentAction
                        status={documentStatus}
                        documentTypeId={documentTypeId}
                        documentStorageUrl={downloadUrl}
                        documentDisplayNumber={documentDisplayNumber!}
                        documentTotal={documentTotal}
                        documentId={documentId}
                    />
                </ItemActionSection>
            </DocumentBlock>
            <DocumentWithPreview
                previewUrl={requestUrl}
                mediaUrl={requestUrl}
                description={`${textUtils.capitalize(documentType)}: ${documentDisplayNumber}`}
            />
        </DocumentContainer>
    );
}
