import { jsx as _jsx } from "react/jsx-runtime";
import ArrowForward from '@mui/icons-material/ArrowForward';
import { omit, pick, camelCase } from 'lodash';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ColoredButton from './ColoredButton';
const useStyles = makeStyles(theme => createStyles({
    iconButton: {
        minWidth: 'auto',
        borderRadius: theme.shape.borderRadius,
    },
    smallIcon: {
        padding: theme.spacing(1),
    },
    mediumIcon: {
        padding: theme.spacing(1.5),
    },
    largeIcon: {
        padding: theme.spacing(2),
    },
    smallIconOutlined: {
        padding: theme.spacing(0.75),
    },
    mediumIconOutlined: {
        padding: theme.spacing(1.25),
    },
    largeIconOutlined: {
        padding: theme.spacing(1.75),
    },
    noWrap: {
        '& .MuiButton-label': {
            whiteSpace: 'nowrap',
        },
    },
    hideButton: {
        display: 'none',
    },
}));
export default function Button(props) {
    const theme = useTheme();
    const classes = useStyles();
    const { emphasis, size, minWidth, maxWidth, borderRadius, style, buttonType, noWrap, hidden = false, } = props;
    let { className, startIcon, endIcon } = props;
    const variant = { high: 'contained', medium: 'outlined', low: 'text' }[emphasis || 'high'];
    const mappedSize = { small: 'small', medium: 'medium', large: 'large' }[size || 'medium'];
    const color = {
        primary: 'primary',
        secondary: 'secondary',
        warning: 'warning',
        error: 'error',
        neutral: 'neutral',
    }[buttonType || 'primary'];
    let { children } = props;
    if ((!children || children === ' ') && (startIcon || endIcon)) {
        const iconClass = classes[camelCase([mappedSize, 'Icon', variant === 'outlined' ? 'Outlined' : ''].join('_'))];
        children = startIcon || endIcon;
        className = clsx([className, classes.iconButton, iconClass]);
        startIcon = null;
        endIcon = null;
    }
    if (noWrap) {
        className = clsx([className, classes.noWrap]);
    }
    if (hidden) {
        className = clsx([className, classes.hideButton]);
    }
    let marginStyles = {};
    const spacingProps = pick(props, ['m', 'mt', 'mr', 'mb', 'ml', 'p', 'pt', 'pr', 'pb', 'pl']);
    const applySpacingProps = (key, styleName) => {
        if (spacingProps[key]) {
            if (typeof spacingProps[key] === 'number') {
                spacingProps[key] = theme.spacing(spacingProps[key]);
            }
            marginStyles = Object.assign(Object.assign({}, marginStyles), { [styleName]: spacingProps[key] });
        }
    };
    applySpacingProps('m', 'margin');
    applySpacingProps('mt', 'marginTop');
    applySpacingProps('mr', 'marginRight');
    applySpacingProps('mb', 'marginBottom');
    applySpacingProps('ml', 'marginLeft');
    applySpacingProps('p', 'padding');
    applySpacingProps('pt', 'paddingTop');
    applySpacingProps('pr', 'paddingRight');
    applySpacingProps('pb', 'paddingBottom');
    applySpacingProps('pl', 'paddingLeft');
    return (_jsx(ColoredButton, Object.assign({ color,
        variant,
        className,
        startIcon }, spacingProps, omit(props, ['className', 'startIcon', 'buttonType', 'color', 'emphasis', 'style', 'noWrap', 'minWidth', 'm', 'mt', 'mr', 'mb', 'ml']), { endIcon: (typeof endIcon === 'undefined' && variant === 'text') ? _jsx(ArrowForward, {}) : endIcon, style: Object.assign(Object.assign({ minWidth: minWidth || 'initial', maxWidth: maxWidth || 'initial', borderRadius: borderRadius || theme.shape.borderRadius }, marginStyles), style), children: children })));
}
