import React from 'react';

import arrayUtils from '@powerednow/shared/modules/utilities/array';
import useComplexData from '@data/hooks/complexDataHook';
import { ComplexModelEntity } from '@powerednow/models/modelLookup';

type MessageDataType = ComplexModelEntity<'Message'>;
type CustomerMessageDataType = ComplexModelEntity<'CustomerMessage'>;
type MessageLoader = (messageId: number) => { messages?: MessageDataType[] | CustomerMessageDataType[]};

export const useDocumentMessage: MessageLoader = documentId => {
    const { optionalResult } = useComplexData(
        'Document',
        documentId,
        async (complexDocument, resultSetter: (_newResult:{ messages: CustomerMessageDataType[], loaded: boolean }) => void) => {
            const complexCustomerMessages = await complexDocument.getAllCustomerMessage();
            const messages = complexCustomerMessages.map(message => message.data.getPureDataValues());
            resultSetter({ messages, loaded: true });
        },
    );
    return {
        messages: optionalResult?.messages,
        loaded: optionalResult?.loaded,
    };
};

export const useAppointmentMessage: MessageLoader = actionId => {
    const { optionalResult } = useComplexData(
        'Action',
        actionId,
        async (complexAppointment, resultSetter: (_newResult: { messages: MessageDataType[], loaded: boolean }) => void) => {
            const complexMessages = await complexAppointment.getAllMessage();
            const messages = complexMessages.map(message => message.data.getPureDataValues());
            resultSetter({ messages, loaded: true });
        },
    );
    return {
        messages: optionalResult?.messages,
        loaded: optionalResult?.loaded,
    };
};

export const useFormMessage: MessageLoader = formId => {
    const { optionalResult } = useComplexData(
        'FormDocument',
        formId,
        async (complexDocument, resultSetter: (_newResult: { messages: CustomerMessageDataType[], loaded: boolean }) => void) => {
            const complexCustomerMessages = await complexDocument.getAllCustomerMessage();
            const messages = complexCustomerMessages.map(message => message.data.getPureDataValues());
            resultSetter({ messages, loaded: true });
        },
    );
    return {
        messages: optionalResult?.messages,
        loaded: optionalResult?.loaded,
    };
};

export const useMessageLoader = (complexInstanceId, messageLoader) => {
    const { messages, loaded } = messageLoader(complexInstanceId);
    if (loaded) {
        let messageId;
        if (messages?.length) {
            const [firstMessage] = arrayUtils.objectArraySorter(messages, 'dt_created', 'asc');
            messageId = firstMessage.id;
        }
        return {
            loaded,
            messageId,
        };
    }
    return {
        loaded,
    };
};
