import React from 'react';
import { hasFullPortalAccess } from '@data/state/subscription';
import RequireFeature from '@features/permission/requireFeature';
import SendMessageButton, { SendMessageButtonProps } from '@components/button/SendMessageButton';
import { hasRole } from '@data/state/permission';
import { useRecoilValue } from 'recoil';
import { ROLES } from '@powerednow/shared/constants';
import RequirePortalUser from '@features/permission/requirePortalUser';
import { canCustomerAccessPortalChat } from '@data/state/companySettings';

export default function PortalSendMessageButton(props: SendMessageButtonProps) {
    const {
        onClick,
        color,
        loaded,
    } = props;

    const canSendMessage = useRecoilValue(hasRole(ROLES.PERMISSIONS.CAN_SEND_MESSAGE_FROM_PORTAL)) && useRecoilValue(canCustomerAccessPortalChat);

    return (
        <RequirePortalUser>
            <RequireFeature feature={hasFullPortalAccess}>
                <SendMessageButton onClick={onClick} color={color} loaded={loaded} enabled={canSendMessage} />
            </RequireFeature>
        </RequirePortalUser>

    );
}
