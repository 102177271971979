export default {
    regexp: [
        // /.*@powerednow\.com/i,
        // /cdbarling.*@gmail\.com/i,
    ],
    mysql: [
        // '%@powerednow.com',
        // 'cdbarling.%@gmail.com',
    ],
} as Record<string, RegExp[] | string[]>;
