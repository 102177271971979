import React, { useState } from 'react';
import {
    Box,
    Snackbar,
    Alert,
    AlertTitle,
} from '@mui/material';
import Button from '@components/button/Button';
import { getApiRequest } from '@app/connection/apiRequest';
import { ifInstanceOf } from '@powerednow/shared/modules/typedCatch';
import { useToast } from '@components/toast/toast';
import { useRecoilValue } from 'recoil';
import Mask from '@components/mask/Mask';
import { CircularProgress } from '@components/progress/Progress';
import { NetworkError, ServiceError } from '@powerednow/shared/error';
import { authState } from '@data/state/auth';
import useCompanyDetails from '@data/hooks/useCompanyDetails';
import { canUseFeature, isSiteContactAndHasFullPortalPermission } from '@data/state/subscription';
import RequireFeature from '@features/permission/requireFeature';
import { FEATURES } from '@powerednow/shared/constants/subscription';

function ActionButton({ onClose, portalId }) {
    const [showProgress, setShowProgress] = useState(false);
    const [sendButtonPressed, setSendButtonPressed] = useState(false);
    const { success, error } = useToast();

    const sendLink = async () => {
        setShowProgress(true);
        setSendButtonPressed(true);
        return getApiRequest().resendAuthToken(portalId).then(() => {
            setShowProgress(false);
            onClose();
            success('Link has been sent to your email address!');
        })
            .catch(ifInstanceOf(ServiceError, () => {
                error('Your request could not be processed. It is either an error at our side or your token is invalid.');
                setShowProgress(false);
            }))
            .catch(ifInstanceOf(NetworkError, () => {
                error('Your network seems to be down or Amazon did blow up and our servers are in the heaven not in the cloud anymore.');
                setShowProgress(false);
            }));
    };

    return (
        <Box display="flex" justifyContent="flex-start" flexDirection="column">
            <Mask opacity={0.1} show={showProgress} fullScreen content={<CircularProgress />} />
            <Button buttonType="primary" emphasis="low" onClick={onClose}>Close</Button>
            <RequireFeature feature={canUseFeature(FEATURES.CAN_USE_PORTAL)}>
                <Button
                    buttonType="primary"
                    emphasis="low"
                    href="/login"
                >
                    Login
                </Button>
            </RequireFeature>
            <Button
                buttonType="primary"
                minWidth="125px"
                emphasis="high"
                disabled={sendButtonPressed}
                onClick={() => sendLink()}
            >
                Send new link
            </Button>
        </Box>
    );
}

export default function ReusedLinkSnackBar() {
    const authData = useRecoilValue(authState);
    const canUsePortal = useRecoilValue(canUseFeature(FEATURES.CAN_USE_PORTAL));
    const { portalId, data } = authData;
    const { details } = useCompanyDetails(data?.companyId);
    const [showSnackbar, setShowSnackbar] = useState(true);

    if (!portalId || !canUsePortal) {
        return null;
    }

    const handleClose: any = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnackbar(false);
    };

    return (
        <RequireFeature feature={isSiteContactAndHasFullPortalPermission}>
            <Snackbar
                open={showSnackbar && data.used}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleClose}
                    severity="info"
                    elevation={6}
                    action={<ActionButton portalId={portalId} onClose={handleClose} />}
                >
                    <AlertTitle>Link already used in another browser.</AlertTitle>
                    Please Note: Some of the features on this page are limited. Please request a new link to access the full
                    {' '}
                    {details?.name}
                    {' '}
                    portal.
                    <br />
                    Make sure to check your spam folder if the email doesn&apos;t appear in your inbox.
                </Alert>
            </Snackbar>
        </RequireFeature>
    );
}
