import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
const useStyles = makeStyles(theme => createStyles({
    checkedColor: {
        fill: theme.palette.radio.checked || theme.palette.primary.main,
    },
    uncheckedColor: {
        fill: theme.palette.radio.unchecked || theme.palette.primary.light,
    },
}));
export default function PowerednowRadio(props) {
    const { checked } = props;
    const stateColors = useStyles();
    const innerCircleClassName = checked ? stateColors.checkedColor : stateColors.uncheckedColor;
    const innerCircle = (_jsx("circle", { cx: "50%", cy: "50%", r: "8px", className: innerCircleClassName }));
    return (_jsxs("svg", { width: "38px", height: "38px", viewBox: "0 0 24 24", fontSize: "38px", children: [_jsx("circle", { cx: "50%", cy: "50%", r: "11px", stroke: "white", strokeWidth: "1px", fill: "white" }), innerCircle] }));
}
