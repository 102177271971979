import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { omit } from 'lodash';
import { Box } from '@mui/material';
const useStyles = makeStyles(() => createStyles({
    gridMatrix: {
        display: 'grid',
    },
}));
export default function ComponentMatrix(_a = {
    children: '', columns: 1,
}) {
    var { className, spacing, columns, children } = _a, props = __rest(_a, ["className", "spacing", "columns", "children"]);
    const classes = useStyles();
    const flexClass = classes.gridMatrix;
    return (_jsx(Box, Object.assign({ className: clsx(className, flexClass), style: {
            columnGap: `${spacing}px`,
            rowGap: `${spacing}px`,
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
        } }, omit(props, 'className', 'cellClassName', 'cellStyle', 'cellAlign', 'cellJustify'), { children: children })));
}
