import adminReport from './adminReport';
import adminFilters from './adminFilters';
import accountVerification from './accountVerification';
import archive from './archive';
import roles from './roles';
import sync from './sync';
import login from './login';
import http from './http';
import company from './company';
import customer from './customer';
import subscription from './subscription';
import deviceTypes from './deviceTypes';
import partnerLookup from './partnerLookup';
import httpRequest from './httpRequest';
import messages from './messages';
import email from './email';
import layout from './layout';
import logo from './logo';
import autoComplete from './autoComplete';
import document from './document';
import contentTypes from './contentTypes';
import contentTypeFormats from './contentTypeFormats';
import action from './action';
import payment from './payment';
import project from './project';
import error from './error';
import apiResponses from './apiResponses';
import importValidationErrors from './importValidationErrors';
import audit from './audit';
import dataEmitterServices from './dataEmitterServices';
import dataStructures from './dataStructures';
import oauth2 from './oauth2';
import chat from './chat';
import cis from './cis';
import consumptionExtra from './consumptionExtra';
import fileUpload from './fileUpload';
import purchaseSetup from './purchaseSetup';
import purchaseAction from './purchaseAction';
import report from './report';
import whatToExportValues from './whatToExportValues';
import systemAttachment from './systemAttachment';
import companyConnection from './companyConnection';
import applications from './applications';
import events from './events';
import testEmailMatchers from './testEmailMatchers';
import social from './social';
import cohorts from './cohorts';
import contactToAddress from './contactToAddress';
import countryFeature from './countryFeature';
import country from './country';
import emailValidationMatcher from './emailValidationMatcher';
import userProfile from './userProfile';
import userRoles from './userRoles';
import platforms from './platforms';
import expressions from './expressions';
import globalConfig from './globalConfig';
import global2FASettings from './global2FASettings';
import forms from './forms';
import validation from './validation';
import adminAuditTrail from './adminAuditTrail';
import screen from './screen';
import network from './network';
import formInheritanceFilter from './formInheritanceFilter';
import formCategory from './formCategory';
import tax from './tax';
import countryGroup from './countryGroup';
import environment from './environment';
import userProfileRoleCustom from './userProfileRoleCustom';
import productCategory from './productCategory';
import settings from './settings';
import notification from './notification';
import integration from './integration';
import database from './database';
import filter from './filter';
import usageHistory from './usageHistory';
import settingsKeys from './settingsKeys';
import intercom from './intercom';
import deliveryAddress from './deliveryAddress';
import partner from './partner';
import socket from './socket';
import requestProxy from './requestProxy';
import incrementalNumber from './incrementalNumber';
import businessCalculator from './businessCalculator';
import user from './user';
import navigation from './navigation';
import startingPage from './startingPage';
import status from './status';
import whiteLabel from './whiteLabel';
import purchases from './purchases';
import envelopeType from './envelopeType';
import accountType from './accountType';
import * as customerEmailTemplate from './customerEmailTemplateValues';

const CONSTANTS = {
    ENVELOPE_TYPE: envelopeType,
    ADMIN_REPORT: adminReport,
    ADMIN_FILTERS: adminFilters,
    ACCOUNT_VERIFICATION: accountVerification,
    ARCHIVE: archive,
    ROLES: roles,
    SYNC: sync,
    LOGIN: login,
    HTTP: http,
    COMPANY: company,
    CUSTOMER: customer,
    SUBSCRIPTION: subscription,
    DEVICE_TYPES: deviceTypes,
    PARTNER_LOOKUP: partnerLookup,
    HTTP_REQUEST: httpRequest,
    MESSAGES: messages,
    EMAIL: email,
    LAYOUT: layout,
    LOGO: logo,
    AUTO_COMPLETE: autoComplete,
    DOCUMENT: document,
    CONTENT_TYPES: contentTypes,
    CONTENT_TYPE_FORMATS: contentTypeFormats,
    ACTION: action,
    PAYMENT: payment,
    PROJECT: project,
    ERROR: error,
    API_RESPONSES: apiResponses,
    IMPORT_VALIDATION_ERRORS: importValidationErrors,
    AUDIT: audit,
    DATA_EMITTER_SERVICES: dataEmitterServices,
    DATA_STRUCTURES: dataStructures,
    OAUTH2: oauth2,
    CHAT: chat,
    CIS: cis,
    CONSUMPTION_EXTRA: consumptionExtra,
    FILE_UPLOAD: fileUpload,
    PURCHASE_SETUP: purchaseSetup,
    PURCHASE_ACTION: purchaseAction,
    REPORT: report,
    WHAT_TO_EXPORT_VALUES: whatToExportValues,
    SYSTEM_ATTACHMENT: systemAttachment,
    COMPANY_CONNECTION: companyConnection,
    APPLICATIONS: applications,
    EVENTS: events,
    EMAIL_VALIDATOR_MATCHER: emailValidationMatcher,
    TEST_EMAILS_MATCHER: testEmailMatchers,
    SOCIAL: social,
    COHORTS: cohorts,
    CONTACT_TO_ADDRESS: contactToAddress,
    COUNTRY_FEATURE: countryFeature,
    FORMS: forms,
    COUNTRY: country,
    VALIDATION: validation,
    USER_PROFILE: userProfile,
    USER_ROLES: userRoles,
    PLATFORMS: platforms,
    EXPRESSIONS: expressions,
    GLOBAL_CONFIG: globalConfig,
    GLOBAL_2FA_SETTINGS: global2FASettings,
    ADMIN_AUDIT_TRAIL: adminAuditTrail,
    SCREEN: screen,
    NETWORK: network,
    FORM_INHERITANCE_FILTER: formInheritanceFilter,
    FORM_CATEGORY: formCategory,
    TAX: tax,
    COUNTRY_GROUP: countryGroup,
    ENVIRONMENT: environment,
    USER_PROFILE_ROLE_CUSTOM: userProfileRoleCustom,
    PRODUCT_CATEGORY: productCategory,
    SETTINGS: settings,
    NOTIFICATION: notification,
    INTEGRATION: integration,
    DATABASE: database,
    FILTER: filter,
    USAGE_HISTORY: usageHistory,
    SETTINGS_KEYS: settingsKeys,
    INTERCOM: intercom,
    PARTNER: partner,
    DELIVER_ADDRESS: deliveryAddress,
    SOCKET: socket,
    REQUEST_PROXY: requestProxy,
    INCREMENTAL_NUMBER: incrementalNumber,
    STARTING_PAGE: startingPage,
    STATUS: status,
    BUSINESS_CALCULATOR: businessCalculator,
    USER: user,
    NAVIGATION: navigation,
    WHITELABEL: whiteLabel,
    CUSTOMER_EMAIL_TEMPLATE_VALUES: customerEmailTemplate,
    ACCOUNT_TYPE: accountType,
};

export default CONSTANTS;
export const ENVELOPE_TYPE = envelopeType;
export const ADMIN_REPORT = adminReport;
export const ADMIN_FILTERS = adminFilters;
export const ACCOUNT_VERIFICATION = accountVerification;
export const ROLES = roles;
export const ARCHIVE = archive;
export const SYNC = sync;
export const LOGIN = login;
export const HTTP = http;
export const HTTP_REQUEST = httpRequest;
export const COMPANY = company;
export const CUSTOMER = customer;
export const COMPANY_CONNECTION = companyConnection;
export const SUBSCRIPTION = subscription;
export const DEVICE_TYPES = deviceTypes;
export const PARTNER_LOOKUP = partnerLookup;
export const MESSAGES = messages;
export const LAYOUT = layout;
export const LOGO = logo;
export const AUTO_COMPLETE = autoComplete;
export const EMAIL = email;
export const DOCUMENT = document;
export const CONTENT_TYPES = contentTypes;
export const CONTENT_TYPE_FORMATS = contentTypeFormats;
export const ACTION = action;
export const PAYMENT = payment;
export const PROJECT = project;
export const ERROR = error;
export const API_RESPONSES = apiResponses;
export const IMPORT_VALIDATION_ERRORS = importValidationErrors;
export const AUDIT = audit;
export const DATA_EMITTER_SERVICES = dataEmitterServices;
export const DATA_STRUCTURES = dataStructures;
export const OAUTH2 = oauth2;
export const CHAT = chat;
export const CIS = cis;
export const CONSUMPTION_EXTRA = consumptionExtra;
export const FILE_UPLOAD = fileUpload;
export const PURCHASES = purchases;
export const PURCHASE_SETUP = purchaseSetup;
export const PURCHASE_ACTION = purchaseAction;
export const REPORT = report;
export const WHAT_TO_EXPORT_VALUES = whatToExportValues;
export const SYSTEM_ATTACHMENT = systemAttachment;
export const APPLICATIONS = applications;
export const EVENTS = events;
export const EMAIL_VALIDATOR_MATCHER = emailValidationMatcher;
export const TEST_EMAILS_MATCHER = testEmailMatchers;
export const SOCIAL = social;
export const COHORTS = cohorts;
export const CONTACT_TO_ADDRESS = contactToAddress;
export const COUNTRY_FEATURE = countryFeature;
export const FORMS = forms;
export const COUNTRY = country;
export const VALIDATION = validation;
export const USER_PROFILE = userProfile;
export const USER_ROLES = userRoles;
export const PLATFORMS = platforms;
export const EXPRESSIONS = expressions;
export const GLOBAL_CONFIG = globalConfig;
export const GLOBAL_2FA_SETTINGS = global2FASettings;
export const ADMIN_AUDIT_TRAIL = adminAuditTrail;
export const NETWORK = network;
export const SCREEN = screen;
export const FORM_INHERITANCE_FILTER = formInheritanceFilter;
export const FORM_CATEGORY = formCategory;
export const TAX = tax;
export const COUNTRY_GROUP = countryGroup;
export const ENVIRONMENT = environment;
export const USER_PROFILE_ROLE_CUSTOM = userProfileRoleCustom;
export const PRODUCT_CATEGORY = productCategory;
export const SETTINGS = settings;
export const NOTIFICATION = notification;
export const INTEGRATION = integration;
export const DATABASE = database;
export const FILTER = filter;
export const USAGE_HISTORY = usageHistory;
export const SETTINGS_KEYS = settingsKeys;
export const INTERCOM = intercom;
export const DELIVERY_ADDRESS = deliveryAddress;
export const PARTNER = partner;
export const SOCKET = socket;
export const REQUEST_PROXY = requestProxy;
export const INCREMENTAL_NUMBER = incrementalNumber;
export const STATUS = status;
export const BUSINESS_CALCULATOR = businessCalculator;
export const USER = user;
export const NAVIGATION = navigation;
export const STARTING_PAGE = startingPage;
export const CUSTOMER_EMAIL_TEMPLATE_VALUES = customerEmailTemplate;
