import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useLayoutEffect, useRef, } from 'react';
import { Box } from '@mui/material';
import { omit } from 'lodash';
import { isOnSameDay, getFirstDayOfTheWeek, getLastDayOfTheWeek, addMonth, getFirstDayOfTheMonth, getLastDayOfTheMonth, } from '@powerednow/shared/modules/utilities/date';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CalendarDay from './CalendarDay';
import CalendarHeader from './CalendarHeader';
const useStyles = makeStyles(() => createStyles({
    root: {
        border: ({ border }) => border || 'solid 1px silver',
        borderRadius: 4,
    },
    calendarDaysContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    calendarDayContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: ({ cellWidth }) => cellWidth,
        height: ({ cellWidth }) => cellWidth,
    },
}));
function getDays(inputDate, firstDayOfTheWeek = 'monday') {
    const days = [];
    const firstDayOfTheMonth = getFirstDayOfTheMonth(inputDate);
    const lastDayOfTheMonth = getLastDayOfTheMonth(inputDate);
    const firstDate = getFirstDayOfTheWeek(firstDayOfTheMonth, firstDayOfTheWeek);
    const lastDate = getLastDayOfTheWeek(lastDayOfTheMonth, firstDayOfTheWeek);
    while (firstDate <= lastDate) {
        days.push(new Date(firstDate));
        firstDate.setDate(firstDate.getDate() + 1);
    }
    return days;
}
const isDayIncludedInDays = (day, dayArray) => {
    if (Array.isArray(dayArray)) {
        return dayArray.some(selectedDate => isOnSameDay(selectedDate.date, day));
    }
    return false;
};
export default function Calendar(props) {
    const { onSelectDay, onNextMonth, onPreviousMonth, selectedDays, enabledDays, date, firstDay, lastDay, firstDayOfTheWeek, width, border, style, color, } = props;
    const [days, setDays] = useState([]);
    const [initialDate, setInitialDate] = useState(firstDay ? new Date(firstDay) : new Date());
    const [cellWidth, setCellWidth] = useState(0);
    const classes = useStyles({ cellWidth, border });
    const containerRef = useRef(null);
    const updateDays = (forDate) => {
        setInitialDate(forDate);
        setDays(getDays(forDate, firstDayOfTheWeek || 'monday'));
    };
    useEffect(() => {
        const displayDate = date || new Date();
        updateDays(new Date(displayDate.getFullYear(), displayDate.getMonth(), 1));
    }, []);
    useEffect(() => {
        const firstDayDate = firstDay || new Date();
        updateDays(new Date(firstDayDate.getFullYear(), firstDayDate.getMonth(), 1));
    }, [firstDay]);
    useLayoutEffect(() => {
        const current = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current;
        if (current) {
            if (width) {
                setCellWidth(current ? width / 7 : 0);
            }
            else {
                setCellWidth(current ? current.getBoundingClientRect().width / 7 : 0);
            }
        }
    }, [width]);
    const calendarDays = (days.map(day => {
        const isSelected = selectedDays && isDayIncludedInDays(day, selectedDays);
        const isDisabled = enabledDays && !isDayIncludedInDays(day, enabledDays);
        return (_jsx(Box, { className: classes.calendarDayContainer, children: _jsx(CalendarDay, { day: day, calendarDate: initialDate, selected: isSelected, disabled: isDisabled, onClick: onSelectDay }) }, `day_${day}`));
    }));
    const shouldRenderExtraLine = days.length > 0 && days.length < 42;
    const isFirstDayOfTheWeekSet = firstDayOfTheWeek || 'monday';
    const emptyCalendarDay = (_jsx(Box, { className: classes.calendarDayContainer }));
    const handleNextMonthClick = onNextMonth || (() => updateDays(addMonth(initialDate, 1)));
    const handlePreviousMonthClick = onPreviousMonth || (() => updateDays(addMonth(initialDate, -1)));
    return (_jsx(Box, Object.assign({ className: classes.root }, omit(props, [
        'date',
        'label',
        'onNextMonth',
        'onSelectDay',
        'border',
        'onPreviousMonth',
        'sixWeekView',
        'selectedDays',
        'headerComponent',
        'enabledDays',
        'firstDay',
        'lastDay',
    ]), { children: _jsxs(Box, { ref: containerRef, sx: Object.assign(Object.assign({}, style), { position: 'relative', width }), children: [_jsx(CalendarHeader, { date: initialDate, onNextMonth: handleNextMonthClick, onPreviousMonth: handlePreviousMonthClick, color: color, firstDayOfTheWeek: isFirstDayOfTheWeekSet, firstDay: firstDay, lastDay: lastDay }), _jsxs(Box, { className: classes.calendarDaysContainer, children: [calendarDays, shouldRenderExtraLine && emptyCalendarDay] })] }) })));
}
