import React from 'react';
import AvailableAccountItem from '@features/auth/availableAccountList/availableAccountItem';
import { Box, BoxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import useUniqueAccounts from '@data/hooks/uniqueAccounts';

type Props = BoxProps & {
    selectedCompanyId?: number | null
}

const useStyles = makeStyles<Theme>(theme => createStyles({
    root: {
        overflowY: 'auto',
        maxHeight: '80vh',
    },
}));

const AvailableAccountList = React.forwardRef<HTMLLIElement, Props>((props, ref) => {
    const filteredAccounts = useUniqueAccounts();
    const classes = useStyles();

    const items = Object.entries(filteredAccounts).map(([key, value]) => (
        <AvailableAccountItem
            ref={ref}
            key={key}
            auth={{ portalId: key, data: value }}
            selected={filteredAccounts[key].companyId === props.selectedCompanyId}
        />
    ));
    return (<Box className={classes.root}>{items}</Box>);
});

export default AvailableAccountList;
