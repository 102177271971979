import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { isOnSameDay, isSameMonth, } from '@powerednow/shared/modules/utilities/date';
import Button from '../button/Button';
export default function CalendarDay(props) {
    const { selected, day, calendarDate, onClick, color, disabled, } = props;
    const isToday = isOnSameDay(new Date(), day);
    return (_jsx(Box, { display: "flex", flex: 1, justifyContent: "center", alignItems: "center", children: _jsx(Button, { emphasis: selected ? 'high' : 'low', buttonType: selected ? color : 'none', onClick: onClick ? () => onClick(day) : undefined, endIcon: null, disabled: !isSameMonth(day, calendarDate) || disabled, style: {
                width: '35px',
                height: '35px',
                padding: 0,
                fontWeight: isToday ? 'bold' : 'normal',
                border: isToday ? 'solid 1px silver' : 'initial',
            }, children: day === null || day === void 0 ? void 0 : day.getDate() }) }));
}
