import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(theme => createStyles({
    root: {
        marginTop: '3vh',
        [theme.breakpoints.down('lg')]: {
            height: '40vh',
        },
        [theme.breakpoints.up('md')]: {
            height: '50vh',
        },
    },
}));
export default function NotFound() {
    const classes = useStyles();
    return (_jsx(Typography, { variant: "h4", align: "center", className: classes.root, children: "Sorry, this page is no longer available" }));
}
