import React from 'react';
import FORMS from '@powerednow/shared/constants/forms';
import { useRecoilValue } from 'recoil';
import { type Filter } from '@powerednow/complexData/connectedData';
import useComplexData from '@data/hooks/complexDataHook';
import formListColumnConfig from '@features/form/list/formListColumnConfig';
import DataGridTabList, { ComplexTabListItems, TabListItemsParams } from '@features/dataGridTabList/DataGridTabList';
import { displayProjectsOnPortal } from '@data/state/companySettings';
import { ComplexModelEntity } from '@powerednow/models/modelLookup';

type FormDataType = ComplexModelEntity<'FormDocument'>;
type FormResult = {
    listItems: FormDataType[],
    error?: any
    loaded: boolean,
    total: number,
}

export const useForms = (params: TabListItemsParams<ComplexModelEntity<'FormDocument'>>): ComplexTabListItems<ComplexModelEntity<'FormDocument'>> => {
    const {
        contactId,
        pageSize,
        skip,
        sorters,
    } = params;
    const { optionalResult, error } = useComplexData(
        'Contact',
        [contactId, pageSize, skip, sorters],
        async (complexContact, resultSetter:(_newResult:FormResult)=>void) => {
            const complexCustomer = await complexContact.getCustomer();
            const filters: Filter<ComplexModelEntity<'FormDocument'>>[] = [{
                operator: '=',
                property: 'status',
                value: FORMS.STATUSES.SENT,
            }];
            const total = await complexCustomer.getFormDocumentCount(filters);
            const complexFormDocuments = await complexCustomer.getAllFormDocument({
                skip,
                filters,
                limit: pageSize,
                sorters,
            });
            resultSetter({
                total,
                loaded: true,
                listItems: complexFormDocuments.map(formDocument => formDocument.data.getPureDataValues()),
            });
        },
    );

    return {
        listItems: optionalResult?.listItems || [],
        loaded: optionalResult?.loaded || false,
        total: optionalResult?.total || 0,
        error,
    };
};

export default function FormList() {
    const rowHeight = 80;
    const height = 510;
    const sortModel = [
        { field: 'dt_created', sort: 'desc' } as const,
    ];

    const displayProjects = useRecoilValue(displayProjectsOnPortal);
    const columns = formListColumnConfig(displayProjects);

    return (
        <DataGridTabList
            columns={columns}
            useGetListItems={useForms}
            sortModel={sortModel}
            rowHeight={rowHeight}
            height={height}
            noRowsText="Sorry, there are no documents to display"
            tabToNavigate="form"
        />
    );
}
