import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { TryAgainFallbackModal } from './TryAgainFallback';
const defaultContext = {
    redirectPath: '/',
};
export const ErrorContext = createContext(defaultContext);
export default function ErrorBoundaryWithTryAgainModal(_a) {
    var { children } = _a, args = __rest(_a, ["children"]);
    return (_jsx(ErrorBoundary, Object.assign({ FallbackComponent: TryAgainFallbackModal }, args, { children: children })));
}
export function ErrorBoundaryRedirect(_a) {
    var { children } = _a, args = __rest(_a, ["children"]);
    return (_jsx(ErrorContext.Provider, { value: defaultContext, children: _jsx(ErrorBoundaryWithTryAgainModal, Object.assign({}, args, { children: children })) }));
}
