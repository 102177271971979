import useComplexData from '@data/hooks/complexDataHook';
import htmlDecode from '@components/helper/htmlDecode';
import { ComplexModelEntity } from '@powerednow/models/modelLookup';

const useCompanyDetails = (companyId: number | null) => {
    const { details, optionalResult } = useComplexData(
        'Company',
        companyId,
        async (complexCompany, resultSetter:(_newResult: {companyAddress: ComplexModelEntity<'CompanyAddress'>})=>void) => {
            const complexAddress = await complexCompany.getCompanyAddress();
            resultSetter({ companyAddress: complexAddress?.data?.getPureDataValues() });
        },
    );
    if (details) {
        // TODO: consider decoding all fields what may be affected by htmlDecode
        details.name = htmlDecode(details?.name);
    }
    return {
        details,
        companyAddress: optionalResult?.companyAddress,
    };
};

export default useCompanyDetails;
