export default {
    LINK_TYPE: {
        Document: {
            code: 1,
            event_type: {
                DOC_EDIT: 'docEdit',
                DOC_SENT: 'docSent',
                ITEM_ADDED: 'itemAdded',
                ITEM_DELETED: 'itemDeleted',
                DOC_PRINTED: 'docPrinted',
                RECEIPT_SENT: 'receiptSent',
                CHASING_DISABLED: 'chasingDisabled',
                CHASING_ENABLED: 'chasingEnabled',
            },
        },
        Message: {
            code: 2,
            event_type: {
                1: 'messageSent',
            },
        },
        Payment: {
            code: 3,
            event_type: {
                1: 'paymentRecorded',
                2: 'paymentRefunded',
                3: 'paymentReceived',
            },
        },
        FormDocument: {
            code: 4,
            event_type: {
                DOC_PRINTED: 'docPrinted',
            },
        },
    },
};
