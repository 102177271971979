import { jsx as _jsx } from "react/jsx-runtime";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Button from './Button';
const useStyles = makeStyles(() => createStyles({
    actionButton: {
        padding: '1rem 1rem',
    },
}));
export default function ActionButton({ children, href, onClick, enabled = true, color, fullWidth, startIcon, endIcon, emphasis, }) {
    const classes = useStyles();
    return (_jsx(Button, { className: classes.actionButton, size: "large", onClick: onClick, buttonType: color || 'secondary', href: href, disabled: !enabled, fullWidth: fullWidth, startIcon: startIcon, endIcon: endIcon, emphasis: emphasis, children: children }));
}
