import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Box } from '@mui/material';
import chroma from 'chroma-js';
const useStyles = makeStyles(theme => createStyles({
    root: {
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0, 0.1)',
    },
    fullScreen: {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
    },
    noSelect: {
        'user-select': 'none',
        '-moz-user-select': 'none',
        '-khtml-user-select': 'none',
        '-webkit-user-select': 'none',
        '-o-user-select': 'none',
    },
}));
export default function Mask(_a) {
    var { backgroundColor, blur, opacity, show, children, zIndex, fullScreen, overflow, borderRadius, style, content, className } = _a, boxProps = __rest(_a, ["backgroundColor", "blur", "opacity", "show", "children", "zIndex", "fullScreen", "overflow", "borderRadius", "style", "content", "className"]);
    const classes = useStyles();
    const baseClass = fullScreen ? classes.fullScreen : classes.root;
    const overflowValue = fullScreen ? 0 : (overflow || 0);
    const position = {
        left: -overflowValue,
        top: -overflowValue,
        right: -overflowValue,
        bottom: -overflowValue,
    };
    const opacityValue = opacity === undefined ? 0.1 : opacity;
    let bakgroundColorValue = backgroundColor || 'black';
    if (chroma.valid(bakgroundColorValue)) {
        bakgroundColorValue = chroma(bakgroundColorValue || 'black').alpha(opacityValue).hex();
    }
    return (_jsxs(Box, Object.assign({ position: "relative", minHeight: 0, minWidth: 0 }, boxProps, { children: [children, _jsx(Box, { display: show ? 'flex' : 'none', justifyContent: "center", alignItems: "center", className: clsx(className, baseClass, classes.blur, classes.noSelect), flexDirection: "column", style: Object.assign(Object.assign(Object.assign({}, position), { borderRadius: (borderRadius || 0), zIndex: zIndex || 100, backdropFilter: blur && blur >= 0 ? `blur(${blur}px)` : 'none', backgroundColor: bakgroundColorValue }), style), children: content })] })));
}
