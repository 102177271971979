import useComplexData from '@data/hooks/complexDataHook';

export const useOnlineBookable = (companyId: number | null) => {
    const { optionalResult } = useComplexData(
        'Company',
        companyId,
        async (complexCompany, resultSetter: (_newResult: {
            loaded: boolean;
            isOnlineBookable: boolean;
        }) => void) => {
            const isOnlineBookable = await complexCompany?.hasBookableAppointmentSlots();
            resultSetter({
                loaded: true,
                isOnlineBookable,
            });
        },
    );

    return {
        loaded: optionalResult?.loaded || false,
        isOnlineBookable: optionalResult?.isOnlineBookable || false,
    };
};

export const useContactDetails = (contactId: number | null) => {
    const { details, optionalResult } = useComplexData(
        'Contact',
        contactId,
        async (complexContact, resultSetter: (_newResult: {
            hasAccount: boolean;
        }) => void) => {
            const hasAccount = await complexContact.hasRegisteredUser();
            resultSetter({
                hasAccount,
            });
        },
    );
    return {
        contactDetails: details,
        hasAccount: optionalResult?.hasAccount,
    };
};
