import { promptQuery, companyQuoteNameId } from '@data/state/prompt';
import useComplexData from '@data/hooks/complexDataHook';
import { authState } from '@data/state/auth';
import { useRecoilValue, useRecoilState } from 'recoil';

const applyTemplate = (sourceString: string, templateData?: object): string => {
    if (!templateData) {
        return sourceString;
    }

    const regex = /{.*?}/gm;
    let stringCopy = sourceString;
    let m = regex.exec(stringCopy);
    
    while (m !== null) {
        if (m.index === regex.lastIndex) {
            regex.lastIndex += 1;
        }

        // eslint-disable-next-line no-loop-func
        m.forEach(match => {
            stringCopy = stringCopy.replace(match, templateData[match.slice(1, -1)]);
        });

        m = regex.exec(stringCopy);
    }

    return stringCopy;
};

const setQuoteName = () => {
    const authData = useRecoilValue(authState);
    const { data: { companyId } } = authData;
    const [quoteNameId, setQuoteNameId] = useRecoilState(companyQuoteNameId);

    useComplexData(
        'Company',
        companyId,
        async complexCompany => {
            setQuoteNameId(complexCompany.data.quote_name_id);
        },
    );
    return quoteNameId;
};

const usePrompt = () => {
    const promptData = useRecoilValue(promptQuery);
    const quoteNameId = setQuoteName();

    return (code: string, defaultValue: string, templateData?: object) => {
        let record = null;

        if (quoteNameId === 2) {
            // find the estimated version
            record = promptData.find(item => item.code === `${code}_est`);
        }

        if (!record) {
            // find the normal version (also if estimated not exists
            record = promptData.find(item => item.code === code);
        }

        let finalValue = defaultValue;

        if (!record) {
            // this is just a sign for us, this is the similar way as app do
            console.log(`Error! Wrong promptId.: ${quoteNameId === 2 ? `${code}_est or ${code}` : code}, default value: ${defaultValue}`);
        } else {
            finalValue = (record as any).value;
        }

        if (templateData) {
            return applyTemplate(finalValue, templateData);
        }

        return finalValue;
    };
};

export default usePrompt;
