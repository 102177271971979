import React from 'react';
import ActionButton from '@components/button/ActionButton';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

type DownloadButtonProps = {
    url: string,
    fileName: string,
    children?: React.ReactNode,
    startIcon?: React.ReactNode,
    endIcon?: React.ReactNode,
    emphasis?: 'high' | 'medium' | 'low',
    hideDefaultText?: boolean,
};

const useStyles = makeStyles<Theme>(() => createStyles({
    root: {
        textDecoration: 'inherit',
        color: 'inherit',
        width: '100%',
    },
}));

export default function DownloadButton(props: DownloadButtonProps) {
    const {
        url, fileName, startIcon, endIcon, children, emphasis, hideDefaultText,
    } = props;
    const classes = useStyles();

    return (
        <a
            className={classes.root}
            href={url}
            download={fileName}
            target="_blank"
            rel="noreferrer"
            aria-label="download"
        >  
            <ActionButton emphasis={emphasis} startIcon={startIcon} endIcon={endIcon} fullWidth color="primary">
                {children || (!hideDefaultText && 'Download')}
            </ActionButton>
        </a>
    );
}
