import React from 'react';

import useComplexData from '@data/hooks/complexDataHook';
import appointmentListColumns from '@features/appointment/list/appointmentListColumnConfig';
import DataGridTabList, { ComplexTabListItems, gridFilterToComplexFilter, TabListItemsParams } from '@features/dataGridTabList/DataGridTabList';
import { ComplexModelEntity } from '@powerednow/models/modelLookup';
import converterUtils from '@powerednow/shared/dist/modules/utilities/converter';
import { SETTINGS_KEYS } from '@powerednow/shared/constants';
import { displayProjectsOnPortal } from '@data/state/companySettings';
import { useRecoilValue } from 'recoil';

type AppointmentResult = {
    listItems: ComplexModelEntity<'ActionsForCustomer'>[],
    loaded: boolean,
    total: number,
    error?: any,
}

const useAppointments = (params: TabListItemsParams<ComplexModelEntity<'Action'>>): ComplexTabListItems<ComplexModelEntity<'Action'>> => {
    const {
        contactId,
        pageSize,
        skip,
        sorters,
        filters,
    } = params;
    const {
        optionalResult,
        error,
    } = useComplexData(
        'Contact',
        [contactId, pageSize, skip, sorters, filters],
        async (complexContact, resultSetter: (_newResult: AppointmentResult) => void) => {
            const complexCustomer = await complexContact?.getCustomer();
            const company = await complexCustomer?.getCompany();
            const sentOnly = converterUtils.stringToBoolean(await company?.getSettingValue(SETTINGS_KEYS.DISPLAY_SENT_APPOINTMENTS_ONLY_ON_PORTAL));
            const complexFilters = (filters?.items || []).map(gridFilterToComplexFilter);
            if (sentOnly) {
                // @ts-ignore
                complexFilters.push({ property: 'is_sent', operator: '=', value: true });
            }
            // @ts-ignore
            const total = await complexCustomer?.getAppointmentForCustomerCount(complexFilters);
            const complexAppointments = await complexCustomer?.getAllAppointmentForCustomer({
                skip,
                limit: pageSize,
                sorters,
                // @ts-ignore
                filters: complexFilters,
            });
            resultSetter({
                total,
                loaded: true,
                listItems: complexAppointments.map(appointment => appointment.data.getPureDataValues()),
            });
        },
    );

    return {
        listItems: optionalResult?.listItems || [],
        loaded: optionalResult?.loaded || false,
        total: optionalResult?.total || 0,
        error,
    };
};

function AppointmentsList() {
    const displayProjects = useRecoilValue(displayProjectsOnPortal);
    const columns = appointmentListColumns(displayProjects);

    const noRowsText = 'Sorry, there are no appointments to display';
    const rowHeight = 80;
    const height = 510;
    const sortModel = [
        { field: 'dt_start', sort: 'desc' } as const,
    ];

    return (
        <DataGridTabList
            columns={columns}
            useGetListItems={useAppointments}
            sortModel={sortModel}
            rowHeight={rowHeight}
            height={height}
            noRowsText={noRowsText}
            tabToNavigate="appointment"
        />
    );
}

export const MemoizeList = React.memo(AppointmentsList);
export default MemoizeList;
