/**
 * TextUtils module definition
 *
 * Collection of text-manipulation functions
 *
 */

// Node modules
import stripTags from 'striptags';
import moment from './momentOverride';

const path = require('path');
const Bluebird = require('bluebird');
const TinyURL = require('tinyurl');
const crypto = require('crypto');
const util = require('util');
const { v4: uuidv4 } = require('uuid');

let _shortenerLastUsageTimestamp = new Date().getTime();

const textUtils = {
    ucFirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },

    lcFirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },

    toCamelCase(string) {
        return string.toLowerCase().replace(/_(.)/g, (match, group1) => group1.toUpperCase());
    },

    capitalize(string) {
        return string
            .split(' ')
            .map(word => textUtils.ucFirst(word))
            .join(' ');
    },

    replaceUrlsToShortened(inputText) {
        let text = inputText;
        let longUrls: RegExpMatchArray | Array<string> = String(text).match(/\b(([\w-]+:\/\/?|www[.])[^\s()<>]+(?:\([\w\d]+\)|([^!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~\s]|\/)))/ig);

        if (longUrls && longUrls.length > 0) {
            //
            // Already shortened urls should not be shortened again
            //
            longUrls = longUrls.filter(url => !(/^https?:\/\/tinyurl\.com\//.test(url)));
        }

        if (longUrls && longUrls.length > 0) {
            return Bluebird.each(longUrls, longUrl => this._shortener(longUrl)
                .then(result => {
                    text = text.replace(longUrl, result);
                    return text;
                }))
                .then(() => text);
        }
        return Bluebird.resolve(text);
    },

    _tinyUrl(longUrl) {
        return TinyURL.shorten(longUrl);
    },

    replaceTagByString(originalText, replaceTagObject) {
        const re = new RegExp(Object.keys(replaceTagObject).join('|'), 'gi');
        return stripTags(originalText.replace(re, matched => replaceTagObject[matched]));
    },

    urlShortener(url) {
        return this._shortener(url, 2);
    },

    _shortener(longUrl, recursionCount) {
        const self = this;

        const _minimalDelay = 1000;
        const _timestamp = new Date().getTime();
        const _timeDifference = _timestamp - _shortenerLastUsageTimestamp;
        let _delay = 0;

        if (_timeDifference < _minimalDelay) {
            _delay = _minimalDelay - _timeDifference;
        }

        return Bluebird.delay(_delay)
            .then(() => self._tinyUrl(longUrl))
            .then(result => {
                _shortenerLastUsageTimestamp = new Date().getTime();
                return result;
            })
            .catch(err => {
                if (parseInt(err.code, 10) === 403) {
                    if (recursionCount > 2) {
                        return Bluebird.reject(new Error('Too much recursion'));
                    }
                    return self._shortener(longUrl, (recursionCount || 0) + 1);
                }
                throw err;
            });
    },
    getExtensionFromFileName(fileName) {
        if (!fileName) return '';
        return path.extname(fileName).replace(/^\./, '');
    },

    /**
     * Generate random hex value
     * @param len
     * @returns
     */
    randomValueHex(len) {
        return crypto.randomBytes(Math.ceil(len / 2))
            .toString('hex')
            .slice(0, len);
    },

    /**
     * Extend util.format() with the feature to convert all dates to
     * 'moment.fromNow()' strings, if needed.
     *
     * usage: textUtils.formatFromNow('date: %s', {date:new Date(), fromNow: true})
     *        => 'date: a few seconds ago'
     *
     */
    formatFromNow(...args) {
        const mappedArgs = args.map(elem => {
            //
            // Use moment.fromNow() on date if actual element has a `date` property
            // and a `fromNow` property which is true.
            //

            if (elem.date && elem.fromNow) {
                return moment(elem.date).utc().from(moment().utc());
            }

            return elem;
        });

        return util.format(...mappedArgs);
    },

    formatDateTime(dateTime, format = 'DD/MM/YY') {
        return moment(dateTime).format(format.replace('d', 'DD').replace('m', 'MM').replace('H', 'HH').replace('i', 'mm'));
    },

    stripTags(text: string) {
        return text.replace(/<(?:.|\n)*?>/gm, '');
    },

    formatMoney(
        num,
        options: { decimal: number, decimalsep: string, thousandsep: string, symbol: string, symbol_before?: boolean } = {
            decimal: 2,
            decimalsep: '.',
            thousandsep: ',',
            symbol: '£',
            symbol_before: true,
        },
    ) {
        let c = options.decimal;
        let d = options.decimalsep;
        let t = options.thousandsep;
        const sym = options.symbol;
        const symbolBefore = options.symbol_before || true;

        let n = num;
        c = Math.abs(c);
        c = Number.isNaN(c) ? 2 : c;
        d = d === undefined ? ',' : d;
        t = t === undefined ? '.' : t;
        const s = n < 0 ? '-' : '';
        const iNum = parseInt(n = Math.abs(+n || 0).toFixed(c), 10);
        const i = `${iNum}`;
        const j = (i.length > 3) ? (i.length % 3) : 0;

        return s + (symbolBefore ? sym : '') + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) + (c ? d + Math.abs(n - iNum).toFixed(c).slice(2) : '') + (symbolBefore ? '' : sym);
    },

    generateInvitationHash(companyId1, companyId2) {
        const ids = [parseInt(companyId1, 10), parseInt(companyId2, 10)].sort();
        return textUtils.hash(`${ids[0]}-${ids[1]}-`);
    },

    hash(message) {
        return crypto.createHmac('sha256', 'fe3k3rjf20dqw').update(message.toString()).digest('hex');
    },

    generateUniqueId() {
        //
        // The it structure is the following:
        //
        // ccccc-xxxxV-xxxxV-xxxxV
        //
        // Where:
        // - ccccc is the company id padded with zeros
        // - xxxx * 3 is a random number derived from the built in UUID generator
        // - V is a checksum calculated for each part
        //
        const uuid = uuidv4();
        const rndint = parseInt(uuid.substr(-12, 12), 16);
        const strInt = String(rndint * 31).substr(-12, 12);
        const checkSum = this.generateChecksum(strInt);
        return checkSum;
    },

    generateChecksum(num) {
        //
        // Divide up to 4 digits long parts
        //
        const parts = String(num).match(/\d{4}/g);
        //
        // Calculate the checksum on each part
        //
        // console.log(parts);
        parts.forEach((part, index) => {
            // console.log(part.getLuhn());
            parts[index] = this.getLuhn(part);
        });
        const retValue = parts.join('');
        //
        // Return the part
        //
        return retValue;
    },

    getLuhn(inputString) {
        const luhnArr = [[0, 1, 2, 3, 4, 5, 6, 7, 8, 9], [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]];
        let sum = 0;
        inputString.replace(/\D+/g, '').replace(/[\d]/g, (c, p, o) => {
            // eslint-disable-next-line no-bitwise
            sum += luhnArr[(o.length - p) & 1][parseInt(c, 10)];
        });
        const checksum = (10 - (sum % 10)) % 10;
        return inputString + checksum.toString();
    },

    isUKPostcode(value: string) {
        // eslint-disable-next-line max-len
        const postCodeRegex = /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;
        return value.match(postCodeRegex);
    },
};

export default textUtils;
