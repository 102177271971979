import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
export const useWidth = () => {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys];
    return keys.filter(key => useMediaQuery(theme.breakpoints.up(key)));
};
export const useResponsive = () => {
    const themeWidth = useWidth();
    const theme = useTheme();
    return (props, fallback) => {
        let result = fallback || null;
        theme.breakpoints.keys.forEach(key => {
            if (themeWidth.includes(key) && Object.prototype.hasOwnProperty.call(props, key)) {
                result = props[key];
            }
        });
        return result;
    };
};
export const useResponsiveFontSize = () => {
    const responsive = useResponsive();
    return responsive({ xs: theme => theme.poweredNowTypography.fontSize.small, sm: theme => theme.poweredNowTypography.fontSize.base });
};
