import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import usePagination from '@mui/material/usePagination';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box } from '@mui/material';
import { omit } from 'lodash';
import clsx from 'clsx';
import Button from '../button/Button';
const getSizes = (theme, baseSize, margin) => ({
    width: theme.spacing(baseSize),
    height: theme.spacing(baseSize),
    minWidth: theme.spacing(baseSize),
    minHeight: theme.spacing(baseSize),
    margin: `0 ${theme.spacing(0.5 * margin)}`,
});
const useStyles = makeStyles(theme => createStyles({
    container: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },
    small: getSizes(theme, 8.5, 1),
    medium: getSizes(theme, 9, 1.5),
    large: getSizes(theme, 10.5, 2),
    rounded: {
        borderRadius: '20%',
    },
    grayed: {
        border: `solid 1px ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.15)' : 'rgba(0,0,0,0.15)'}`,
        color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)',
    },
    noBackground: {
        backgroundColor: `${theme.palette.background.paper} !important`,
    },
    noBorder: {
        border: 'none',
    },
}));
export default function Pagination(props) {
    const classes = useStyles();
    const { items } = usePagination(props);
    const { size, emphasis, color } = props;
    const sizeValue = size || 'medium';
    const getSeparator = item => {
        const sizeClass = classes === null || classes === void 0 ? void 0 : classes[sizeValue];
        const shapeClass = classes === null || classes === void 0 ? void 0 : classes.rounded;
        const borderClass = emphasis === 'low' ? classes.noBorder : null;
        const classNames = clsx([sizeClass, shapeClass, classes.grayed, classes.noBackground, borderClass]);
        return (_jsx(Button, Object.assign({ endIcon: null, emphasis: emphasis }, item, { className: classNames, disabled: true, children: "..." })));
    };
    const getNavigationButtons = (type, item) => {
        const sizeClass = classes === null || classes === void 0 ? void 0 : classes[sizeValue];
        const shapeClass = classes.rounded;
        const borderClass = emphasis === 'low' ? classes.noBorder : null;
        const classNames = clsx([sizeClass, shapeClass, classes.grayed, classes.noBackground, borderClass]);
        return (_jsx(Button, Object.assign({ buttonType: color, emphasis: emphasis, className: classNames }, item, { startIcon: type === 'next' ? _jsx(ChevronRight, {}) : _jsx(ChevronLeft, {}) })));
    };
    const getButton = (item, page, selected) => {
        const sizeClass = classes === null || classes === void 0 ? void 0 : classes[sizeValue];
        const shapeClass = classes === null || classes === void 0 ? void 0 : classes.rounded;
        const borderClass = emphasis === 'low' ? classes.noBorder : null;
        let classNames = clsx([sizeClass, shapeClass]);
        if (!selected) {
            classNames = clsx([classNames, classes.grayed, classes.noBackground, borderClass]);
        }
        return (_jsx(Button, Object.assign({ buttonType: color, endIcon: null, emphasis: emphasis, className: classNames }, item, { children: page })));
    };
    return (_jsx(Box, Object.assign({ display: "flex", flexDirection: "row" }, omit(props, ['siblingCount']), { children: _jsx(Box, { display: "flex", className: classes.container, flexDirection: "row", children: items.map((_a, index) => {
                var { page, type, selected } = _a, item = __rest(_a, ["page", "type", "selected"]);
                let children;
                if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                    children = getSeparator(item);
                }
                else if (type === 'page') {
                    children = getButton(item, page, selected);
                }
                else {
                    children = getNavigationButtons(type, item);
                }
                return _jsx("div", { children: children }, index);
            }) }) })));
}
