import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Typography, Paper, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import chroma from 'chroma-js';
import clsx from 'clsx';
const useStyles = makeStyles(theme => createStyles({
    tipIcon: {
        borderRadius: '50%',
        padding: '0.3rem',
        fontSize: '3.5rem',
        marginRight: '1rem',
    },
    tipHeader: {
        lineHeight: '1.5rem',
    },
    tipContent: {
        marginLeft: '4.5rem',
        color: theme.palette.grey[700],
    },
    noIcon: {
        marginLeft: 'initial',
    },
}));
export default function TipBox(_a) {
    var { icon, title, content, elevation, color } = _a, boxProps = __rest(_a, ["icon", "title", "content", "elevation", "color"]);
    const classes = useStyles();
    const theme = useTheme();
    const definedColor = color || 'secondary';
    const iconComponent = icon
        ? React.cloneElement(icon, {
            color: definedColor,
            className: classes.tipIcon,
            style: {
                color: definedColor === 'neutral'
                    ? chroma(theme.palette[definedColor].main).darken(1).hex()
                    : theme.palette[definedColor].main,
                backgroundColor: definedColor === 'neutral'
                    ? chroma(theme.palette[definedColor].main).darken(0.1).hex()
                    : chroma(theme.palette[definedColor].main).alpha(0.2).hex(),
            },
        })
        : null;
    return (_jsx(Box, Object.assign({}, boxProps, { children: _jsx(Paper, { elevation: elevation !== undefined ? elevation : 2, children: _jsxs(Box, { flexDirection: "column", p: 6, pl: icon ? 3 : 6, pt: icon ? 3 : 6, children: [_jsxs(Box, { display: "flex", alignItems: "center", flexDirection: "row", pb: 2, children: [iconComponent, _jsx(Typography, { variant: "h5", className: classes.tipHeader, children: title })] }), _jsx(Box, { pt: 2, children: _jsx(Typography, { className: clsx(classes.tipContent, icon ? null : classes.noIcon), children: content }) })] }) }) })));
}
