import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, useState } from 'react';
import { ThemeProvider, StyledEngineProvider, } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import * as _ from 'lodash';
import MATERIAL_THEME from '@powerednow/shared/constants/materialTheme';
import { PowerednowLightTheme } from './Powerednow/stylesheet';
import { PortalDarkTheme, PortalLightTheme } from './Portal/stylesheet';
import { defaultDarkTheme, defaultLightTheme } from './Default/stylesheet';
export const ThemeContext = createContext({
    themeName: `${MATERIAL_THEME.AVAILABLE_THEMES.DEFAULT_THEME}--light`,
    switchTheme: () => `${MATERIAL_THEME.AVAILABLE_THEMES.DEFAULT_THEME}--light`,
});
const themeMap = {
    [`${MATERIAL_THEME.AVAILABLE_THEMES.DEFAULT_THEME}--light`]: defaultLightTheme,
    [`${MATERIAL_THEME.AVAILABLE_THEMES.DEFAULT_THEME}--dark`]: defaultDarkTheme,
    [`${MATERIAL_THEME.AVAILABLE_THEMES.PORTAL_THEME}--light`]: PortalLightTheme,
    [`${MATERIAL_THEME.AVAILABLE_THEMES.PORTAL_THEME}--dark`]: PortalDarkTheme,
    [`${MATERIAL_THEME.AVAILABLE_THEMES.POWEREDNOW_APP_THEME}--light`]: PowerednowLightTheme,
};
export function ThemeContextProvider(props) {
    const { children, customThemeName } = props;
    const currentTheme = localStorage.getItem('appTheme') || `${MATERIAL_THEME.AVAILABLE_THEMES.DEFAULT_THEME}--light`;
    const [theme, setTheme] = useState(customThemeName || currentTheme);
    const [themeName, setThemeName] = useState(customThemeName || currentTheme);
    const onSwitchTheme = name => {
        setTheme(name);
        setThemeName(name);
        localStorage.setItem('appTheme', name);
    };
    const contextValue = React.useMemo(() => ({
        themeName,
        switchTheme: onSwitchTheme,
    }), [themeName]);
    return (_jsx(ThemeContext.Provider, { value: contextValue, children: _jsx(StyledEngineProvider, { injectFirst: true, children: _jsxs(ThemeProvider, { theme: _.cloneDeep(themeMap[theme]), children: [_jsx(CssBaseline, {}), children] }) }) }));
}
