import { jsx as _jsx } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(theme => createStyles({
    root: {
        backgroundColor: theme.palette.backgroundColor,
    },
}));
function BasePage({ containerMaxWidth, pt, pr, pb, pl, children, paperElevation, contentBackground, }) {
    const classes = useStyles();
    return (_jsx(Box, { pt: pt, pr: pr, pb: pb, pl: pl, className: classes.root, children: _jsx(Container, { maxWidth: containerMaxWidth, children: _jsx(Paper, { elevation: paperElevation, children: _jsx(Box, { bgcolor: contentBackground, children: children }) }) }) }));
}
export default BasePage;
