import { ValueOf } from '@powerednow/type-definitions';
import { FEATURES } from '@powerednow/shared/constants/subscription';

export const TYPES_VALUES = {
    NONE: -1,
    INVOICE: 0,
    QUOTE: 1,
    WORKSHEET: 2,
    CREDITNOTE: 3,
    SIGNATURE: 4,
    SUPPLIER_INVOICE: 5,
    SUPPLIER_INVOICE_CREDIT: 6,
    EXPENSE: 7,
    EXPENSE_CREDITNOTE: 8,
    PURCHASE_ORDER: 9,
} as const;

export const TYPE_PERMISSION_MAP = {
    [TYPES_VALUES.INVOICE]: FEATURES.CAN_CREATE_INVOICE,
    [TYPES_VALUES.EXPENSE]: FEATURES.CAN_CREATE_EXPENSE,
    [TYPES_VALUES.SUPPLIER_INVOICE]: FEATURES.CAN_CREATE_SUPPLIER_INVOICE,
    [TYPES_VALUES.PURCHASE_ORDER]: FEATURES.CAN_CREATE_SUPPLIER_INVOICE,
    [TYPES_VALUES.QUOTE]: FEATURES.CAN_CREATE_QUOTE,
    [TYPES_VALUES.CREDITNOTE]: FEATURES.CAN_CREATE_CREDITNOTE,
    [TYPES_VALUES.SUPPLIER_INVOICE_CREDIT]: FEATURES.CAN_CREATE_SUPPLIER_INVOICE_CREDIT,
    [TYPES_VALUES.EXPENSE_CREDITNOTE]: FEATURES.CAN_CREATE_EXPENSE_CREDITNOTE,
    [TYPES_VALUES.WORKSHEET]: FEATURES.CAN_USE_JOBSHEETS,
    [TYPES_VALUES.SIGNATURE]: FEATURES.CAN_CREATE_SIGNATURE,
};

export type TYPES_VALUES_Type = ValueOf<typeof TYPES_VALUES>

const DOCUMENT_GROUP_TYPE_ID = {
    SALES: 0,
    SUPPLIER: 1,
};
const DOCUMENT_PAYMENT_CATEGORIES = {
    SALES: 1,
    SUPPLIER: 2,
    EXPENSE: 3,
} as const;

export type StatusStringDefinition = {
    promptId: string
    defaultValue: string
};

const DOCUMENT_TYPE_TO_DOCUMENT_PAYMENT_CATEGORY = {
    [TYPES_VALUES.INVOICE]: DOCUMENT_PAYMENT_CATEGORIES.SALES,
    [TYPES_VALUES.CREDITNOTE]: DOCUMENT_PAYMENT_CATEGORIES.SALES,
    [TYPES_VALUES.SUPPLIER_INVOICE]: DOCUMENT_PAYMENT_CATEGORIES.SUPPLIER,
    [TYPES_VALUES.SUPPLIER_INVOICE_CREDIT]: DOCUMENT_PAYMENT_CATEGORIES.SUPPLIER,
    [TYPES_VALUES.EXPENSE]: DOCUMENT_PAYMENT_CATEGORIES.EXPENSE,
    [TYPES_VALUES.EXPENSE_CREDITNOTE]: DOCUMENT_PAYMENT_CATEGORIES.EXPENSE,
} as const;

export const DOCUMENT_TO_DOCUMENT_TYPES = {
    INVOICE_TO_QUOTE: 'InvoiceToQuote',
    PARENT_DOCUMENT: 'ParentDocument',
};

export const CHASE_DATE_BY_NAME = [
    { text: '1 week', value: 7 },
    { text: '2 weeks', value: 2 * 7 },
    { text: '3 weeks', value: 3 * 7 },
    { text: '4 weeks', value: 4 * 7 },
    { text: '6 weeks', value: 6 * 7 },
    { text: '2 months', value: 61 },
    { text: '3 months', value: 92 },
];

export const CHASE_DATE_BY_VALUE = CHASE_DATE_BY_NAME.reduce((acc, { text, value }) => {
    acc[value] = text;
    return acc;
}, {});

export default {
    TYPES_VALUES,
    DOCUMENT_GROUP_TYPE_ID,
    CHASE_DATE_BY_NAME,
    CHASE_DATE_BY_VALUE,
    TYPES: {
        0: 'invoice',
        1: 'quote',
        2: 'worksheet',
        3: 'creditnote',
        4: 'signature',
        5: 'supplier_invoice',
        6: 'supplier_invoice_credit',
        7: 'expense',
        8: 'expense_creditnote',
        9: 'purchase_order',
    } as const,

    STATUS_STRING: {
        UNAPPROVED: { promptId: 'Document.StatusUnapproved', defaultValue: 'Unapproved' },
        RAISED: { promptId: 'Document.StatusPending', defaultValue: 'Raised' },
        QUOTE_SENT: { promptId: 'Document.StatusSentQuote', defaultValue: 'Sent' },
        SENT: { promptId: 'Document.StatusSent', defaultValue: 'Sent' },
        EXPENSE_ACCEPTED: { promptId: 'Document.StatusExpenseAccepted', defaultValue: 'Approved' },
        ACCEPTED: { promptId: 'Document.StatusAccepted', defaultValue: 'Client Accepted' },
        EXPENSE_REJECTED: { promptId: 'Document.StatusExpenseRejected', defaultValue: 'Rejected' },
        REJECTED: { promptId: 'Document.StatusRejected', defaultValue: 'Client Rejected' },
        REFUNDED: { promptId: 'Document.StatusRefunded', defaultValue: 'Refunded' },
        PAID: { promptId: 'Document.StatusPaid', defaultValue: 'Paid' },
        PARTIALLY_PAID: { promptId: 'Document.StatusPartiallyPaid', defaultValue: 'Part Paid' },
        OVER_PAID: { promptId: 'Document.StatusExcessivePayment', defaultValue: 'Over Paid' },
        PENDING_PAYMENT: { promptId: 'Document.PendingPayment', defaultValue: 'Pending Payment' },
    },

    STATUS: {
        RAISED: 0,
        SENT: 1,
        ACCEPTED: 2,
        REJECTED: 3,
        PAID: 4,
        REFUNDED: 4,
        PART_PAID: 5,
        OVER_PAID: 6,
    },

    PREFIXES: {
        EXPENSE: 'EXP',
        WORKSHEET: 'WS',
        INVOICE: 'INV',
        QUOTE: 'QTE',
        EST: 'EST',
        CREDITNOTE: 'CR-',
        SIGNATURE: 'SG-',
        EXPENSE_CREDITNOTE: 'CR-',
        SUPPLIER_INVOICE: 'SUP',
        SUPPLIER_INVOICE_CREDIT: 'SUP-CR',
        PURCHASE_ORDER: 'POR',
    },
    DEFAULT_DISPLAY_NUMBER: 'TBA',
    LAYOUT_TYPES: {
        DEFAULT: '0',
        EXPENSE: '1',
    },
    ENVELOPE: {
        name: 'A4',
        id: 1,
        windowLeftOffset: 25,
        windowTopOffset: 56,
    },
    DOCUMENT_ITEMS: {
        TYPES: {
            JOB_ITEM_GROUP: 'JobItem',
            MEDIA_GROUP: 'Media',
            MEDIA_GROUP_L: 'Media-l',
            MEDIA_GROUP_M: 'Media-m',
            NOTE: 'Note',
            SIGNATURE: 'Signature',
        } as const,
    },
    LAYOUT_KEYS_BY_DOCUMENT_TYPE: {
        [TYPES_VALUES.SUPPLIER_INVOICE_CREDIT]: 'priced_item_expense_creditnote_layout',
        [TYPES_VALUES.SUPPLIER_INVOICE]: 'priced_item_expense_layout',
        [TYPES_VALUES.INVOICE]: 'priced_item_invoice_layout',
        [TYPES_VALUES.QUOTE]: 'priced_item_quote_layout',
        [TYPES_VALUES.WORKSHEET]: 'priced_item_worksheet_layout',
        [TYPES_VALUES.CREDITNOTE]: 'priced_item_creditnote_layout',
        [TYPES_VALUES.EXPENSE]: 'priced_item_expense_layout',
        [TYPES_VALUES.EXPENSE_CREDITNOTE]: 'priced_item_expense_creditnote_layout',
        [TYPES_VALUES.PURCHASE_ORDER]: 'priced_item_purchase_order_layout',
    },
    ORIENTATION: {
        LANDSCAPE: 'landscape',
        PORTRAIT: 'portrait',
    } as const,
    DISCOUNT_TYPE: {
        PERCENTAGE: 0,
        AMOUNT: 1,
    },
    DOCUMENT_GROUP_TYPE: {
        [TYPES_VALUES.INVOICE]: DOCUMENT_GROUP_TYPE_ID.SALES,
        [TYPES_VALUES.CREDITNOTE]: DOCUMENT_GROUP_TYPE_ID.SALES,
        [TYPES_VALUES.QUOTE]: DOCUMENT_GROUP_TYPE_ID.SALES,
        [TYPES_VALUES.SUPPLIER_INVOICE]: DOCUMENT_GROUP_TYPE_ID.SUPPLIER,
        [TYPES_VALUES.SUPPLIER_INVOICE_CREDIT]: DOCUMENT_GROUP_TYPE_ID.SUPPLIER,
        [TYPES_VALUES.EXPENSE]: DOCUMENT_GROUP_TYPE_ID.SUPPLIER,
        [TYPES_VALUES.EXPENSE_CREDITNOTE]: DOCUMENT_GROUP_TYPE_ID.SUPPLIER,
        [TYPES_VALUES.PURCHASE_ORDER]: DOCUMENT_GROUP_TYPE_ID.SUPPLIER,
    },

    IS_FINANCIAL: {
        [TYPES_VALUES.INVOICE]: true,
        [TYPES_VALUES.QUOTE]: false,
        [TYPES_VALUES.WORKSHEET]: false,
        [TYPES_VALUES.CREDITNOTE]: true,
        [TYPES_VALUES.SIGNATURE]: false,
        [TYPES_VALUES.SUPPLIER_INVOICE]: true,
        [TYPES_VALUES.SUPPLIER_INVOICE_CREDIT]: true,
        [TYPES_VALUES.EXPENSE]: true,
        [TYPES_VALUES.EXPENSE_CREDITNOTE]: true,
        [TYPES_VALUES.PURCHASE_ORDER]: false,
    },
    DOCUMENT_PAYMENT_CATEGORIES: {
        ...DOCUMENT_PAYMENT_CATEGORIES,
    },
    DOCUMENT_TYPE_TO_DOCUMENT_PAYMENT_CATEGORY,
    CREDIT_NOTE_TYPES: [
        TYPES_VALUES.CREDITNOTE,
        TYPES_VALUES.SUPPLIER_INVOICE_CREDIT,
        TYPES_VALUES.EXPENSE_CREDITNOTE,
    ],
    DOCUMENT_HEADER_FOOTER_TYPES: {
        ...Object.values(TYPES_VALUES).reduce((acc, type) => ({ ...acc, [type]: type }), {}),
        [TYPES_VALUES.SUPPLIER_INVOICE]: TYPES_VALUES.EXPENSE,
        [TYPES_VALUES.SUPPLIER_INVOICE_CREDIT]: TYPES_VALUES.EXPENSE_CREDITNOTE,
    },

    DOCUMENT_CONTENT_TYPE: {
        CUSTOMER_DATA: 0,
        COMPANY_DATA: 1,
    },
};
