import React from 'react';
import Badge from '@components/Badge/Badge';
import UserAvatar from '@components/Avatar/UserAvatar/UserAvatar';
import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import htmlDecode from '@components/helper/htmlDecode';
import type { EngineerDetails } from '@features/appointment/assignedUsers/AssignedUsers';

const useStyles = makeStyles<Theme>(createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: '100px',
    },
    assigneeDetails: {
        marginLeft: '8px',
    },
}));

type AppointmentUsersAvatarsProps = {
    users: EngineerDetails[],
    mode?: 'onlyFirst' | 'all',
}

export default function AppointmentUsersAvatars(props: AppointmentUsersAvatarsProps) {
    const { users, mode } = props;
    const classes = useStyles();

    if (mode === 'onlyFirst') {
        const { assigneeDetails, profilePictureUrl } = users[0];
        const decodedAssigneeDetails = htmlDecode(assigneeDetails);
        const badgeContent = users.length > 1 ? `+${users.length}` : null;
        return (
            <Box className={classes.root}>
                <Badge content={badgeContent}>
                    <UserAvatar profilePictureUrl={profilePictureUrl} />
                </Badge>
                <Typography
                    className={classes.assigneeDetails}
                    variant="inherit"
                    noWrap
                >
                    {decodedAssigneeDetails}
                </Typography>
            </Box>
        );
    }

    return (
        <Box className={classes.root}>
            {users.map(user => (
                <Box ml={2}>
                    <UserAvatar profilePictureUrl={user.profilePictureUrl} />
                </Box>
            ))}
        </Box>
    );
}
