export default {
    VALIDATION: {
        BAD_EMAIL_DOMAIN: 'bad_email_domain',
    },
    SETTINGS: {
        STORE_ADD_INDEXED_INSERT: 'store_add_indexed_insert',
        ALLOWED_FILE_UPLOAD_TYPE: 'allowed_file_upload_type',
        GLOBAL_2FA_SETTINGS: 'global_2fa_settings',
    },
};
