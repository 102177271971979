import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Box, Paper } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import PageBlock from '@components/layout/PageBlock';
import Button from '@components/button/Button';
import { ErrorContext } from '@components/errorBoundary/errorBoundary';
const useStyles = makeStyles(createStyles({
    root: {
        height: '90vh',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    helperTextContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '10px',
    },
}));
export default function DefaultFallBack() {
    const classes = useStyles();
    const placeholderText = 'Something went horribly wrong';
    const errorContext = useContext(ErrorContext);
    const { redirectPath } = errorContext;
    const handleOnClick = () => {
        window.location.href = redirectPath;
    };
    return (_jsx(Box, { className: classes.root, children: _jsx(Paper, { elevation: 4, children: _jsxs(PageBlock, { display: "flex", flexDirection: "column", neutral: true, title: placeholderText, titleAlign: "center", width: 400, position: "relative", p: 12, children: [_jsx("p", { className: classes.helperTextContainer, children: "Now we will redirect you to the main page." }), _jsx(Button, { onClick: handleOnClick, children: "Ok" })] }) }) }));
}
