import React, { useMemo } from 'react';
import { Columns } from '@components/dataGrid/DataGrid';
import {
    parse,
    patterns,
    format,
    convertToLocal,
} from '@powerednow/shared/modules/utilities/date';
import Typography from '@mui/material/Typography';
import { useProjectDescription } from '@powerednow/portal/src/helper/tabList';
import DownloadButton from '@features/buttons/DownloadButton';
import DownloadIcon from '@mui/icons-material/Download';

function downloadCell({ row }) {
    return (
        <DownloadButton
            url={row.url}
            fileName={row.name}
            startIcon={<DownloadIcon />}
            emphasis="low"
            hideDefaultText
        />   
    );
}

function dtCreatedCell({ row }) {
    const date = parse(row.dt_created, patterns.ISO8601Long);
    const finalDate = convertToLocal(date);

    return (
        <span>
            {format(finalDate, patterns.shortDateOnly)} 
        </span>
    );
}

function fileNameCell({ row }) {
    return (
        <Typography variant="inherit" noWrap>
            {row.name}
        </Typography>
    );
}

function projectNameCell({ row }) {
    return (
        <Typography variant="inherit" noWrap>
            {row.job_description}
        </Typography>
    );
}

export default function filesListColumnConfig(displayProjects: boolean): Columns {
    return useMemo(() => {
        const columns: Columns = [{
            field: 'dt_created',
            headerName: 'Date',
            minWidth: 100,
            renderCell: dtCreatedCell,
        }, {
            field: 'name',
            headerName: 'File Name',
            headerAlign: 'left',
            minWidth: 300,
            renderCell: fileNameCell,
        },
        {
            field: 'download',
            headerName: 'Download',
            sortable: false,
            minWidth: 100,
            renderCell: downloadCell,
        }];
        if (displayProjects) {
            columns.push({
                field: 'job_description',
                headerName: 'Project Name',
                minWidth: 400,
                flex: 1,
                renderCell: projectNameCell,
            });
        }
        return columns;
    }, [displayProjects]);
}
