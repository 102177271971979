import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar as MUIAvatar } from '@mui/material';
import clsx from 'clsx';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { omit } from 'lodash';
const getColor = (theme, name) => ({
    '&.MuiAvatar-root': {
        backgroundColor: theme.palette[name].main,
        color: (name === 'warning' || name === 'neutral') ? theme.palette[name].contrastText : theme.palette[name].light,
    },
});
const useStyles = makeStyles(theme => createStyles({
    primary: getColor(theme, 'primary'),
    secondary: getColor(theme, 'secondary'),
    warning: getColor(theme, 'warning'),
    error: getColor(theme, 'error'),
    neutral: getColor(theme, 'neutral'),
    small: {
        height: theme.spacing(3 * 2),
        width: theme.spacing(3 * 2),
        fontSize: theme.spacing(0.8 * 4),
    },
    medium: {
        height: theme.spacing(3 * 3),
        width: theme.spacing(3 * 3),
        fontSize: theme.spacing(4),
    },
    large: {
        height: theme.spacing(3 * 4),
        width: theme.spacing(3 * 4),
        fontSize: theme.spacing(1.2 * 4),
    },
    xlarge: {
        height: theme.spacing(10 * 2),
        width: theme.spacing(10 * 2),
        fontSize: theme.spacing(2 * 4),
    },
    xxlarge: {
        height: theme.spacing(10 * 3),
        width: theme.spacing(10 * 3),
        fontSize: theme.spacing(2.5 * 4),
    },
    xxxlarge: {
        height: theme.spacing(10 * 4),
        width: theme.spacing(10 * 4),
        fontSize: theme.spacing(3 * 4),
    },
    squared: {
        borderRadius: '5px',
    },
}));
export default function Avatar(props) {
    const classes = useStyles();
    const { color, size, alt, className, squared, } = props;
    const colorClasses = clsx(classes === null || classes === void 0 ? void 0 : classes[color || 'primary']);
    const sizeClasses = clsx(classes === null || classes === void 0 ? void 0 : classes[size || 'medium']);
    return (_jsx(MUIAvatar, Object.assign({ alt: alt || 'avatar', className: clsx(className, colorClasses, sizeClasses, classes.root, squared ? classes.squared : '') }, omit(props, 'className', 'size', 'children', 'alt'))));
}
