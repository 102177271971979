import LabelledBox from '@components/LabelledBox/LabelledBox';
import React from 'react';
import Select from '@components/fields/Select';
import { useResponsive } from '@data/hooks/responsive';
import { authState } from '@data/state/auth';
import { useRecoilValue } from 'recoil';
import useAppointmentTypes, { useAppointmentTypeSettings } from '@data/hooks/useAppointmentTypes';
import { Typography } from '@mui/material';
import htmlDecode from '@components/helper/htmlDecode';
import { getPriceText } from '@features/appointment/createAppointmentWizard/AppointmentTypeField';

const NOT_SELECTED_ID = -1;

type AppointmentTypeSelectorProps = {
    selectedAppointmentTypeId: number,
    handleChange: (_appointmentTypeId: number, appointmentDefaultDurationId: number) => void,
}

const getAppointmentTypeText = (appointmentTypesResult, appointmentTypeSettings) => {
    const { appointmentType, formattedPrice } = appointmentTypesResult;
    if (!appointmentTypeSettings.loaded || !appointmentTypeSettings.data) {
        return '';
    }
    const priceText = getPriceText(appointmentType.price, formattedPrice, appointmentTypeSettings);

    return `${htmlDecode(appointmentType.name)}${priceText ? ` (${htmlDecode(priceText)})` : ''}`;
};

export default function AppointmentTypeSelector({
    selectedAppointmentTypeId,
    handleChange,
}: AppointmentTypeSelectorProps) {
    const authData = useRecoilValue(authState);
    const { data: { companyId } } = authData;

    const responsive = useResponsive();
    const appointmentTypesResults = useAppointmentTypes(companyId);
    const appointmentTypeSettings = useAppointmentTypeSettings(companyId);
    const availableAppointmentTypes = appointmentTypesResults?.data.map(appointmentTypesResult => ({
        id: appointmentTypesResult.appointmentType.id,
        text: <Typography noWrap>{getAppointmentTypeText(appointmentTypesResult, appointmentTypeSettings)}</Typography>,
    }));

    const menuItems = [
        {
            id: NOT_SELECTED_ID,
            text: '- Please choose appointment type -',
        },
        ...availableAppointmentTypes,
    ] || [];

    const getSelectedAppointmentDefaultDurationId = value => {
        const selectedAppointment = appointmentTypesResults.data.find(appointmentTypesResult => appointmentTypesResult.appointmentType.id === value);
        return selectedAppointment?.appointmentType?.defaultDurationId || NOT_SELECTED_ID;
    };

    const handleAppointmentTypeChange = e => {
        const { value } = e.target;
        handleChange(parseInt(value as string, 10), getSelectedAppointmentDefaultDurationId(value));
    };

    return (
        <LabelledBox
            labelPosition={responsive({ xs: 'top', md: 'left' })}
            title="Type of appointment:"
        >
            <Select
                variant="outlined"
                items={menuItems}
                value={selectedAppointmentTypeId}
                fullWidth
                onChange={handleAppointmentTypeChange}
            />
        </LabelledBox>
    );
}
