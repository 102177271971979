import { jsx as _jsx } from "react/jsx-runtime";
import { createTheme, responsiveFontSizes, } from '@mui/material/styles';
import { CheckBoxOutlined } from '@mui/icons-material';
import { getDarkShadedColors, getShadedColors } from '../utilities';
const commonThemeProperties = {
    typography: {
        h6: {
            minWidth: '100px',
        },
        fontFamily: 'Inter, sans-serif',
        button: {
            textTransform: 'none',
        },
        h2: {
            fontSize: '32px',
            fontWeight: 'bold',
        },
    },
    spacing: factor => `${0.25 * factor}rem`,
    shape: {
        borderRadius: 5,
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    '@media (min-width: 600px)': {
                        minWidth: 'auto',
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-footerContainer': {
                        justifyContent: 'center',
                        '@media screen and (min-width: 600px)': {
                            justifyContent: 'space-between',
                        },
                    },
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                checkedIcon: _jsx(CheckBoxOutlined, {}),
            },
        },
    },
};
const lightThemeSource = createTheme(Object.assign(Object.assign({}, commonThemeProperties), { palette: {
        mode: 'light',
        primary: getShadedColors('#377dff', '#E5F1FF'),
        secondary: getShadedColors('#2dca8c', '#EAFAF3'),
        warning: getShadedColors('#ffba00', '#FFF2D8'),
        error: getShadedColors('#ff715b', '#FFE3DE'),
        neutral: getShadedColors('#F5F6FA'),
        backgroundColor: '#5e5e5e',
        dataGridRowColor: {
            main: '#f0f0f0',
            light: 'rgba(0, 0, 0, 0.16)',
            dark: 'initial',
            selected: 'rgba(55, 125, 255, 0.12)',
            selectedhover: 'rgba(55, 125, 255, 0.23)',
        },
        radio: {
            checked: '#377dff',
            unchecked: '#E5E0D5',
        },
    } }));
const darkThemeSource = createTheme(Object.assign(Object.assign({}, commonThemeProperties), { palette: {
        mode: 'dark',
        primary: getDarkShadedColors('#377dff', '#E5F1FF'),
        secondary: getDarkShadedColors('#2dca8c', '#EAFAF3'),
        warning: getDarkShadedColors('#ffba00', '#FFF2D8'),
        error: getDarkShadedColors('#ff715b', '#FFE3DE'),
        neutral: getDarkShadedColors('#45464A'),
        backgroundColor: '#5e5e5e',
        background: {
            paper: '#333333',
            default: '#222222',
        },
        dataGridRowColor: {
            main: '#666666',
            light: 'rgba(0, 0, 0, 0.16)',
            dark: 'initial',
            selected: 'rgba(55, 125, 255, 0.12)',
            selectedhover: 'rgba(55, 125, 255, 0.23)',
        },
        radio: {
            checked: '#377dff',
            unchecked: '#E5E0D5',
        },
        text: {
            primary: '#fff',
        },
    } }));
const defaultLightTheme = responsiveFontSizes(lightThemeSource);
const defaultDarkTheme = responsiveFontSizes(darkThemeSource);
export { lightThemeSource, darkThemeSource, defaultLightTheme, defaultDarkTheme, };
