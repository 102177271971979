import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
export default function Optional(Component) {
    return function Wrapper(props) {
        const { renderIf, children, defaultElement = null } = props, rest = __rest(props, ["renderIf", "children", "defaultElement"]);
        if (!renderIf) {
            return defaultElement;
        }
        return _jsx(Component, Object.assign({}, rest, { children: children }));
    };
}
