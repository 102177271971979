import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useResponsive } from '@data/hooks/responsive';
import { CircularProgress } from '@components/progress/Progress';
import useCompanyDetails from '@data/hooks/useCompanyDetails';

type Props = BoxProps & {
    companyId: number,
}

const useStyles = makeStyles<Theme>(theme => createStyles({
    companyLogo: {
        '& img': {
            height: '60px',
            width: 'auto',
        },
    },
}));

export default function CompanyFooter({ companyId }: Props) {
    const classes = useStyles();
    const responsive = useResponsive();
    const { details: companyDetails, companyAddress } = useCompanyDetails(companyId);

    if (!companyDetails || !companyAddress) {
        return (
            <Box display="flex" flex="auto" justifyContent="center" alignItems="center">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box
            display="flex"
            flex={1}
            className={classes.companyLogo}
            flexDirection="column"
            alignItems={responsive({ xs: 'center', md: 'flex-start' })}
        >
            <Box display="flex" flex="auto">
                <Typography variant="h4" align={responsive({ xs: 'center', md: 'left' })}>
                    {companyDetails ? companyDetails?.name : ''}
                </Typography>
            </Box>
            <Box display="flex" flex={1}>
                <Typography variant="body1" align={responsive({ xs: 'center', md: 'left' })}>
                    {companyDetails?.phone}
                    <br />
                    {companyDetails?.email}
                    <br />
                    <br />
                    {companyAddress?.address1}
                    <br />
                    {companyAddress?.address2}
                    <br />
                    {companyAddress?.city}
                    <br />
                    {companyAddress?.postcode}
                    <br />
                </Typography>
            </Box>
        </Box>
    );
}
