import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import htmlDecode from '@components/helper/htmlDecode';
import { Box } from '@mui/material';
import Optional from '@components/fields/Optional';
function TextWithIcon(props) {
    const { startIcon, children, className, } = props;
    return (_jsxs(Box, { className: className, children: [startIcon, _jsx("span", { children: htmlDecode(children) })] }));
}
const OptionalTextWithIcon = Optional(TextWithIcon);
export { TextWithIcon, OptionalTextWithIcon };
