import useComplexData from '@data/hooks/complexDataHook';

type LocalDateFormatResult = {
    loaded: boolean,
    localDateFormat: string,
}

const useLocalDateFormat = (companyId: number | null) => {
    const { optionalResult } = useComplexData(
        'Company',
        companyId,
        async (complexCompany, resultSetter: (_newResult: LocalDateFormatResult) => void) => {
            const companyAddress = await complexCompany.getCompanyAddress();
            const country = await companyAddress.getCountry();
            const localDateFormat = country.data.date_format;

            resultSetter({
                loaded: true,
                localDateFormat,
            });
        },
    );

    return optionalResult;
};

export default useLocalDateFormat;
