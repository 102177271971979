import React from 'react';
import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import useComplexData from '@data/hooks/complexDataHook';
import { AuthData } from '@data/state/auth';
import MenuItem from '@components/menu/MenuItem';
import { getDataWrapperInstance } from '@powerednow/complexData/connection/api/dataWrapper';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from '@data/hooks/responsive';
import { CircularProgress } from '@components/progress/Progress';
import { ComplexModelEntity } from '@powerednow/models/modelLookup';
import { getApiRequestForAuth } from '@app/connection/apiRequest';

type AvailableAccountItemProps = {
    auth: AuthData,
    selected?: boolean
}

const useStyles = makeStyles<Theme>(theme => createStyles({
    companyLogo: {
        '& img': {
            maxHeight: '60px',
            maxWidth: '200px',
            borderRadius: '4px',
            marginRight: theme.spacing(4),
        },
    },
    companyDetail: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}));

const useCompanyDetailsForAuth = (auth: AuthData) => {
    const { companyId } = auth.data;
    const { details, optionalResult } = useComplexData(
        'Company',
        companyId,
        async (complexCompany, resultSetter: (_newResult:{ logo: string })=>void) => {
            const complexLogo = await complexCompany.getCompanyLogo();
            resultSetter({ logo: complexLogo?.data?.getPureDataValues().url });
        },
        getDataWrapperInstance(getApiRequestForAuth(auth.data)),
    );
    return {
        details,
        companyLogo: optionalResult?.logo,
    };
};

type ContactForAuthResult = {
    email: ComplexModelEntity<'ContactMethod'>,
    fullName: string,
}

const useContactDetailsForAuth = (auth: AuthData) => {
    const { contactId } = auth.data;

    const { details, optionalResult } = useComplexData(
        'Contact',
        contactId,
        async (complexContact, resultSetter: (_newResult:ContactForAuthResult)=>void) => {
            const fullName = await complexContact.getFullName();
            const email = await complexContact.getEmail();
            resultSetter({
                fullName,
                email: email?.data.getPureDataValues(),
            });
        },
        getDataWrapperInstance(getApiRequestForAuth(auth.data)),
    );
    return {
        details,
        optionalResult,
    };
};

function CompanyLogo({ companyLogo }) {
    return !companyLogo ? null : (
        <img alt="logo" src={companyLogo} />
    );
}

const AvailableAccountItem = React.forwardRef<HTMLLIElement, AvailableAccountItemProps>(({ auth, selected }, ref) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const responsive = useResponsive();

    const { details: companyDetails, companyLogo } = useCompanyDetailsForAuth(auth);
    const { optionalResult } = useContactDetailsForAuth(auth);
    if (!companyDetails || !optionalResult) {
        return <CircularProgress />;
    }

    const { email, fullName } = optionalResult;

    const handleSelection = () => {
        navigate(`/login/${auth.portalId}`);
    };

    return (
        <MenuItem
            ref={ref}
            className={classes.companyLogo}
            icon={responsive({ xs: null, md: <CompanyLogo companyLogo={companyLogo} /> })}
            onClick={handleSelection}
            selected={selected}
        >
            <Box className={classes.companyDetail}>
                <Typography variant="h4" noWrap>
                    {companyDetails?.name}
                </Typography>
            </Box>
            <Box className={classes.companyDetail}>
                <Typography variant="h4">
                    {fullName}
                </Typography>
                &nbsp;
                <Typography variant="h4">
                    {email?.value}
                </Typography>
            </Box>
        </MenuItem>
    );
});

export default AvailableAccountItem;
