import React from 'react';

import useComplexData from '@data/hooks/complexDataHook';
import { authState } from '@data/state/auth';
import { useRecoilValue } from 'recoil';
import CircularProgress from '@mui/material/CircularProgress';
import useFormatMoney from '@data/hooks/MoneyFormatter';
import usePrompt from '@data/hooks/promptHook';
import ItemActionSection from '@components/ItemInformations/itemActionSection';
import { DOCUMENT } from '@powerednow/shared/constants';
import PageBlock from '@components/layout/PageBlock';
import ItemDetailSection from '@components/ItemInformations/itemDetailSection';
import { useResponsive } from '@data/hooks/responsive';
import { paymentType } from '@data/state/globalData';
import ActionButton from '@components/button/ActionButton';
import { useNavigateDocumentMessage } from '@data/hooks/navigationHook';
import ColumnComponent from '@components/layout/ColumnComponent';
import { useNavigate } from 'react-router-dom';
import PortalSendMessageButton from '@features/buttons/PortalSendMessageButton';
import { ComplexModelEntity } from '@powerednow/models/modelLookup';

type PaymentDetailsProps = {
    paymentId: number
}

type PaymentDetailsType = {
    document: ComplexModelEntity<'Document'>
    docType: string
}

const usePaymentDetails = (paymentId: number) => {
    const L = usePrompt();
    const { details, optionalResult } = useComplexData(
        'Payment',
        paymentId,
        async (complexPayment, resultSetter:(_newResult:PaymentDetailsType)=>void) => {
            const document = await complexPayment.getDocument();
            const documentTypeString = DOCUMENT.TYPES[document.data.type];
            const docType = L(`InvoiceLabel.${documentTypeString}`, `${documentTypeString}`.replace(/_/g, ' '));
            resultSetter({ document: document.data.getPureDataValues(), docType });
        },
    );
    return {
        payment: details,
        document: optionalResult,
    };
};

export default function PaymentDetails(paymentDetails: PaymentDetailsProps) {
    const responsive = useResponsive();
    const navigate = useNavigate();
    const authData = useRecoilValue(authState);
    const { paymentId } = paymentDetails;
    const { payment, document } = usePaymentDetails(paymentId);
    const paymentTypeRecord = useRecoilValue(paymentType(payment?.type || 0));

    const { navigateHandler: handleViewDocument } = useNavigateDocumentMessage(document?.document?.id);

    const paymentAmount = useFormatMoney(payment?.amount);
    const documentTotal = useFormatMoney(document?.document?.total);

    if (!payment || !document) {
        return (<CircularProgress />);
    }

    return (
        <PageBlock flexDirection={responsive({ xs: 'column', md: 'row' })}>
            <PageBlock
                title={`Payment for ${document?.document?.displaynumber}`}
                display="flex"
                flexDirection="column"
                mb={8}
                flex={responsive({ sm: 2, md: 5 })}
            >
                <ItemDetailSection title="Amount:" text={`Total: ${documentTotal}, Paid ${paymentAmount} `} />
                <ItemDetailSection title="Type:" text={paymentTypeRecord?.name} />
                <ItemActionSection>
                    <ColumnComponent>
                        <PortalSendMessageButton
                            loaded
                            onClick={handleViewDocument}
                            color="primary"
                        />
                        <ActionButton
                            fullWidth
                            onClick={
                                () => navigate(`/portal/${authData.portalId}/document/${document?.document?.id}/view`)
                            }
                        >
                            View Document
                        </ActionButton>
                    </ColumnComponent>
                </ItemActionSection>
            </PageBlock>
        </PageBlock>
    );
}
