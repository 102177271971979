import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { omit } from 'lodash';
import { Box, Typography } from '@mui/material';
import htmlDecode from '@components/helper/htmlDecode';
import { useResponsive } from '../hooks/responsive';
const useStyles = makeStyles(theme => createStyles({
    neutralBlock: {
        backgroundColor: theme.palette.neutral.main,
    },
    pageBlockTitle: {
        marginLeft: '-0.25rem',
        marginBottom: '1rem',
        fontWeight: 'bold',
    },
}));
export default function PageBlock(_a) {
    var { neutral, title, pt, pr, pb, pl, titleAlign, children } = _a, props = __rest(_a, ["neutral", "title", "pt", "pr", "pb", "pl", "titleAlign", "children"]);
    const classes = useStyles();
    const responsive = useResponsive();
    const classNames = clsx(classes.className, neutral ? classes.neutralBlock : null);
    const renderTitle = () => {
        if (title === undefined) {
            return null;
        }
        if (typeof title === 'string') {
            return (_jsx(Typography, { className: classes.pageBlockTitle, variant: responsive({ xs: 'h5', md: 'h4' }), align: titleAlign, children: htmlDecode(title) }));
        }
        return _jsx(Box, { ml: -1, mb: 8, children: title });
    };
    return (_jsxs(Box, Object.assign({ display: "flex", position: "relative", flexDirection: "column", pt: pt !== undefined ? pt : responsive({ xs: 1, sm: 8, md: 12 }), pr: pr !== undefined ? pr : responsive({ xs: 4, sm: 8, md: 12 }), pb: pb !== undefined ? pb : responsive({ xs: 1, sm: 8, md: 12 }), pl: pl !== undefined ? pl : responsive({ xs: 4, sm: 8, md: 12 }), className: classNames }, omit(props, ['neutral', 'className', 'title', 'titleAlign']), { children: [renderTitle(), children] })));
}
