import { authState } from '@data/state/auth';
import { useSimpleComplexData } from '@data/hooks/complexDataHook';
import { COMPANY } from '@powerednow/shared/constants';
import { useRecoilValue } from 'recoil';

const useQuoteName = () => {
    const authData = useRecoilValue(authState);
    const { data: { companyId } } = authData;
    const company = useSimpleComplexData('Company', companyId);
    const { quote_name_id } = company || {};

    if (!quote_name_id || quote_name_id <= 0) {
        return '';
    }

    const isQuote = quote_name_id === COMPANY.QUOTE_NAME_IDS.QUOTE;
    return isQuote ? 'quote' : 'estimate';
};

export default useQuoteName;
